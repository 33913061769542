import React, {useReducer} from 'react';
import {
  HANDLE_WALLET_MODAL,
  ANTIMATTER_TRANSACTION_LIST,
  HANDLE_POPUP_LIST,
  CLEAR_ANTIMATTER_TRANSACTION_LIST,
  HANDLE_TOKENS, CHAINSWAP_TOKENS, HANDLE_LBP_SLIPPAGE,
} from './const';

const mainContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case HANDLE_WALLET_MODAL:
      return {...state, walletModal: action.walletModal};
    case ANTIMATTER_TRANSACTION_LIST:
      let lastTxs = state.transactions
      if (action.transaction.receipt) {
        const index = lastTxs.findIndex(item => {
          return item.hash === action.transaction.hash
        })
        if (index !== -1) {
          lastTxs[index] = action.transaction
        }
      } else {
        lastTxs = [action.transaction].concat(lastTxs)
      }
      window.localStorage.setItem(ANTIMATTER_TRANSACTION_LIST, JSON.stringify(lastTxs))
      return {...state, transactions: lastTxs}

    case CLEAR_ANTIMATTER_TRANSACTION_LIST:
      return {...state, transactions: []}

    case HANDLE_POPUP_LIST:
      let popups = state.popupList
      const popup = action.popup
      if (action.auction === 'add') {
        popups = [popup].concat(popups)
      } else {
        popups = popups.filter(item => {
          return item.key !== popup.key
        })
      }
      popups = popups.filter(function (item, index, arr) {
        return arr.findIndex(popup => item.key === popup.key) === index;
      });
      return {...state, popupList: popups};

    case HANDLE_TOKENS:
        const hash = {}
        const allTokens = action.tokens.concat(state.tokens).reduce((item, next) => {
          if(!hash[next.symbol]) hash[next.symbol] = item.push(next)
          return item
        }, []).sort((a,b)=>{
          if(b.symbol.toLowerCase() === 'token') return 1
          return a.symbol.toLowerCase() > b.symbol.toLowerCase()? 1: -1
        })
      window.localStorage.setItem(CHAINSWAP_TOKENS, JSON.stringify(allTokens))
      return {...state, tokens: allTokens}

    case HANDLE_LBP_SLIPPAGE:
      return {...state, slippage: action.slippage};
    default:
      return state;
  }
};

const ContextProvider = (props) => {
  const transactionsData = window.localStorage.getItem(ANTIMATTER_TRANSACTION_LIST)
  const [state, dispatch] = useReducer(reducer, {
    showConnectModal: false,
    showStakeModal: false,
    showUnstakeModal: false,
    showRewardModal: false,
    showStakedTokensModal: false,
    showUnstakedTokensModal: false,
    showFailedTransactionModal: false,
    showWaitingWalletConfirmModal: {show: false, title: '', content: ''},
    showTransactionModal: false,
    walletModal: null,
    txStatus: null,
    showMenuMaskModal: false,
    transactions: transactionsData ? JSON.parse(transactionsData) : [],
    popupList: [],
    tokens: [],
    slippage: window.localStorage.getItem('LBP_SLIPPAGE')? parseInt(window.localStorage.getItem('LBP_SLIPPAGE')): 50,
    approvedToken: null
  });
  return (
      <mainContext.Provider value={{state, dispatch}}>
        {props.children}
      </mainContext.Provider>
  );
};

export {reducer, mainContext, ContextProvider};
