import { useMemo } from 'react'
import { useMulticallContract } from '../../web3/useContract'
import { Web3Provider } from '@ethersproject/providers'

import { NetworkConnector } from '../../utils/NetworkConnector'

export function useSingleContractMultipleData(
  contract,
  methodName,
  callInputs,
  chainId,
  library
) {
  const multicallContract = useMulticallContract(chainId, library)
  const fragment = useMemo(() => contract?.interface?.getFunction(methodName), [
    contract,
    methodName,
  ])
  const calls =
    contract && fragment && callInputs && callInputs.length > 0
      ? callInputs.map((inputs) => {
          return {
            address: contract.address,
            callData: contract.interface.encodeFunctionData(fragment, inputs),
          }
        })
      : []
  return useMemo(() => {
    if (!multicallContract) return
    try {
      multicallContract
        .aggregate(calls.map((obj) => [obj.address, obj.callData]))
        .then(({ resultsBlockNumber, returnData }) => {
          return returnData.map((data) => {
            return contract.interface
              .decodeFunctionResult(fragment, data)
              .toString()
          })
        })
    } catch (error) {
      console.debug('Failed to fetch chunk inside retry', error)
      throw error
    }
  }, [calls, contract, fragment, multicallContract])
}

export const ETHNetwork = new NetworkConnector({
  urls: { 1: 'https://mainnet.infura.io/v3/3f6f55ba1ee540328662f8496ddbc228' },
})

export const testETHNetwork1 = new NetworkConnector({
  urls: { 3: 'https://ropsten.infura.io/v3/3f6f55ba1ee540328662f8496ddbc228' },
})

export const testETHNetwork2 = new NetworkConnector({
  urls: { 4: 'https://rinkeby.infura.io/v3/3f6f55ba1ee540328662f8496ddbc228' },
})

export const HECONetwork = new NetworkConnector({
  urls: { 128: 'https://http-mainnet.hecochain.com' },
})

export const BSCNetwork = new NetworkConnector({
  urls: { 56: 'https://bsc-dataseed1.binance.org' },
})

export const OKExNetwork = new NetworkConnector({
  urls: { 66: 'https://exchainrpc.okex.org' },
})

export const PolygonNetwork = new NetworkConnector({
  urls: { 137: 'https://matic-mainnet-archive-rpc.bwarelabs.com' },
})

export function getNetworkLibrary(chainId) {
  switch (chainId) {
    case 1:
      return new Web3Provider(ETHNetwork.provider)
    case 3:
      return new Web3Provider(testETHNetwork1.provider)
    case 4:
      return new Web3Provider(testETHNetwork2.provider)
    case 56:
      return new Web3Provider(BSCNetwork.provider)
    case 66:
      return new Web3Provider(OKExNetwork.provider)
    case 128:
      return new Web3Provider(HECONetwork.provider)
    case 137:
      return new Web3Provider(PolygonNetwork.provider)
    default:
  }
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
