import React, {useContext, useEffect} from 'react'
import {useWeb3React} from '@web3-react/core'
import {CHAINSWAP_TOKENS, GALLERY_SELECT_WEB3_CONTEXT, HANDLE_TOKENS} from '../const'
import {InjectedConnector} from '@web3-react/injected-connector'
import {WalletConnectConnector} from '@web3-react/walletconnect-connector'
import {LedgerConnector} from '@web3-react/ledger-connector'
import {TransactionsUpdater, useTokenList, WalletTransactionsUpdater} from './Hooks'
import {PopupItem} from "../components/popup/Popup";
import {mainContext} from "../reducer";

const injected = new InjectedConnector({
    supportedChainIds: [1, 3, 4, 5, 56, 66,42, 128, 137],
})

const POLLING_INTERVAL = 12000
const RPC_URLS = {
    1: 'https://mainnet.infura.io/v3/092b404ef4534f8e9f3acb4e047049c9',
    4: 'https://rinkeby.infura.io/v3/8f6d3d5d7a1442a38d9e8050c31c1884',
}

const walletconnect = new WalletConnectConnector({
    rpc: {1: RPC_URLS[1]},
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
    pollingInterval: POLLING_INTERVAL,
})

const ledger = new LedgerConnector({
    chainId: 1,
    url: RPC_URLS[1],
    pollingInterval: POLLING_INTERVAL,
})

const wallets = {
    MetaMask: injected,
    WalletConnect: walletconnect,
    Ledger: ledger,
    //TrustWallet: injected,
    //Squarelink: squarelink,
    //Torus: torus,
    //Aut
}

export const InitPage = () => {

    const {popupList} = useContext(mainContext).state
    const {dispatch} = useContext(mainContext)
    const context = useWeb3React()
    const {activate, active} = context

    useTokenList()

    useEffect(() => {
        const localContent =
            window && window.localStorage.getItem(GALLERY_SELECT_WEB3_CONTEXT)
        if (localContent) {
            !active && activate(wallets[localContent])
        }
    }, [activate, active])

    useEffect(() => {
        const localTokens = window && window.localStorage.getItem(CHAINSWAP_TOKENS)
        dispatch({type: HANDLE_TOKENS, tokens: localTokens ? JSON.parse(localTokens) : []})
    }, [])

    return (
        <>
            <WalletTransactionsUpdater/>
            <TransactionsUpdater/>
            <div className="popup_column">
                {popupList.map((item) => {
                    return (
                        <PopupItem
                            key={item.key}
                            popKey={item.key}
                            hash={item.hashLink}
                            content={item.summary}
                            success={item.success}
                            removeAfterMs={4000}
                        />
                    )
                })}
            </div>
        </>
    )
}
