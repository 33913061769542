import {useState, useEffect, useContext, useCallback, useMemo} from 'react'
import {Interface} from '@ethersproject/abi'
import {BigNumber} from 'bignumber.js'
import {
  getContract,
  getMultipleContractSingleData,
  getSingleContractMultipleData,
  useActiveWeb3React,
  useBlockNumber,
} from '../web3'
import {ChainId, TOKEN_FACTORY} from '../web3/address'
import {mainContext} from '../reducer'
import {
  ANTIMATTER_TRANSACTION_LIST,
  FACTORY_CHAIN_ID,
  HANDLE_POPUP_LIST,
  HANDLE_TOKENS,
} from '../const'
import {getNetworkLibrary} from '../hooks/multicall/hooks'
import TokenFactory from '../web3/abi/TokenFactory.json'
import ERC20 from '../web3/abi/ERC20.json'
import MainTokenMappedABI from '../web3/abi/MainTokenMappedABI.json'
import {useMulticallContract} from '../web3/useContract'
import {
  MEDIA_SIZES,
  MEDIA_WIDTHS,
  defaultMediaWidth,
} from '../utils/constants'
import {isAddress} from '../utils/address'
import {WHITELIST_TOKENS} from '../const'

const ERC20_INTERFACE = new Interface(ERC20)

export const useBalance = (address, options) => {
  const curChainId = options?.curChainId

  const {account, active, library, chainId} = useActiveWeb3React()
  const {blockNumber} = useBlockNumber()
  const [balance, setBalance] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (
        (active && chainId && address)
    ) {
      try {
        const contract = getContract(getNetworkLibrary(curChainId ? curChainId : chainId), ERC20, address)
        contract.balanceOf(account).then((res) => {
          setLoading(false)
          setBalance(res.toString())
        })
      } catch (e) {
        setLoading(false)
        console.log('load token balance error:', address)
      }
    }
  }, [active, chainId, address, blockNumber, account, curChainId, library])

  useEffect(() => {
    setBalance(undefined)
    setLoading(true)
  }, [address, chainId])

  return {tokenBalance: balance, loading}
}

export const useAllowance = (tokenAddress, address, options) => {
  const curChainId = options?.curChainId
  const approveStatus = options?.approveStatus
  const {account, active, chainId} = useActiveWeb3React()
  const {blockNumber} = useBlockNumber()
  const [balance, setBalance] = useState()

  useEffect(() => {
    if (
        active &&
        address &&
        tokenAddress &&
        curChainId &&
        approveStatus
    ) {
      try {
        const contract = getContract(getNetworkLibrary(curChainId), ERC20, tokenAddress)
        contract.allowance(account, address).then((res) => {
          setBalance(res.toString())
        })
      } catch (e) {
        console.log('load token balance error1:', tokenAddress, address)
      }
    } else {
      setBalance(undefined)
    }
  }, [
    active,
    chainId,
    address,
    blockNumber,
    account,
    tokenAddress,
    curChainId,
    approveStatus,
  ])

  return balance
}

export const useTransactionAdder = () => {
  const {chainId} = useActiveWeb3React()
  const {dispatch} = useContext(mainContext)
  return useCallback(
      (response, customData) => {
        if (!response) return
        const {hash} = response
        const {
          summary,
          addLiquidity,
          removeLiquidity,
          claimReward,
          stake,
          claim,
          approve,
          exercise,
          createTokenMapped,
          createTokenMapping,
          registerMappingToken,
          strap,
          hashLink,
        } = customData
        const now = () => new Date().getTime()
        dispatch({
          type: ANTIMATTER_TRANSACTION_LIST,
          transaction: {
            hash,
            chainId,
            addLiquidity,
            removeLiquidity,
            claimReward,
            summary,
            stake,
            claim,
            exercise,
            approve,
            createTokenMapped,
            createTokenMapping,
            registerMappingToken,
            strap,
            hashLink,
            addedTime: now(),
          },
        })
      },
      [chainId, dispatch]
  )
}

export const WalletTransactionsUpdater = () => {
  const {dispatch} = useContext(mainContext)
  const {chainId, library} = useActiveWeb3React()
  const {blockNumber} = useBlockNumber()
  const {transactions} = useContext(mainContext).state
  useEffect(() => {
    if (!chainId || !library || !blockNumber) return
    transactions
        .filter((item) => {
          return (
              !item.receipt && item.approve && (new Date().getTime() - item.addedTime < 86_400_000)
          )
        })
        .forEach((tx) => {
          library
              .getTransactionReceipt(tx.hash)
              .then((receipt) => {
                if (receipt) {
                  dispatch({
                    type: ANTIMATTER_TRANSACTION_LIST,
                    transaction: {
                      ...tx,
                      approve: tx.approve
                          ? {...tx.approve, status: receipt.status === 1 ? 1 : -1}
                          : null,
                      receipt: {
                        blockHash: receipt.blockHash,
                        blockNumber: receipt.blockNumber,
                        contractAddress: receipt.contractAddress,
                        from: receipt.from,
                        status: receipt.status,
                        to: receipt.to,
                        transactionHash: tx.hash,
                        transactionIndex: receipt.transactionIndex,
                      },
                    },
                  })
                  dispatch({
                    type: HANDLE_POPUP_LIST,
                    auction: 'add',
                    popup: {
                      key: receipt.transactionHash,
                      popKey: receipt.transactionHash,
                      hash: receipt.transactionHash,
                      hashLink: tx.hashLink,
                      summary: tx.summary,
                      success: receipt.status === 1,
                    },
                  })
                } else {
                }
              })
        })
  }, [chainId, blockNumber, transactions, library, dispatch])
  return null
}


export const TransactionsUpdater = () => {
  const {dispatch} = useContext(mainContext)
  const {chainId, library} = useActiveWeb3React()
  const {blockNumber} = useBlockNumber()
  const {transactions} = useContext(mainContext).state
  useEffect(() => {
    if (!chainId || !library || !blockNumber) return
    transactions
        .filter((item) => {
          return (
              !item.receipt && !item.approve && new Date().getTime() - item.addedTime < 86_400_000
          )
        })
        .forEach((tx) => {
          getNetworkLibrary(tx.chainId)
              .getTransactionReceipt(tx.hash)
              .then((receipt) => {
                if (receipt) {
                  dispatch({
                    type: ANTIMATTER_TRANSACTION_LIST,
                    transaction: {
                      ...tx,
                      addLiquidity: tx.addLiquidity
                          ? {...tx.stake, status: receipt.status === 1 ? 1 : -1} : null,
                      removeLiquidity: tx.removeLiquidity
                          ? {...tx.stake, status: receipt.status === 1 ? 1 : -1} : null,
                      claimReward: tx.claimReward
                          ? {...tx.claimReward, status: receipt.status === 1 ? 1 : -1} : null,
                      stake: tx.stake
                          ? {...tx.stake, status: receipt.status === 1 ? 1 : -1} : null,
                      claim: tx.claim
                          ? {...tx.claim, status: receipt.status === 1 ? 1 : -1} : null,
                      exercise: tx.exercise
                          ? {...tx.exercise, status: receipt.status === 1 ? 1 : -1}
                          : null,
                      createTokenMapped: tx.createTokenMapped
                          ? {
                            ...tx.createTokenMapped,
                            status: receipt.status === 1 ? 1 : -1,
                          }
                          : null,
                      createTokenMapping: tx.createTokenMapping
                          ? {
                            ...tx.createTokenMapping,
                            status: receipt.status === 1 ? 1 : -1,
                          }
                          : null,
                      registerMappingToken: tx.registerMappingToken
                          ? {
                            ...tx.registerMappingToken,
                            status: receipt.status === 1 ? 1 : -1,
                          }
                          : null,
                      strap: tx.approve
                          ? {...tx.approve, status: receipt.status === 1 ? 1 : -1}
                          : null,
                      nonce: tx.stake
                          ? new BigNumber(
                              receipt.logs[tx.stake.fromChainId === ChainId.MATIC ? receipt.logs.length - 2 : receipt.logs.length - 1].data.substring(
                                  0,
                                  66
                              )
                          ).toString()
                          : null,
                      receipt: {
                        blockHash: receipt.blockHash,
                        blockNumber: receipt.blockNumber,
                        contractAddress: receipt.contractAddress,
                        from: receipt.from,
                        status: receipt.status,
                        to: receipt.to,
                        transactionHash: tx.hash,
                        transactionIndex: receipt.transactionIndex,
                      },
                    },
                  })
                  dispatch({
                    type: HANDLE_POPUP_LIST,
                    auction: 'add',
                    popup: {
                      key: receipt.transactionHash,
                      popKey: receipt.transactionHash,
                      hash: receipt.transactionHash,
                      hashLink: tx.hashLink,
                      summary: tx.summary,
                      success: receipt.status === 1,
                    },
                  })
                } else {
                }
              })
        })
  }, [chainId, blockNumber, transactions, library, dispatch])
  return null
}

export const useHasDeposite = () => {
  const {chainId, account} = useActiveWeb3React()
  const {transactions} = useContext(mainContext).state

  return useMemo(() => {
    if (chainId && account && transactions.length !== 0) {
      return false
    } else {
      return false
    }
  }, [transactions, chainId, account])
}

export const useRemovePopup = () => {
  const {dispatch} = useContext(mainContext)

  return useCallback(
      (key) => {
        dispatch({
          type: HANDLE_POPUP_LIST,
          auction: 'remove',
          popup: {key: key, popKey: key, hash: key},
        })
      },
      [dispatch]
  )
}

export const useToken = (token) => {
  const multicallContract1 = useMulticallContract(1, getNetworkLibrary(1))
  const multicallContract3 = useMulticallContract(3, getNetworkLibrary(3))
  const multicallContract4 = useMulticallContract(4, getNetworkLibrary(4))
  const multicallContract56 = useMulticallContract(56, getNetworkLibrary(56))
  const multicallContract66 = useMulticallContract(66, getNetworkLibrary(66))
  const multicallContract128 = useMulticallContract(128, getNetworkLibrary(128))
  const multicallContract137 = useMulticallContract(137, getNetworkLibrary(137))


  const AllMulticallContract = {
    1: multicallContract1,
    3: multicallContract3,
    4: multicallContract4,
    56: multicallContract56,
    66: multicallContract66,
    128: multicallContract128,
    137: multicallContract137
  }

  const FactoryChain = FACTORY_CHAIN_ID
  const tokenFactoryContract = getContract(
      getNetworkLibrary(FactoryChain),
      TokenFactory,
      TOKEN_FACTORY
  )
  const multicallContract = useMulticallContract(
      FactoryChain,
      getNetworkLibrary(FactoryChain)
  )

  const [tokenData, setTokenData] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (token && isAddress(token)) {
      setLoading(true)
    }
  }, [token])

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const mappingTokens = await getSingleContractMultipleData(
            multicallContract,
            tokenFactoryContract,
            'chainIdMappingTokenMappeds',
            [[token]]
        )

        const mainChinToken = await getSingleContractMultipleData(
            multicallContract,
            tokenFactoryContract,
            'mainChainIdTokens',
            [[mappingTokens[0]?.['mappingTokenMappeds_'][0]]]
        )
        const mainChainId = Number(mainChinToken[0]['mainChainId'].toString())
        const mainChainAddress = mainChinToken[0]['token']
        const tokenMulticallContact = AllMulticallContract[mainChainId]

        const name = await getMultipleContractSingleData(
            tokenMulticallContact,
            [mainChainAddress],
            ERC20_INTERFACE,
            'name',
            undefined
        )

        const decimals = await getMultipleContractSingleData(
            tokenMulticallContact,
            [mainChainAddress],
            ERC20_INTERFACE,
            'decimals',
            undefined
        )
        const symbol = await getMultipleContractSingleData(
            tokenMulticallContact,
            [mainChainAddress],
            ERC20_INTERFACE,
            'symbol',
            undefined
        )
        setLoading(false)
        setTokenData({
          mainChainAddress,
          name,
          mappingTokens,
          decimals,
          symbol,
          mainChinId: parseInt(mainChinToken[0].mainChainId.toString()),
        })
      } catch (e) {
        setTokenData(undefined)
        setLoading(false)
      }
    }

    if (tokenFactoryContract && isAddress(token)) {
      fetchToken()
    }
  }, [token])

  return useMemo(() => {
    if (!tokenData) return {loading, token: undefined}
    return {
      loading,
      token: {
        symbol: tokenData.symbol[0][0],
        address: tokenData.mainChainAddress,
        chainId: tokenData.mainChinId,
        name: tokenData.name[0][0],
        decimals: tokenData.decimals[0][0],
        chains: tokenData.mappingTokens[0].chainIds.map((item, index) => {
          return {
            chainId: parseInt(item),
            address:
                tokenData.mappingTokens[0]?.['mappingTokenMappeds_'][index],
            mainAddress: item.toString() === tokenData.mainChinId.toString() ? tokenData.mainChainAddress :
                tokenData.mappingTokens[0]?.['mappingTokenMappeds_'][index],
            decimals: tokenData.decimals[0][0],
          }
        }),
        balance: '0',
      },
    }
  }, [tokenData, loading, token])
}

export const useTokenList = () => {
  const {dispatch} = useContext(mainContext)
  const tokenFactoryContract = getContract(
      getNetworkLibrary(FACTORY_CHAIN_ID),
      TokenFactory,
      TOKEN_FACTORY
  )
  const multicallContract = useMulticallContract(
      FACTORY_CHAIN_ID,
      getNetworkLibrary(FACTORY_CHAIN_ID)
  )

  useEffect(() => {
    const fetchTokens = async () => {
      const mappingTokens = await getSingleContractMultipleData(
          multicallContract,
          tokenFactoryContract,
          'chainIdMappingTokenMappeds',
          WHITELIST_TOKENS.map(({address}) => {
            return [address]
          })
      )
      const list = WHITELIST_TOKENS.map((item, index) => {
        return {
          ...item,
          isMainstream: !!item.isMainstream,
          chains: mappingTokens?.[index]?.['chainIds'].map((subItem, subIndex) => {
            return {
              chainId: parseInt(subItem),
              address: mappingTokens?.[index]?.['mappingTokenMappeds_'][subIndex],
              mainAddress: item.isMainstream ? item[subItem].address :
                  item.chainId.toString() === subItem.toString() ? item.address :
                      mappingTokens?.[index]?.['mappingTokenMappeds_'][subIndex],
              decimals: item.isMainstream ? item[subItem].decimals : item.decimals
            }
          }),
        }
      })
      dispatch({type: HANDLE_TOKENS, tokens: list})
    }
    fetchTokens()
  }, [])
}

export const useBalances = (tokens) => {
  const {account, chainId} = useActiveWeb3React()
  const {blockNumber} = useBlockNumber()
  const currentMulticallContract = useMulticallContract(
      chainId,
      getNetworkLibrary(chainId)
  )

  const [balances, setBalance] = useState([])

  useEffect(() => {
    const fetchBalances = async () => {
      const curAddresses = tokens.map((item) => {
        let token = null
        if (item.chainId.toString() === chainId.toString()) {
          token = item
        } else {
          token = item.chains.find((chainIdItem) => {
            return chainIdItem.chainId.toString() === chainId.toString()
          })
        }

        return token ? token.address : undefined
      })
      try {
        const balances = await getMultipleContractSingleData(
            currentMulticallContract,
            curAddresses.filter((item) => {
              return item
            }),
            ERC20_INTERFACE,
            'balanceOf',
            [account]
        )
        setBalance(balances.map(({balance}) => balance))
      } catch (e) {

      }

    }

    if (account && tokens && chainId && blockNumber !== 0) {
      fetchBalances()
    }
  }, [account, chainId, tokens, blockNumber])

  return balances
}

const isClient = typeof window === 'object'

export const useMediaWiderThan = (size) => {
  const width = isClient ? window.innerWidth : defaultMediaWidth
  return width > MEDIA_WIDTHS[MEDIA_SIZES[size]]
}

export const useStakingInfos = () => {
  const {account} = useActiveWeb3React()
  const {blockNumber} = useBlockNumber()
  const {tokens} = useContext(mainContext).state
  const mainstreamTokens = tokens.filter(({isMainstream}) => {
    return isMainstream
  })

  const [stakingInfos, setStakingInfos] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchStakingInfo = async () => {
      try {
        const infos = await Promise.all(mainstreamTokens.map(async (item) => {
          const chains = item.chains
          item.chains = await Promise.all(chains.map(async ({chainId, address}) => {
            const contract = getContract(getNetworkLibrary(chainId), MainTokenMappedABI, address, account)
            const totalSupply = await contract.totalMapped()
            const balance = await contract.balanceOf(account)
            const earned = await contract.earned(account)
            //const apy = await contract.APY()
            const decimals = item[chainId.toString()].decimals
            const mainAddress = item[chainId.toString()].address
            return {chainId, address, balance, totalSupply, apy: '', decimals, earned, mainAddress}
          }))
          return item
        }))
        setStakingInfos(infos)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.error('error---->', e)
      }
    }

    if (account) {
      fetchStakingInfo()
    }
  }, [account, blockNumber])

  useEffect(() => {
    setLoading(true)
  }, [])

  return {stakingInfos, loading}
}

export const useStakingInfo = ({chainId, address}) => {
  const {account} = useActiveWeb3React()
  const {blockNumber} = useBlockNumber()
  const [stakingInfo, setStakingInfo] = useState()
  const [loading, setLoading] = useState(false)
  const [chains, setChains] = useState([])

  useEffect(() => {
    const fetchStakingInfo = async () => {
      try {
        const factoryContract = getContract(getNetworkLibrary(FACTORY_CHAIN_ID), TokenFactory, TOKEN_FACTORY)
        const tokenContract = getContract(getNetworkLibrary(parseInt(chainId)), ERC20, address)
        const name = await tokenContract.name()
        const symbol = await tokenContract.symbol()
        const decimals = await tokenContract.decimals()
        const mappingTokenAddress = await factoryContract.mappingTokenMappeds(address, chainId)
        const mappingContract = getContract(getNetworkLibrary(parseInt(chainId)), MainTokenMappedABI, mappingTokenAddress)
        const totalSupply = await mappingContract.totalMapped()
        const balance = await mappingContract.balanceOf(account)
        setStakingInfo({address, name, symbol, decimals, mappingTokenAddress, totalSupply, balance})
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.error('error---->', e)
      }
    }

    if (account && chainId && address) {
      setLoading(true)
      fetchStakingInfo()
    }
  }, [account, chainId, address, blockNumber])

  useEffect(()=>{
    const fetchChains = async () =>{
      const factoryContract = getContract(getNetworkLibrary(FACTORY_CHAIN_ID), TokenFactory, TOKEN_FACTORY)
      const chainsRes = await factoryContract.chainIdMappingTokenMappeds(address)
      const chains = chainsRes.chainIds.map((item, index) =>{
        return {chainId: item, mappingAddress: chainsRes.mappingTokenMappeds_[index]}
      })
      const stakingInfos = await Promise.all(chains.map(async ({chainId, mappingAddress}) => {
        console.log('mappingAddress', mappingAddress)
        const contract = getContract(getNetworkLibrary(parseInt(chainId)), MainTokenMappedABI, mappingAddress, account)
        const totalSupply = await contract.totalMapped()
        const mainRes = await factoryContract.mainChainIdTokens(mappingAddress)
        const mainAddress = mainRes['token']
        const tokenContract = getContract(getNetworkLibrary(parseInt(chainId)), ERC20, mainAddress)
        const decimals = await tokenContract.decimals()

        console.log('mainRes', mainRes)
        //const apy = await contract.APY()
        return {chainId: parseInt(chainId), mappingAddress, totalSupply: totalSupply.toString(), decimals, apy: ''}
      }))
      setChains(stakingInfos)
      console.log('mappings--->', stakingInfos)
    }

    fetchChains()
  },[address])

  useEffect(() => {
    setLoading(true)
    setStakingInfo(undefined)
  }, [account, chainId])

  return {stakingInfo, chains, loading}
}


