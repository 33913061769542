import React, { useEffect, useMemo, useState } from 'react'
import styles from './LBP.module.scss'
import { ObjectKeys } from '../../../../helpers/objects'
import { Body2 } from '../../../../ui/typography'
import { NavLink, Button } from '../../../../ui/action'
import { Timer } from '../timer'
import classNames from 'classnames'
import { Left, Right } from './Icons'
import { LBPInfo } from 'hooks/useLBPInfo'
import { fromWei } from 'utils/format'
import BigNumber from 'bignumber.js'
import { LBP_CHAIN_ID } from 'const'
import { STATUS } from '../../Tokens'

export const LBP = ({
  info,
  chainId,
  price,
  startTime,
  status,
  setStatus,
}: {
  info: LBPInfo | undefined
  chainId?: number
  price: string
  startTime: number
  status: STATUS
  setStatus: (val: any) => void
}) => {
  const [fullValue, setFullValue] = useState<string>('-')
  const [currentPrice, setCurrentPrice] = useState<string>('-')
  const [currency] = useState<string>('ETH')

  const DATA = useMemo(() => {
    return {
      'Fully Diluted Valuation': `${
        fullValue && price
          ? '$ ' + Number(new BigNumber(fullValue).multipliedBy(price ?? 0).toFixed(0)).toLocaleString()
          : ''
      }`,
      'Current Price': `${currentPrice} ${currency} ${
        currentPrice && price
          ? '( $ ' +
            new BigNumber(currentPrice).multipliedBy(price ?? 0).toString() +
            ' )'
          : ''
      }`,
    }
  }, [fullValue, currentPrice, price, currency])

  useEffect(() => {
    if (info && !info.error) {
      setFullValue(
        fromWei(info.priceLBP?.toString())
          .multipliedBy(new BigNumber(100000000))
          .toFixed(6)
      )
      setCurrentPrice(fromWei(info.priceLBP?.toString()).toFixed(6))
    }
  }, [info])

  return (
    <div className={styles.component}>
      <ul className={styles.list}>
        {ObjectKeys(DATA).map((key) => (
          <Body2 key={key} Component="li" lighten={60} className={styles.item}>
            {key}:{' '}
            <Body2
              Component="span"
              lighten={100}
              style={{ textAlign: 'right', whiteSpace: 'pre-line' }}
            >
              {DATA[key]}
            </Body2>
          </Body2>
        ))}
      </ul>
      <div className={styles.presentation} role="presentation">
        <NavLink
          className={classNames(styles.step, styles.contract, styles.logo_link)}
          href="https://etherscan.io/address/0xaE8dC65a012970345ECb5FF05479B2f3014d2090"
        >
          <Body2 lighten={70}>
            Chainswap LBP
            <br />
            Contract
          </Body2>
        </NavLink>
        <Left className={styles.arrow} />
        <NavLink
          className={classNames(styles.step, styles.uniswap, styles.logo_link)}
          href="https://info.uniswap.org/pair/0x8Ec39063FaB1fC22c05dF2f00d999422a30f2c83"
        >
          <Body2 lighten={70}>Uniswap</Body2>
        </NavLink>
        <Right className={styles.arrow} />
        <Body2 className={classNames(styles.step, styles.amount)} lighten={70}>
          ETH Amount to <br />
          Participate
        </Body2>
      </div>
      {status === STATUS.STARTED && chainId === LBP_CHAIN_ID && (
        <NavLink
          className={styles.button}
          href={'/tokens/purchase'}
          color="primary"
          variant="contained"
        >
          Participate
        </NavLink>
      )}
      {chainId !== LBP_CHAIN_ID && (
        <Button
          className={styles.button}
          color="primary"
          variant="contained"
          disabled={true}
        >
          Please switch to Ethereum Mainnet
        </Button>
      )}

      {status !== STATUS.STARTED && chainId === LBP_CHAIN_ID && (
        <Button
          className={styles.button}
          color="primary"
          variant="contained"
          disabled={true}
        >
          {status === STATUS.PRE_START && (
            <Timer
              timer={startTime / 1000}
              onZero={() => setStatus(STATUS.STARTED)}
            />
          )}
          {status === STATUS.ENDED && 'Closed'}
        </Button>
      )}
    </div>
  )
}
