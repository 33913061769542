import React, { useEffect, useState, useCallback } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import './styles.css'
import './assets/css/style.scss'
import { ContextProvider } from './reducer'
import { InitPage } from './pages/InitPage'
import { Bridge } from './pages/bridge/Bridge'
import { ClaimList } from './pages/claimList'
import Deploy from './pages/deploy'
import Header from './components/header/Header'
import ComingSoon from './pages/ComingSoon'
import { Tokens } from './pages/tokens'
import Farm from './pages/Farm'
import { MODE_TYPE } from 'const'
import { ModalOverlay } from 'components/header/modal'
import { Wallets } from 'pages/bridge/Wallets'
import { CONNECTING } from 'pages/bridge/modals'

const getLibrary = (provider: any) => {
  const library = new Web3Provider(provider)
  library.pollingInterval = 8000
  return library
}
export const ModalContext = React.createContext({
  handleWallet: () => {},
  handleClose: () => {},
})

function App() {
  const [modalType, setModalType] = useState<string | undefined>(undefined)
  useEffect(() => {
    const el = document.querySelector('.loader-container')
    if (el) {
      el.remove()
    }
  }, [])
  const handleClose = useCallback(() => setModalType(MODE_TYPE.INIT), [
    setModalType,
  ])
  const handleWallet = useCallback(() => setModalType(MODE_TYPE.WALLETS), [
    setModalType,
  ])
  return (
    <ContextProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <div className="page">
          <Router>
            {modalType === MODE_TYPE.WALLETS && (
              <ModalOverlay zIndex={200}>
                <Wallets
                  onClose={handleClose}
                  onConnecting={() => {
                    setModalType(MODE_TYPE.CONNECTING)
                  }}
                />
              </ModalOverlay>
            )}

            {modalType === MODE_TYPE.CONNECTING && (
              <ModalOverlay zIndex={200}>
                <CONNECTING />
              </ModalOverlay>
            )}

            <Header />
            <ModalContext.Provider
              value={{ handleWallet: handleWallet, handleClose: handleClose }}
            >
              <div className="page__main">
                <Switch>
                  <Route exact path="/deploy" component={Deploy} />
                  <Route exact path="/liquidity" component={ComingSoon} />
                  <Route exact path="/liquidity/add" component={ComingSoon} />
                  <Route exact path="/liquidity/deposit/:chainId/:address" component={ComingSoon} />
                  <Route exact path="/liquidity/withdraw/:chainId/:address" component={ComingSoon} />
                  <Route exact path="/farm" component={Farm} />
                  <Route exact path="/withdraw" component={ClaimList} />
                  <Route
                    exact
                    path="/apply_for_listing"
                    component={ComingSoon}
                  />
                  <Route path="/tokens" component={Tokens} />
                  <Route exact path="/statistics" component={ComingSoon} />
                  <Route exact path="/history" component={ComingSoon} />
                  <Route exact path="/explorer" component={ComingSoon} />
                  <Route exact path="/support" component={ComingSoon} />
                  <Route exact path="/about" component={ComingSoon} />
                  <Route exact path="/swap" component={Bridge} />
                  <Route exact path="/swap/claim" component={Bridge} />
                  <Route path="/" component={Bridge} />
                </Switch>
              </div>
            </ModalContext.Provider>
            <InitPage />
          </Router>
        </div>
      </Web3ReactProvider>
    </ContextProvider>
  )
}

export default App
