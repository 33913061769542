import React from 'react'
import { ReactComponent as Close } from '../../assets/icon/close.svg'
import { SwapItem } from './SwapItem'
import { formatAmount } from '../../utils/format'
import {Img} from "../../components/Img";
import {SUPPORT_CHAINS} from "../../const";

export const Confirm = ({ depositData, onStake, onClose }) => {
  return (
    <div
      className="default_modal deposit_confirm_modal">
      <Close className="close-btn" onClick={onClose} />
      <div className="extra_frame">
        <p className="default_modal__title" style={{ marginBottom: 20 }}>
        Confirm Deposit
        </p>
        <h2 className="deposit_confirm_modal_amount">{formatAmount(depositData.amount, depositData.decimals)}</h2>
        <div className="deposit_confirm_modal_token">
          <Img src={`https://raw.githubusercontent.com/williamzng/chainswap-assets/main/blockchains/ethereum/${depositData.mainAddress.toLowerCase()}.png`} />
          <span>{depositData.symbol}</span>
        </div>
        <SwapItem data={depositData} />

        <p className="deposit_confirm_modal_notice">
          <span style={{opacity: 0.4}}>Destination: </span>
          {depositData.toAddress}
        </p>

        <button
          onClick={onStake}
          style={{ width: '100%', marginTop: 20 }}
          className="default_button"
        >
          Confirm
        </button>
        <p className="deposit_confirm_modal_notice deposit_confirm_modal_noticep"
        >Chainswap charges a transaction fee 0.005 {SUPPORT_CHAINS[depositData.fromChainId].symbol}</p>
      </div>
    </div>
  )
}
