export const ChainId = {
    MAINNET: 1,
    ROPSTEN: 3,
    RINKEBY: 4,
    GÖRLI: 5,
    KOVAN: 42,
    BSC: 56,
    OKEx: 66,
    HT: 128,
    MATIC : 137
}


export function WETH_ADDRESS(chainId) {
    switch (chainId) {
        case 1:
            return '0xc778417e063141139fce010982780140aa0cd5ab'
        case 3:
            return '0xc778417e063141139fce010982780140aa0cd5ab'
        case 128:
            return '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
        default:
            throw new Error('unknown chain')
    }
}


export const TOKEN_ADDRESS = '0x3B73c1B2ea59835cbfcADade5462b6aB630D9890'

const MULTICALL_NETWORKS = {
    [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
    [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
    [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
    [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
    [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
    [ChainId.BSC]: '0xe348b292e8eA5FAB54340656f3D374b259D658b8',
    [ChainId.OKEx]: '0x86267f0116c923cbb2f3460c2b421562b6189f5d',
    [ChainId.HT]: '0x37ab26db3df780e7026f3e767f65efb739f48d8e',
    [ChainId.MATIC]: '0x373CE6Da1AEB73A9bcA412F2D3b7eD07Af3AD490'
}


const TOKEN_FACTORY = '0xBF515ff38D55737C56D62E8B6a8eea322Ec38Aa5'

const LBP_ADDRESS = '0xaE8dC65a012970345ECb5FF05479B2f3014d2090'

// ropsten
//const AIRDROP_ADDRESS = '0x0950584984a6b1FB0333F0908C914264c8505aef'

//bsc
const AIRDROP_ADDRESS = '0x9e2cF5D43A8a4FaeFd5A44D76cBCbbA78e868A9b'

export { MULTICALL_NETWORKS, TOKEN_FACTORY, LBP_ADDRESS, AIRDROP_ADDRESS }
