import * as React from 'react'

import './Gears.scss'

const radians = (degrees: number) => (degrees / 180) * Math.PI

const polarCoordinate = (radius: number, angle: number) => [
  radius * Math.cos(radians(angle)),
  radius * Math.sin(radians(angle)),
]

const generateArc = (radius: number, angleEnd: number, CCW: number) => {
  const to = polarCoordinate(radius, angleEnd)
  return `A${radius},${radius} 1 0 ${CCW} ${to[0]},${to[1]}`
}

function generateSectorPath(
  pathStart: boolean,
  radius: number,
  start: number,
  end: number
) {
  const startCoord = polarCoordinate(radius, start)
  const startPath = `${(pathStart ? 'M' : 'L') + startCoord[0]},${
    startCoord[1]
  }`

  // Arc angles
  const angle = end - start
  const firstAngle = Math.abs(angle) > 180 ? start + 180 : end
  const secondAngle = end

  const CCW = angle > 0 ? 1 : 0

  // Arcs
  const firstArc = generateArc(radius, firstAngle, CCW)
  const secondArc =
    Math.abs(angle) > 180 ? generateArc(radius, secondAngle, CCW) : ''

  return `${startPath} ${firstArc} ${secondArc}`
}

function generateRadius(
  pathStart: boolean,
  angle: number,
  innerRadius: number,
  outerRadius: number
) {
  const startCoord = polarCoordinate(innerRadius, angle)
  const startPath = `${(pathStart ? 'M' : 'L') + startCoord[0]},${
    startCoord[1]
  }`

  const endCoord = polarCoordinate(outerRadius, angle)
  const endPath = `L${endCoord[0]},${endCoord[1]}`
  return `${startPath} ${endPath}`
}

function generateSector(
  start: number,
  end: number,
  innerRadius: number,
  outerRadius: number
) {
  return `${
    generateSectorPath(true, innerRadius, start, end) +
    generateRadius(false, end, innerRadius, outerRadius) +
    generateSectorPath(false, outerRadius, end, start) +
    generateRadius(false, start, outerRadius, innerRadius)
  }z`
}

function generateWireSector(
  start: number,
  end: number,
  innerRadius: number,
  outerRadius: number
) {
  return generateSectorPath(true, outerRadius, start, end)
}

interface Item {
  value: number
}

export interface CalculatedItem extends Item {
  key: number
  index: number
  angle: number
  prevAngle?: number
  stat: { min: number; max: number; sum: number }
}

function dataMapper(data: Item[], maximalAngle?: number): CalculatedItem[] {
  const min = data.reduce((acc, x) => Math.min(acc, x.value), Infinity)
  const max = data.reduce((acc, x) => Math.max(acc, x.value), -Infinity)
  const sum = data.reduce((acc, x) => acc + x.value, 0)

  const mul = (maximalAngle || 360) / sum

  const stat = {
    sum,
    min,
    max,
  }

  return data.map((item, index) => ({
    key: index,
    index,
    ...item,
    angle: mul * item.value,
    stat,
  }))
}

type BasicAngleFunction = (x: number, offset: number) => number

interface GearProps {
  data: Item[]
  radius: number
  innerRadius?: number

  styler: (item: any) => object

  before?: React.ReactNode
  after?: React.ReactNode

  className?: string

  springConfig?: object

  sizeFactor?: number

  mode: 'spin' | 'grow'
  wireframe?: boolean
  reverse?: boolean
  maximalAngle?: number

  basisAngle?: BasicAngleFunction

  growFactor?: (item: any, min: number, max: number) => number

  processAngle?: (
    start: number,
    end: number,
    index: number,
    item: any,
    props: any
  ) => number[]
}

const defaultBaseAngle: BasicAngleFunction = () => 0
const defaultGrowFactor = (item: Item, min: number, max: number) =>
  (item.value - min) / max

const SprintGear: React.FC<any> = ({ gears, reverse }: any) => {
  const result = gears()
  result.sort((a: any, b: any) => {
    return a.props.style.zIndex - b.props.style.zIndex
  })
  if (reverse) {
    result.reverse()
  }

  return result
}

export class GearChart extends React.Component<GearProps, {}> {
  public static defaultProps = {
    sizeFactor: 2.3,
    wireframe: false,
    innerRadius: 0,
  }

  public gears = (x: number, offset: number) => {
    switch (this.props.mode) {
      case 'spin':
        return this.gearsSpin(x, offset)
      case 'grow':
        return this.gearsGrow(x, offset)
    }
  }

  public generateGear(
    start0: number,
    end0: number,
    innerRadius: number,
    outerRadius: number,
    index: number,
    item: any
  ) {
    const [start, end] = this.props.processAngle
      ? this.props.processAngle(start0, end0, index, item, this.props)
      : [start0, end0]
    return this.props.wireframe
      ? generateWireSector(start, end, innerRadius, outerRadius)
      : generateSector(start, end, innerRadius, outerRadius)
  }

  public gearsSpin(x: number = 1, offset: number = 0) {
    const {
      styler,
      innerRadius = 0,
      radius,
      basisAngle = defaultBaseAngle,
      data = [],
    } = this.props
    const normalized = dataMapper(data, this.props.maximalAngle)

    const startPosition = {
      prevAngle: -90 + basisAngle(x, offset),
      angle: offset && (1 - x) * 360,
    }

    const mapped = normalized.reduce((acc, item) => {
      const angle = item.angle * x
      const prev = acc[acc.length - 1] || startPosition

      return [
        ...acc,
        {
          ...item,
          angle,
          prevAngle: prev.prevAngle + prev.angle,
        },
      ]
    }, [] as any[])

    return mapped.map((item, index) => {
      const { key, angle, prevAngle } = item
      return (
        <path
          key={key}
          d={this.generateGear(
            prevAngle,
            prevAngle + angle,
            innerRadius,
            radius,
            index,
            item
          )}
          {...styler(item)}
        />
      )
    })
  }

  public gearsGrow(x: number = 1, offset: number = 1) {
    const {
      styler,
      innerRadius = 0,
      radius,
      basisAngle = defaultBaseAngle,
      growFactor = defaultGrowFactor,
      data = [],
    } = this.props

    const normalized = dataMapper(data, this.props.maximalAngle)

    const startPosition = { prevAngle: -90 + basisAngle(x, offset), angle: 0 }

    const mapped = normalized.reduce((acc, item) => {
      const prev = acc[acc.length - 1] || startPosition

      return [
        ...acc,
        {
          ...item,
          prevAngle: prev.prevAngle + prev.angle,
        },
      ]
    }, [] as any[])

    return mapped.map((item, index) => {
      const {
        key,
        angle,
        prevAngle,
        stat: { min, max },
      } = item
      return (
        <path
          key={key}
          d={this.generateGear(
            prevAngle,
            prevAngle + angle,
            innerRadius,
            innerRadius +
              (radius - innerRadius) * x * growFactor(item, min, max),
            index,
            item
          )}
          {...styler(item)}
        />
      )
    })
  }

  public render() {
    const { className, before, after, radius, sizeFactor = 2.3 } = this.props

    const viewport = radius * sizeFactor
    const offset = viewport / 2

    return (
      <svg viewBox={`0 0 ${viewport} ${viewport}`} className={className}>
        {this.props.children}
        <g transform={`translate(${offset}, ${offset})`}>
          {before}
          <SprintGear gears={this.gears} />
          {after}
        </g>
      </svg>
    )
  }
}
