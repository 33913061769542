import React, {FC} from "react";
import styles from "./ConfirmPopUp.module.scss";
import {Accent, Body1} from "../../../../../ui/typography";
import {Button} from "../../../../../ui/action";

export const Failed: FC<{ tryClick(): void; cancelClick(): void }> = ({
                                                                        cancelClick,
                                                                        tryClick,
                                                                      }) => {
  return (
    <div className={styles.failed}>
      <Accent Component="h2" className={styles.failedTitle}>Oops</Accent>
      <Body1 className={styles.failedText}>Something went wrong, please try again</Body1>
      <div className={styles.failedButtons}>
        <Button color="primary" variant="outlined" onClick={cancelClick}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={tryClick}>
          Try Again
        </Button>
      </div>
    </div>
  );
};
