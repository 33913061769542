import React, { FC } from 'react'
import styles from './ConfirmPopUp.module.scss'
import { Accent } from '../../../../../ui/typography'
import { Button, NavLink } from '../../../../../ui/action'

type ClaimedType = {
  etherScanLink: string
  onClick(): void
}

export const Claimed: FC<ClaimedType> = ({ onClick, etherScanLink }) => {
  return (
    <div className={styles.claimed}>
      <Accent className={styles.claimedTitle} Component="h2">
        Submitted
      </Accent>
      <NavLink
        className={styles.claimedLink}
        href={etherScanLink}
        variant="text"
        color="primary"
      >
        View on Bscscan
      </NavLink>
      <Button
        className={styles.claimedButton}
        variant="outlined"
        color="primary"
        onClick={onClick}
      >
        Close
      </Button>
    </div>
  )
}
