import React, {useState} from 'react'
import {formatAddress} from "../../utils/format";
import {Check} from "react-feather";
import {ReactComponent as Copy} from "../../assets/icon/copy.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";

export const CopyView = ({title, content}) =>{

    const [copied, setCopied] = useState(false)
    return (
        <li>
            {title}:
            <p>{content && content.length > 20 ? formatAddress(content, 12, -6): content}</p>
            <CopyToClipboard
                text={content}
                onCopy={() => {
                    setCopied(true)
                    setTimeout(() => {
                        setCopied(false)
                    }, 1000)
                }}
            >
                {copied ? <Check style={{cursor: 'pointer', width: 20, height: 20, marginLeft: 'auto', marginRight: 16}} size={20}/> : <Copy style={{cursor: 'pointer', width: 20, height: 20, marginLeft: 'auto', marginRight: 16}} size={24}/>}
            </CopyToClipboard>
        </li>
    )
}
