import React from 'react'
import {ReactComponent as Close} from '../../assets/icon/close.svg'
import Circle from '../../assets/icon/circle.svg'
import {SUPPORT_CHAINS} from "../../const";

export const Confirming = ({chainId, auction, onClose}) => {
  return (
      <div className="default_modal confirm_modal">
        <Close className="close-btn" onClick={onClose}/>
        <img className="confirm_modal__loading" src={Circle} alt=""/>
        <p style={{fontSize: 18}}>{auction === 'APPROVED'? 'Chainswap nodes have approved your transaction.' :
            auction === 'CONSENSUS'? 'Waiting for Consensus confirmation' :
                auction === 'APPROVE' ? 'Please approve spending limit to Chainswap' :
                    'Wait For Confirmation...'}</p>
        {auction !== 'APPROVED' && auction !== 'APPROVE' && auction !== 'CONSENSUS' && (
            <p>
              {`${auction === 'DEPOSIT' ? 'Deposit in' : 'Withdraw on'} ${
                  chainId && SUPPORT_CHAINS[chainId].title
              } Chain`}</p>
        )}
        <p className="color-gray">{auction === 'CONSENSUS'? 'Please wait patiently for chainswap nodes approval' :auction === 'APPROVE' ? 'Confirming approve in your wallet' : `Please initiate transaction in your wallet`}</p>
      </div>
  )
}
