import { ReactComponent as Close } from '../../assets/icon/close.svg'
import { Button } from "../action";
import { MaybeWithClassName, WithChildren } from "../../helpers/types";
import classNames from "classnames";
import React from "react";
import type { CSSProperties, FC } from "react";
import { Shadow } from "../shadow";

import styles from "./PopUpContainer.module.scss";
import { FocusOn } from "react-focus-on";
import { suppressEvent } from "../utils/suppress-event";
import { useWindowSize } from "../../hooks/ui/use-window-size";

type PopUpContainerType = {
	animated: boolean;
	visible: boolean;
	withoutClose?: boolean;
	focusLock?: boolean;
	onClose(): void;
};

type ComponentType = PopUpContainerType & MaybeWithClassName & WithChildren;

export const PopUpContainer: FC<ComponentType & MaybeWithClassName> = ({
	className,
	visible,
	onClose,
	animated,
	children,
	withoutClose,
	focusLock,
}) => {
	const windowHeight = useWindowSize()[1];

	return (
		<>
			<Shadow className={className} visible={visible} animated={animated} focusLock={focusLock} />
			<FocusOn
				autoFocus
				enabled={visible}
				onEscapeKey={!withoutClose ? onClose : () => null}
				onClickOutside={!withoutClose ? onClose : () => null}
				focusLock={focusLock}
			>
				<div data-autofocus-inside style={{outline: "none"}}>
					{/* eslint-disable-next-line max-len */}
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
					<div
						className={classNames(
							className,
							styles.component,
							visible && styles.visible,
							animated && styles.animation
						)}
						tabIndex={-1}
						role="dialog"
						onClick={onClose}
						style={{ "--window-height": `${windowHeight}px` } as CSSProperties}
					>
						<div className={styles.container}>
							<div className={styles.close}>
								{!withoutClose && (
									<Button
										icon={<Close />}
										color="secondary"
										variant="text"
										onClick={onClose}
									>
										Close
									</Button>
								)}
							</div>
							{/* eslint-disable-next-line max-len */}
							{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
							<div onClick={suppressEvent} className={styles.content}>
								{children}
							</div>
						</div>
					</div>
				</div>
			</FocusOn>
		</>
	);
};
