import React, { useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Purchase.module.scss'
import { GutterBox } from '../../../../ui/gutter-box'
import { Caption, Heading1 } from '../../../../ui/typography'
import { Button } from '../../../../ui/action'
import { Input } from '../../../../ui/input'
import { Form, FormSpy } from 'react-final-form'
import { Spinner } from '../../../../ui/spinner'
import { useControlPopUp } from '../../../../hooks/ui/use-control-pop-up'
import { SuccessPopUp } from './success-pop-up'
import { useETHBalance } from 'hooks/balance'
import { fromWei } from 'utils/format'
import { ReactComponent as Setting } from '../../../../assets/icon/setting.svg'
import Confirming from '../../../Tokens-archived/modals/PendingModal'
import { ModalOverlay } from '../../../../components/header/modal'
import { ReactComponent as Close } from '../../../../assets/icon/close.svg'
import { ImpactModal } from '../../../Tokens-archived/modals/ImpactModal'
export function isRequired(value: string): string | undefined {
  return value ? undefined : 'This field is required.'
}

enum OPERATION {
  default = '',
  claim = 'claim',
  claimed = 'claimed',
  failed = 'failed',
}

export const Purchase = ({
  ethVal,
  setEthVal,
  tokenAmount,
  onStrap,
}: {
  ethVal: number | undefined
  setEthVal: (val?: number) => void
  tokenAmount: string
  onStrap: () => Promise<any>
}) => {
  const [operation, setOperation] = useState<OPERATION>(OPERATION.default)
  const [hashLink, setHashLink] = useState('')
  const [showImpact, setShowImpact] = useState(false)
  const balance = useETHBalance()
  const formattedBalance = balance ? fromWei(balance).toFixed(6) : '0'

  const validation = useCallback(
    (values: any) => {
      const errors: { [keys: string]: string | undefined } = {}

      if (!values.amount) {
        errors.amount = 'Enter Amount'
        return errors
      }
      if (values.amount > +formattedBalance) {
        errors.amount = 'Insufficient Balance'
        return errors
      }
      return errors
    },
    [formattedBalance]
  )

  const claimAction = () => {
    setOperation(OPERATION.claim)
    onStrap().then((r) => {
      if (r && r.status === 'success') {
        setHashLink(r.hashLink)
        setOperation(OPERATION.claimed)
      } else if (r && r.status === 'failed') {
        setOperation(OPERATION.failed)
      }
    })

    //     try {
    //       setOperation(OPERATION.claim)
    //      const status=await onStrap()
    //       // const claimResult = await claimTokens(contract, userInfo.address, userInfo.signature);
    //       // console.log(claimResult);
    // console.log(status)
    //       // setTimeout(() => setOperation(OPERATION.claimed), 10000)
    //     } catch (e) {
    //       console.error('fffffff',e);
    //       setOperation(OPERATION.failed)
    //     } finally {
    //       // close modal
    //     }
  }

  const { popUp, close, open } = useControlPopUp()

  useEffect(() => {
    if (operation === OPERATION.claimed) {
      open()
    }
  }, [open, operation])

  useEffect(() => {
    setEthVal(undefined)
  }, [setEthVal])

  return (
    <>
      <section className={styles.component}>
        <GutterBox className={styles.wrapper}>
          <div className={styles.content}>
            <div className="row_between">
              <Heading1 className={styles.title} Component="h2">
                Participate Chainswap Token
              </Heading1>
              <NavLink to="/tokens">
                <Close className="close-btn" />
              </NavLink>
            </div>

            <Form
              validate={validation}
              onSubmit={claimAction}
              render={({ handleSubmit, invalid, touched }) => (
                <form
                  className={styles.form}
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={(state) => {
                      const { values } = state
                      touched && setEthVal && setEthVal(values.amount)
                    }}
                  />
                  <div>
                    <Input
                      className={styles.input}
                      name="amount"
                      label={
                        <Caption
                          className={styles.label}
                          Component="p"
                          lighten={60}
                        >
                          Purchase amount
                          <Caption Component="span" lighten={60}>
                            Your balance: {formattedBalance} ETH
                          </Caption>
                        </Caption>
                      }
                      type="number"
                      required
                      placeholder="Enter the amount you want to purchase"
                      validate={isRequired}
                      max={+formattedBalance}
                    />
                    <Setting
                      onClick={() => {
                        setShowImpact(true)
                      }}
                      className={styles.setting}
                    />
                  </div>
                  <Button
                    className={styles.submit}
                    variant="contained"
                    color="primary"
                    submit
                    disabled={invalid}
                  >
                    {operation === OPERATION.claim && (
                      <Spinner size="small" color="white" />
                    )}
                    <span style={{ marginRight: 6 }}>
                      Purchase{' '}
                      {tokenAmount && ethVal ? tokenAmount + ' TOKEN' : ''}{' '}
                    </span>
                  </Button>
                </form>
              )}
            />
          </div>
        </GutterBox>
      </section>
      {popUp.defined ? (
        <SuccessPopUp hash={hashLink} control={popUp} close={close} />
      ) : null}
      {operation === OPERATION.claim && (
        <ModalOverlay>
          <Confirming
            text={''}
            onClose={() => {
              setOperation(OPERATION.default)
            }}
          />
        </ModalOverlay>
      )}
      {showImpact && (
        <ModalOverlay>
          <ImpactModal
            onClose={() => {
              setShowImpact(false)
            }}
          />
        </ModalOverlay>
      )}
    </>
  )
}
