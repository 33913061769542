import {useActiveWeb3React, useBlockNumber} from "../web3";
import {useEffect, useMemo, useState} from "react";
import Web3 from "web3";
import {Route, Token, WETH, Fetcher} from "@uniswap/sdk";
import {ChainId} from "../web3/address";
import BigNumber from "bignumber.js";
import {numToWei} from "../utils/format";

export const useETHBalance = () => {
    const { account, library, chainId } = useActiveWeb3React()
    const {blockNumber} = useBlockNumber()
    const [balance, setBalance] = useState()
    useEffect(() => {
        if (account) {
            try {
                new Web3(library.provider).eth.getBalance(account).then((res)=>{
                    setBalance(res)
                })
            } catch (e) {
                console.log('load balance error:', e)
            }
        }
    }, [ blockNumber, account, library, chainId])

    return balance
}

export const useETHPrice = () => {
    const {blockNumber} = useBlockNumber()
    const [price, setPrice] = useState()
    useEffect(() => {
      async  function  fetchPrice () {
          const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18)

          // note that you may want/need to handle this async code differently,
          // for example if top-level await is not an option
          const pair = await Fetcher.fetchPairData(DAI, WETH[DAI.chainId])

          const route = new Route([pair], WETH[DAI.chainId])
          const price = route.midPrice.toSignificant(2)
          if(price){
              setPrice(price)
          }
        }

        fetchPrice()
    }, [ blockNumber])

    return price
}

export const useIsEnoughGas = ((gas = '0.005') => {
    const {account, chainId} = useActiveWeb3React()
    const balance = useETHBalance()

    return useMemo(()=>{
        if (!account || !chainId) return false
        return new BigNumber(balance).isGreaterThan(numToWei(gas))
    },[account, chainId, balance])
})
