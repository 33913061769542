import React, { FC, useState } from 'react'
import styles from './Info.module.scss'
import { kashe } from 'kashe'
import classNames from 'classnames'
import { uid } from 'react-uid'
import { Caption, Typography } from '../../../../ui/typography'
import { GearChart } from './gears'

const styler = (value: any) => {
  const strokeWidth = value.hovered ? 0.05 : 0
  const zIndex = value.hovered ? 1 : 0

  return {
    className: styles.path,
    onMouseOver: value.setOn,
    onMouseOut: value.setOff,
    style: {
      position: 'relative',
      zIndex,
      strokeWidth,
      stroke: `rgba(var(--white), 1)`,
      fill: `url(#chart-gradient-${value.key})`,
      strokeLinecap: 'mitter',
    },
  }
}

type Chartable = {
  caption: string
  description: string
  percentage: number
  textColor: string
  pathColorStart: string
  pathColorEnd: string
  order: number
}

type ChartType = {
  data?: Chartable[]
}

const DATA: Chartable[] = [
  {
    caption: 'Protocol Rewards',
    description: 'Unlock based on project development needs',
    percentage: 50,
    textColor: '#948FE8',
    pathColorStart: '#625CCA',
    pathColorEnd: '#9792DC',
    order: 1,
  },
  {
    caption: 'Strategic Contributors',
    description: '10% initial, 10% per month starting month 2 on daily basis ',
    percentage: 20,
    textColor: '#2E2E7D',
    pathColorStart: '#222269',
    pathColorEnd: '#7470EE',
    order: 3,
  },
  {
    caption: 'Foundation',
    description: 'Locked for 1 year, then 25% unlocked every 3 months',
    percentage: 10,
    textColor: '#BF61D0',
    pathColorStart: '#F18CF4',
    pathColorEnd: '#B85BCA',
    order: 7,
  },
  {
    caption: 'Team',
    description: 'Locked for 1 year, then 25% unlocked every 3 months',
    percentage: 10,
    textColor: '#5F59CE',
    pathColorStart: '#5E59CE',
    pathColorEnd: '#464485',
    order: 6,
  },
  {
    caption: 'Liquidity',
    description: '100% unlocked for exchanges',
    percentage: 8,
    textColor: '#673F9C',
    pathColorStart: '#523287',
    pathColorEnd: '#9A5ECD',
    order: 2,
  },
  {
    caption: 'Public Sale',
    description: '100% unlocked at IDO',
    percentage: 1,
    textColor: '#0D0895',
    pathColorStart: '#0D0895',
    pathColorEnd: '#0D0895',
    order: 5,
  },
  {
    caption: 'Option airdrop',
    description: '100% unlocked',
    percentage: 1,
    textColor: '#FF98FF',
    pathColorStart: '#FF98FF',
    pathColorEnd: '#FF98FF',
    order: 4,
  },
]

const mapChartData = kashe(
  (
    data: Chartable[],
    setOn: (x: Chartable | undefined) => any,
    highlightedValue: Chartable | undefined
  ) =>
    data
      .map((asset) => ({
        ...asset,
        value: asset.percentage,
        hovered: asset === highlightedValue,
        setOn: () => setOn(asset),
        setOff: () => setOn(undefined),
      }))
      .sort((a, b) => a.order - b.order)
)

export const Info: FC<ChartType> = () => {
  const [value, setValue] = useState<Chartable | undefined>(undefined)

  return (
    <section className={styles.component}>
      <div className={styles.gear}>
        <GearChart
          styler={styler}
          data={mapChartData(DATA, setValue, value)}
          innerRadius={5}
          radius={10}
          sizeFactor={2.5}
          mode="spin"
          maximalAngle={360}
        >
          <defs>
            {mapChartData(DATA, setValue, value).map((item, index) => (
              <linearGradient
                id={`chart-gradient-${index}`}
                x1="0%"
                y1="0%"
                x2="100%"
                y2="100%"
                key={index}
              >
                <stop offset="0%" stopColor={item.pathColorStart} />
                <stop offset="100%" stopColor={item.pathColorEnd} />
              </linearGradient>
            ))}
          </defs>
        </GearChart>
      </div>
      <ul className={styles.list}>
        {DATA.map((item, index) => {
          const row = index % 4

          return (
            <li
              className={classNames(
                styles.item,
                value === item && styles.active
              )}
              onMouseEnter={() => setValue(item)}
              onMouseLeave={() => setValue(undefined)}
              key={uid(item)}
              style={{ gridRow: `${row + 1}/${row + 2}` }}
            >
              <p className={styles.numbers}>
                <Typography
                  Component="span"
                  variant="secondary"
                  weight="medium"
                  size={20}
                  style={{ color: item.textColor }}
                >
                  {item.percentage}%
                </Typography>
                <span className={styles.token}>{item.percentage}M Tokens</span>
              </p>
              <Typography
                Component="h3"
                className={styles.caption}
                variant="secondary"
                weight="medium"
                size={16}
                style={{
                  color:
                    value === item ? item.textColor : 'rgba(var(--white), 1)',
                }}
              >
                {item.caption}
              </Typography>
              <Caption className={styles.text} lighten={60}>
                {item.description}
              </Caption>
            </li>
          )
        })}
      </ul>
    </section>
  )
}
