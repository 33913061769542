import React from 'react'
import Success from '../../assets/icon/success.svg'

export const Submitted = ({hash, onClose}) => {
  return (
      <div className="default_modal submitted_mode">
        <img src={Success} alt=""/>
        <p style={{marginTop: 12, fontSize: 22, fontWeight: 500}}>Transaction Submitted</p>
        <a href={hash} rel="noopener noreferrer" target="_blank">
          View on{' '}
          {hash
              ? hash.indexOf('' +
                  'https://bscscan.com') !== -1
                  ? 'Bscscan'
                  : hash.indexOf('https://hecoinfo.com') !== -1
                      ? 'Hecoinfo'
                      : hash.indexOf('https://www.oklink.com/okexchain') !== -1 ? 'OKLink'
                          : 'Etherscan'
              : ''}
        </a>
        <button style={{width: '100%'}} onClick={onClose} className="default_button">
          Close
        </button>
      </div>
  )
}
