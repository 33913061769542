import React, { useState, useCallback, useEffect } from 'react'
import { RC } from '../../../../../helpers/types'
import { ScatteredContinuousState } from '../../../../../hooks/ui/use-continuous-state'
import { PopUpContainer } from '../../../../../ui/pop-up-container'
import { Default } from './Default'
import { Loading } from './Loading'
import { Claimed } from './Claimed'
import { Failed } from './Failed'
import { AirDropInfo } from '../../../../../hooks/useLBPInfo'

enum OPERATION {
  default = '',
  claim = 'claim',
  claimed = 'claimed',
  failed = 'failed',
}

export const ConfirmPopUp: RC<{
  info: AirDropInfo
  control: ScatteredContinuousState<boolean>
  close(): void
  amount: number
  tokensAmount: number
  onRedeem?: () => void
  onApprove?: () => Promise<any>
  hashLink?: string
}> = ({
  info,
  close,
  control,
  amount,
  tokensAmount,
  onRedeem,
  onApprove,
  hashLink,
}) => {
  const [operation, setOperation] = useState<OPERATION>(
    hashLink
      ? OPERATION.claimed
      : onApprove
      ? OPERATION.claim
      : OPERATION.default
  )

  const claimAction = () => {
    onRedeem && onRedeem()
  }
  useEffect(() => {
    setOperation(
      hashLink
        ? OPERATION.claimed
        : onApprove
        ? OPERATION.claim
        : OPERATION.default
    )
  }, [hashLink, onApprove])
  // try {
  //   // setOperation(OPERATION.claim);
  //   // const claimResult = await claimTokens(contract, userInfo.address, userInfo.signature);
  //   // console.log(claimResult);

  //   setTimeout(() => setOperation(OPERATION.claimed), 10000)
  // } catch (e) {
  //   // console.error(e);
  //   setOperation(OPERATION.failed);
  // } finally {
  //   // close modal
  // }

  const approveAction = useCallback(() => {}, [])

  const content = () => {
    switch (operation) {
      case OPERATION.default:
        return (
          <Default
            info={info}
            amount={amount}
            tokensAmount={tokensAmount}
            onClick={claimAction}
          />
        )

      case OPERATION.claim:
        return <Loading tokensAmount={tokensAmount} approve={!onRedeem} />

      case OPERATION.claimed:
        return <Claimed etherScanLink={hashLink ?? ''} onClick={close} />

      case OPERATION.failed:
        return (
          <Failed
            cancelClick={close}
            tryClick={onApprove ? approveAction : claimAction}
          />
        )
    }
  }

  return (
    <PopUpContainer
      animated={control.present}
      visible={control.defined}
      onClose={close}
    >
      {content()}
      <control.DefinePresent />
    </PopUpContainer>
  )
}
