import React, {useRef, useState, useMemo} from 'react'
import {ReactComponent as DropDown} from '../../assets/icon/down.svg'
import {useOnClickOutside} from '../../hooks/useOnOutsideClick'
import {Check} from 'react-feather'

export default function ButtonSelect({
                                       defaultContent,
                                       children,
                                       label,
                                       options,
                                       onSelection,
                                       selectedId,
                                       disabled,
                                       smallLabel
                                     }) {
  const node = useRef()
  const [isOpen, setIsOpen] = useState(false)
  useOnClickOutside(node, () => setIsOpen(false))

  const showDeafult = useMemo(()=> {
    return !(options && options.find(({id}) => id === selectedId) )
  }, [options, selectedId])

  return (
      <div className="button_select" ref={node}>
        {label && (
            <div style={{marginBottom: '4px'}}>
              <span style={smallLabel?{fontSize:14,color:'#999999'}:{}}>{label}</span>
            </div>
        )}
        <button
            // style={{backgroundColor: selectedId ? '#2f2f2f' : ''}}
            className={`${!selectedId ? `default_button` : 'selected__button'}`}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
        >
          {!showDeafult &&
          <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
          >
            {options
                ? options.find(({id}) => id === selectedId)?.option
                : children}
          </div>
          }
          { showDeafult && defaultContent}
          <DropDown/>
        </button>
        {isOpen && options && onSelection && (
            <div className={`option_frame${isOpen ? ' is_open' : ''}`}>
              <div>
                {options.map(({id, option, data}) => (
                    <button
                        className={`select_option${
                            selectedId === id ? ' selected' : ''
                        }`}
                        key={id}
                        onClick={() => {
                          onSelection(id, data)
                          setIsOpen(false)
                        }}
                    >
                      {options && options.find(({id}) => id === selectedId) &&
                      <Check
                          className={`checked ${selectedId !== id ? 'dis_checked' : ''}`}
                          size={16}
                      /> }
                      {option}
                    </button>
                ))}
              </div>
            </div>
        )}
        {disabled && <div className="disabled_frame"/>}
      </div>
  )
}
