import React from 'react'
import { RC } from '../../../../../helpers/types'
import { ScatteredContinuousState } from '../../../../../hooks/ui/use-continuous-state'
import { PopUpContainer } from '../../../../../ui/pop-up-container'
import styles from './SuccessPopUp.module.scss'
import { Accent, Body1 } from '../../../../../ui/typography'
import { NavLink } from '../../../../../ui/action'

export const SuccessPopUp: RC<{
  hash: string | undefined
  control: ScatteredContinuousState<boolean>
  close(): void
}> = ({ hash, close, control }) => {
  return (
    <PopUpContainer
      animated={control.present}
      visible={control.defined}
      onClose={close}
      withoutClose={true}
    >
      <div className={styles.claimed}>
        <Accent className={styles.claimedTitle} Component="h2">
          Transaction Submitted
        </Accent>
        {/*<Body1 className={styles.claimedText}>LBP has ended, please visit uniswap for trading</Body1>*/}
        <NavLink
          className={styles.claimedLink}
          href={hash ?? ''}
          variant="text"
          color="primary"
        >
          View on Etherscan
        </NavLink>
        <NavLink
          className={styles.claimedButton}
          href="/tokens"
          variant="outlined"
          color="primary"
        >
          Close
        </NavLink>
      </div>
      <control.DefinePresent />
    </PopUpContainer>
  )
}
