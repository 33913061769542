import React from 'react'
import { ReactComponent as Close } from '../../../assets/icon/close.svg'
import { ReactComponent as Circle } from '../../../assets/icon/circle.svg'

export default function Confirming({ onClose, text }) {
  return (
    <div className="default_modal pending_modal">
      <Close className="close-btn" onClick={onClose} />
      <Circle className="confirm_modal__loading pending_modal__loading" />
      <div>
        <p style={{ fontSize: 20 }}>Waiting For Confirmation...</p>
        <p className="text_small_gray">{text}</p>
      </div>

      <p className="text_small_gray" style={{ fontSize: 12 }}>
        Please initiate transaction in your wallet
      </p>
    </div>
  )
}
