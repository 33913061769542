import React, {useContext, useState} from 'react'
import Success from "../../../assets/icon/success.svg";
import {CopyView} from "../CopyView";
import {useMappedToken} from "../../../hooks/deoloy";
import {ANTIMATTER_TRANSACTION_LIST} from "../../../const";
import {mainContext} from "../../../reducer";
import {useIsEnoughGas} from "../../../hooks/balance";
import {ModalOverlay} from "../../../components/header/modal";
import {Submitted} from "../../bridge/Submitted";
import {GASERROR} from "../../bridge/modals";

export const AddedModal = ({token, hash, onClose}) => {

    const dispatch = useContext(mainContext).dispatch
    const {transactions} = useContext(mainContext).state

    const createdTokenMappedTX = transactions.find((item) => {
        return item.createTokenMapped && item.createTokenMapped.status === 1
    })
    const mappedToken = useMappedToken(token.chainId, token.address)
    return (
        <div className="default_modal modal_added">
            <img src={Success} alt=""/>
            <p style={{marginTop: 19, fontSize: 24}}>Success!</p>
            <p style={{}}>You have successfully deployed your mappable contract on mainchain, please save the following infomation and continue to next step:</p>
            <ul className="copy_frame" style={{marginTop: 35}}>
                <CopyView title={'Token contract address'} content={token?.address}/>
                <CopyView title={'Mappable contract address'} content={mappedToken}/>
                <CopyView title={'Mainchain ID'} content={token?.chainId.toString()}/>
            </ul>
            <a
                href={hash}
                rel="noopener noreferrer"
                target="_blank">View on {hash ? hash.indexOf('https://bscscan.com') !== -1 ? 'Bscscan' :
                hash.indexOf('https://hecoinfo.com') !== -1 ? 'Hecoinfo' : 'Etherscan' : ''}</a>
            <button className="default_button" onClick={()=>{
                const tx = createdTokenMappedTX
                if(createdTokenMappedTX){
                    tx.createTokenMapped.status = 2
                }
                dispatch({
                    type: ANTIMATTER_TRANSACTION_LIST,
                    transaction: tx,
                })
                onClose()
            }}>Go to Next Step</button>
        </div>
    )
}
