import { ReactComponent as Close } from '../../assets/icon/close.svg'
import Circle from '../../assets/icon/circle.svg'
import React from 'react'
import {ModalOverlay} from "../header/modal";

export const TXConfirm = ({ context, onClose }) => {
  return (
    <ModalOverlay>
      <div className="default_modal confirm_modal">
        <Close className="close-btn" onClick={onClose} />
        <img className="confirm_modal__loading" src={Circle} alt="" />
        <p style={{ fontSize: 16 }}>Wait For Confirmation...</p>
        <p style={{fontWeight: 700}}>{context}</p>

        <p className="color-gray">{`Confirm this transaction in your wallet`}</p>
      </div>
    </ModalOverlay>
  )
}
