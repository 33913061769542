import React, {useContext, useMemo, useState} from 'react'
import {Check} from 'react-feather'
import ButtonMultiSelect from '../../../components/button/ButtonMultiSelect'
import {
  useAllMappingTokens,
  useMappingTokens,
  useToken,
} from '../../../hooks/deoloy'
import Circle from '../../../assets/icon/circle.svg'
import {getContract, useActiveWeb3React} from '../../../web3'
import TokenFactory from '../../../web3/abi/TokenFactory.json'
import {TOKEN_FACTORY} from '../../../web3/address'
import {numToWei} from '../../../utils/format'
import {getEtherscanLink} from '../../../utils'
import {ModalOverlay} from '../../../components/header/modal'
import {Submitted} from '../../bridge/Submitted'
import {TXConfirm} from '../../../components/modal/TXConfirm'
import {useTransactionAdder} from '../../Hooks'
import {SUPPORT_CHAINS, ANTIMATTER_TRANSACTION_LIST, CHAIN_LIST} from '../../../const'
import {Button} from '../../../components/button/Button'
import {mainContext} from '../../../reducer'
import {useIsEnoughGas} from "../../../hooks/balance";
import {GASERROR} from "../../bridge/modals";

export const MappingDeploymentModal = ({existingToken, onNext, onBack}) => {
  const {account, chainId, library} = useActiveWeb3React()
  const isEnoughGas = useIsEnoughGas('0.1')

  const addTransaction = useTransactionAdder()
  const {token, loading, error} = useToken(
      existingToken.chainId,
      existingToken.address
  )

  const [showGasError, setShowGasError] = useState(false)
  const [mappingChainIds, setMappingChainIds] = useState([])
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [hash, setHash] = useState()

  const {transactions} = useContext(mainContext).state
  const {dispatch} = useContext(mainContext)

  const createMappingTXs = transactions.filter((item) => {
    return (
        item.createTokenMapping &&
        item.createTokenMapping.mappingToken.existingAddress ===
        existingToken.address &&
        item.createTokenMapping.status === 0
    )
  })

  const registeredTX = transactions.find((item) => {
    return (
        item.registerMappingToken &&
        item.registerMappingToken.status === 1 &&
        item.registerMappingToken.register.existingAddress ===
        existingToken.address
    )
  })

  const mappingTokensResult = useMappingTokens(
      existingToken.chainId,
      existingToken.address
  )
  const allMappingTokens = useAllMappingTokens(
      existingToken.chainId,
      existingToken.address
  )

  const unRegisteredMappingToken = useMemo(
      () =>
          allMappingTokens?.filter((item) => {
            return (
                mappingTokensResult &&
                mappingTokensResult.tokens &&
                mappingTokensResult.tokens.findIndex((registeredToken) => {
                  return (
                      registeredToken.chainId.toString() === item.chainId.toString()
                  )
                }) === -1
            )
          }),
      [allMappingTokens, mappingTokensResult]
  )

  const supportChainOptions = useMemo(
      () =>
          CHAIN_LIST.filter(
              ({chainId}) =>
                  chainId !== 66 &&
                  chainId !== existingToken.chainId &&
                  mappingTokensResult &&
                  mappingTokensResult.tokens &&
                  mappingTokensResult.tokens.findIndex((item) => {
                    return item.chainId.toString() === chainId.toString()
                  }) === -1 &&
                  allMappingTokens &&
                  allMappingTokens.findIndex((item) => {
                    return item.chainId.toString() === chainId.toString()
                  }) === -1
          ).map(({chainId, logo, title}) => ({
            id: chainId,
            title,
            option: (
                <>
                  {logo}
                  {title}
                </>
            ),
          })),
      [existingToken.chainId, mappingTokensResult, allMappingTokens]
  )
  const Stepper = useMemo(() => {
    return function ({className}) {
      return (
          <div className={`step_frame${className ? ' ' + className : ''}`}>
            <div className="step_circle">
              <Check size={18}/>
            </div>
            <div className="divider"/>
            <p>2</p>
            <div className="divider"/>
            <p>3</p>
          </div>
      )
    }
  }, [])

  const getSigns = async (chainId) => {
    return await new Promise((resolve, reject) => {
      let signList = []
      let count = []
      for (let i = 1; i < 6; i++) {
        try {
          count.push('')
          fetch(
              `https://node${i}.chainswap.exchange/web/getCreateMappingTokenSignDataSyn`,
              {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                  creator: account,
                  name: token.name,
                  symbol: token.symbol,
                  cap: token.totalSupply.toString(),
                  decimals: token.decimals,
                  token: existingToken.address,
                  mainChainId: existingToken.chainId,
                  chainId: chainId,
                }),
              }
          )
              .then((response) => {
                return response.json()
              })
              .then((data) => {
                if (data.data) {
                  signList.push({
                    signatory: data.data.signatory,
                    v: data.data.signV,
                    r: data.data.signR,
                    s: data.data.signS,
                    cap: data.data.cap,
                    decimals: data.data.decimals,
                    name: data.data.name,
                    symbol: data.data.symbol,
                    token: data.data.token,
                    mainChainId: data.data.mainChainId,
                  })
                }
                if (signList.length === 3) {
                  resolve(signList)
                } else if (count.length === 5 && signList.length < 3) {
                  //reject('query error')
                }
              })
        } catch (e) {
          throw new Error('Fetch sign error.' + e.toString())
        }
      }
    })
  }

  const onCreateMapping = async (chainId) => {
    setAttemptingTxn(true)

    try {
      const signs = await getSigns(chainId)
      const contract = getContract(
          library,
          TokenFactory,
          TOKEN_FACTORY,
          account
      )

      await contract
          .createMappingToken(
              existingToken.chainId,
              existingToken.address,
              existingToken.nonce,
              token.name,
              token.symbol,
              token.decimals,
              token.totalSupply.toString(),
              signs.map((item) => {
                return {
                  signatory: item.signatory,
                  v: item.v,
                  r: item.r,
                  s: item.s,
                }
              }),
              {
                from: account,
                value: numToWei('0.1'),
              }
          )
          .then((response) => {
            setAttemptingTxn(false)
            setHash(getEtherscanLink(chainId, response.hash, 'transaction'))
            addTransaction(response, {
              createTokenMapping: {
                mappingToken: {
                  existingAddress: existingToken.address,
                  existingChainId: existingToken.chainId,
                  symbol: token.symbol,
                  mappingChainId: chainId,
                },
                status: 0,
              },
              summary: `Create ${token.symbol} mapping token on ${SUPPORT_CHAINS[chainId].title} chain `,
              hashLink: getEtherscanLink(chainId, response.hash, 'transaction'),
            })
          })
    } catch (e) {
      console.log('createTokenMapped', e)
      setAttemptingTxn(false)
    }
  }

  return (
      <div style={{paddingTop: 24}} className="default_modal modal_add_token">
        <div className="row_between">
          <p className="default_modal__title">
            Mapping token contract<br/>deployment
          </p>
          <Stepper/>
        </div>
        <div className="modal_add_token__body">
          <div className="extra gray_frame">
            <div className="extra__header">
              <p>Mainchain Info</p>
              {/*<a onClick={onBack}>Edit</a>*/}
            </div>
            <ul>
              <li>Token contract address:</li>
              <li style={{color: '#ffffff'}}>
                {loading ? (
                    <img src={Circle} className="loading"/>
                ) : (
                    existingToken?.address
                )}
              </li>
            </ul>
            <ul>
              <li>Mappable contract address:</li>
              <li style={{color: '#ffffff'}}>
                {loading ? (
                    <img src={Circle} className="loading"/>
                ) : (
                    token?.tokenMapped
                )}
              </li>
            </ul>
            <ul>
              <li>Mainchain ID:</li>
              <li style={{color: '#ffffff'}}>
                {loading ? (
                    <img src={Circle} className="loading"/>
                ) : (
                    existingToken?.chainId
                )}
              </li>
            </ul>
          </div>

          <div className="bridge__input_frame" style={{width: '100%'}}>
            <ButtonMultiSelect
                defaultContent={'Select the chain ID of your existing token'}
                label={'Select Chain'}
                options={supportChainOptions}
                onSelections={(ids) => {
                  setMappingChainIds(ids)
                }}
                selectedIds={mappingChainIds}
            />
          </div>

          <div className="mappings">
            {mappingTokensResult?.tokens?.map((item) => {
              return (
                  <div className="mapping_frame">
                    <p className='text_gray text_14 text_regular'>Chain {parseInt(item.chainId.toString())}</p>
                    <div className="mapping_frame__mapping">
                    <div>
                      {SUPPORT_CHAINS[parseInt(item.chainId.toString())].logo}
                      <span style={{ width: 10 }}></span>
                      {SUPPORT_CHAINS[parseInt(item.chainId.toString())].title}
                    </div>
                      <button disabled className="default_button">
                        Registered
                      </button>
                    </div>
                    <div
                        className="extra gray_frame"
                        style={{marginTop: 16, cursor: 'auto'}}
                    >
                      <ul>
                        <li>Token contract address:</li>
                        <li style={{color: '#ffffff'}}>{item.address}</li>
                      </ul>
                      <ul>
                        <li>Chain ID:</li>
                        <li style={{color: '#ffffff'}}>
                          {item.chainId.toString()}
                        </li>
                      </ul>
                    </div>
                  </div>
              )
            })}

            {unRegisteredMappingToken?.map((item) => {
              return (
                  <div className="mapping_frame">
                    <p className='text_gray text_14 text_regular'>Chain {item.chainId}</p>
                    <div className="mapping_frame__mapping">
                      <div>{SUPPORT_CHAINS[item.chainId].title}</div>
                      <Button disabled success content={'Success'} outline/>
                    </div>
                    <div
                        className="extra gray_frame"
                        style={{marginTop: 16, cursor: 'auto'}}
                    >
                      <ul>
                        <li>Token contract address:</li>
                        <li style={{color: '#ffffff'}}>{item.address}</li>
                      </ul>
                      <ul>
                        <li>Chain ID:</li>
                        <li style={{color: '#ffffff'}}>
                          {item.chainId.toString()}
                        </li>
                      </ul>
                    </div>
                  </div>
              )
            })}

            {createMappingTXs?.map((item) => {
              return (
                  <div className="mapping_frame">
                    <p className='text_gray text_14 text_regular'>Chain {item.chainId}</p>
                    <div className="mapping_frame__mapping">
                    <div>
                    {SUPPORT_CHAINS[item.createTokenMapping.mappingToken.mappingChainId].logo}
                      <span style={{ width: 10 }}></span>
                        {
                          SUPPORT_CHAINS[
                              item.createTokenMapping.mappingToken.mappingChainId
                              ].title
                        }
                      </div>
                      <Button loading content={'Deploying'}/>
                    </div>
                  </div>
              )
            })}

            {mappingChainIds
                .filter((item) => {
                  return (
                      createMappingTXs &&
                      createMappingTXs.findIndex((mappingTX) => {
                        return (
                            mappingTX.createTokenMapping.mappingToken.mappingChainId.toString() ===
                            item.toString()
                        )
                      }) === -1 &&
                      allMappingTokens &&
                      allMappingTokens.findIndex((mappingToken) => {
                        return mappingToken.chainId.toString() === item.toString()
                      }) === -1
                  )
                })
                .map((item) => {
                  return (
                      <div className="mapping_frame">
                        <p className='text_gray text_14 text_regular'>Chain {item}</p>
                        <div className="mapping_frame__mapping">
                          <div>{SUPPORT_CHAINS[item].logo}  <span style={{ width: 10 }}></span>{SUPPORT_CHAINS[item].title}</div>
                          <button
                              className="default_button_dark"
                              onClick={() => {
                                if (item !== chainId) {
                                  const curChain = SUPPORT_CHAINS[item]
                                  library.provider.request({
                                    method: 'wallet_addEthereumChain',
                                    params: [
                                      {
                                        chainId: `0x${item.toString(16)}`,
                                        chainName: curChain.chainName,
                                        nativeCurrency: curChain.nativeCurrency,
                                        rpcUrls: curChain.rpcUrls,
                                        blockExplorerUrls: curChain.blockExplorerUrls,
                                      },
                                    ],
                                  })
                                } else {
                                  if (!isEnoughGas) {
                                    setShowGasError(true)
                                    return
                                  }
                                  onCreateMapping(SUPPORT_CHAINS[item].chainId)
                                }
                              }}
                          >
                            {item !== chainId ? (
                                `Switch to ${SUPPORT_CHAINS[item].title}`
                            ) : (
                                <div className="btn_content">
                                  <p style={{fontSize: 16}}>Deploy</p>
                                  {<p className="btn_extra">{`Deployment fee: 0.1 ${SUPPORT_CHAINS[item].symbol}`}</p>}
                                </div>
                            )}
                          </button>
                        </div>
                      </div>
                  )
                })}
          </div>

          <button
              disabled={
                !token ||
                !unRegisteredMappingToken ||
                unRegisteredMappingToken.length === 0
              }
              onClick={() => {
                if (registeredTX) {
                  registeredTX.registerMappingToken.status = 2
                  dispatch({
                    type: ANTIMATTER_TRANSACTION_LIST,
                    transaction: registeredTX,
                  })
                }

                const t = existingToken
                t.tokenMapped = token.tokenMapped
                onNext(
                    t,
                    unRegisteredMappingToken,
                    mappingTokensResult &&
                    mappingTokensResult.tokens &&
                    mappingTokensResult.tokens.findIndex((item) => {
                      return (
                          item.chainId.toString() === existingToken.chainId.toString()
                      )
                    }) !== -1
                )
              }}
              className="default_button"
              style={{width: '100%', marginTop: 32}}
          >
            Next Step
          </button>
        </div>

        {hash && (
            <ModalOverlay>
              <Submitted
                  hash={hash}
                  onClose={() => {
                    setHash(null)
                  }}
              />
            </ModalOverlay>
        )}

        {attemptingTxn && (
            <TXConfirm
                onClose={() => {
                  setAttemptingTxn(false)
                }}
            />
        )}

        {showGasError && (
            <ModalOverlay>
              <GASERROR onOk={() => {
                setShowGasError(false)
              }} onCancel={() => {
                setShowGasError(false)
              }}/>
            </ModalOverlay>
        )}
      </div>
  )
}
