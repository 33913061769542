import React from 'react'
import Success from "../../../assets/icon/success.svg";

export const Deployed = ({hash, onClose}) => {
  return (
      <div className="default_modal submitted_mode">
        <img src={Success} alt=""/>
        <p style={{marginTop: 19, fontSize: 24}}>Congratulations !</p>
        <p style={{fontSize: 16, textAlign: 'center', width: 360}}>You token is now on Chainswap and the cross chain functionality
          is enabled.</p>
        <a
            href={hash}
            rel="noopener noreferrer"
            target="_blank">View on {hash ? hash.indexOf('https://bscscan.com') !== -1 ? 'Bscscan' :
            hash.indexOf('https://hecoinfo.com') !== -1 ?
                'Hecoinfo' : hash.indexOf('https://www.oklink.com/okexchain') !== -1 ?
                'OKLink' : 'Etherscan' : ''}</a>
        <button className="default_button" style={{width: 448}} onClick={onClose}>Close
        </button>
      </div>
  )
}
