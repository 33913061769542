import classNames from "classnames";
import type { CSSProperties, FC } from "react";
import { MaybeWithClassName, WithChildren } from "../../helpers/types";
import React from "react";
import styles from "./Typography.module.scss";
import colorStyles from "../styles/Color.module.scss";
import sizeStyles from "../styles/Size.module.scss";
import weightStyles from "../styles/Weight.module.scss";
import { ColorType, FontSizeType, WeightType } from "../types";
import { getColorClassName } from "../utils/get-color-class-name";
import { getSizeClassName } from "../utils/get-size-class-name";
import { getWeightClassName } from "../utils/get-weight-class-name";

export type TypographyType = {
	Component: Exclude<keyof JSX.IntrinsicElements, "button" | "a">;
	variant: "primary" | "secondary";
	color?: ColorType;
	size?: FontSizeType;
	weight?: WeightType;
	lighten?: 100 | 90 | 80 | 70 | 60 | 50| 40;
	style?: CSSProperties;
} & WithChildren &
	MaybeWithClassName;

export const Typography: FC<TypographyType> = ({
	Component,
	className,
	variant,
	weight = "regular",
	color = "default",
	size = 18,
	lighten = 100,
	style,
	children,
	...props
}) => (
	<Component
		className={classNames(
			className,
			//
			styles[variant],
			//
			getSizeClassName(size as FontSizeType, sizeStyles),
			//
			getWeightClassName(weight as WeightType, weightStyles),
			//
			getColorClassName(color as ColorType, colorStyles)
			//
		)}
		style={{ ...style, "--lighten": lighten / 100 } as CSSProperties}
		{...props}
	>
		{children}
	</Component>
);

export type AccentType = Omit<TypographyType, "size" | "weight" | "variant">;

export const Accent: FC<AccentType> = ({ ...props }) => {
	return (
		<Typography size={24} weight="regular" variant="primary" {...props} />
	);
};

export type HeadingComponentType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

type HeadingType = Omit<TypographyType, "size" | "Component" | "weight" | "variant"> & {
	Component?: HeadingComponentType;
};

export const Heading1: FC<HeadingType> = ({ Component = "h1", ...props }) => {
	return (
		<Typography Component={Component} size={20} weight="medium" variant="secondary" {...props} />
	);
};

export const Heading2: FC<HeadingType> = ({ Component = "h2", ...props }) => {
	return (
		<Typography Component={Component} size={18} weight="regular" variant="primary" {...props} />
	);
};

type BodyComponentType = Exclude<keyof JSX.IntrinsicElements, "button" | "a">;

type BodyType = Omit<TypographyType, "size" | "Component" | "variant"> & {
	Component?: BodyComponentType;
};

export const Body1: FC<BodyType> = ({ Component = "p", ...props }) => {
	return (
		<Typography Component={Component} size={16} variant="primary" {...props} />
	);
};

export const Body2: FC<BodyType> = ({ Component = "p", ...props }) => {
	return (
		<Typography Component={Component} size={14} variant="primary" {...props} />
	);
};

export const Caption: FC<BodyType> = ({ Component = "p", ...props }) => {
	return (
		<Typography Component={Component} size={12} variant="primary" {...props} />
	);
};
