import React, {useState} from 'react'
import {DeployInit} from "./modals/InitModal";
import {AddTokenModal} from "./modals/AddTokenModal";
import {AddedModal} from "./modals/AddedModal";
import {MappingDeploymentModal} from "./modals/MappingDeploymentModal";
import {RegisterModal} from "./modals/RegisterModal";

export const STATUS = {
    INIT: 'INIT',
    ADD_TOKEN: 'ADD_TOKEN',
    ADDED_TOKEN: "ADDED_TOKEN",
    MAPPING_DEPLOYMENT: "MAPPING_DEPLOYMENT",
    DEPLOYED: "DEPLOYED"
}

export default function Deploy() {

    const [status, setStatus] = useState(STATUS.INIT)
    const [addedToken, setAddedToken] = useState()
    const [addHash, setAddHash] = useState()
    const [existingToken, setExistingToken] = useState()
    //const [deployedTokens, setDeployedTokens] = useState()
    const [mappingTokens, setMappingTokens] = useState()
    const [mappedRegistered, setMappedRegistered] = useState(false)


    return (
        <div className="deploy">
            {status === STATUS.INIT && (
                <DeployInit onDeploy={() => {
                    setStatus(STATUS.ADD_TOKEN)
                }}/>
            )}
            {status === STATUS.ADD_TOKEN && (
                <AddTokenModal onSuccess={(token, hash) => {
                    setAddHash(hash)
                    setAddedToken(token)
                    setStatus(STATUS.ADDED_TOKEN)
                }} toMapping={(token) => {
                    setAddedToken(token)
                    setStatus(STATUS.MAPPING_DEPLOYMENT)
                }}/>
            )}
            {status === STATUS.ADDED_TOKEN && (
                <AddedModal hash={addHash} token={addedToken} onClose={() => {
                    setStatus(STATUS.MAPPING_DEPLOYMENT)
                }}/>
            )}
            {status === STATUS.MAPPING_DEPLOYMENT && (
                <MappingDeploymentModal
                    existingToken={addedToken}
                    onNext={(existingToken, mappingTokens, mappedRegistered) => {
                        setMappingTokens(mappingTokens)
                        setExistingToken(existingToken)
                        setMappedRegistered(mappedRegistered)
                        setStatus(STATUS.DEPLOYED)
                    }}
                    onBack={() => {
                        setStatus(STATUS.ADD_TOKEN)
                    }}/>
            )}
            {status === STATUS.DEPLOYED && (
                <RegisterModal mappedRegistered={mappedRegistered} existingToken={existingToken} mappingTokens={mappingTokens} onBack={() => {
                    setStatus(STATUS.ADD_TOKEN)
                }}/>
            )}
        </div>
    )
}
