import React, { useEffect, useState, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import styles from './Airdrop.module.scss'
import { Body1, Caption } from '../../../../ui/typography'
import { Button } from '../../../../ui/action'
import { Timer } from '../timer'
import { WrongNetworkPopUp } from './wrong-network-pop-up'
import { ConfirmPopUp } from './confirm-pop-up'
import { useControlPopUp } from '../../../../hooks/ui/use-control-pop-up'
import {
  AIRDROP_START_PARTICIPATION,
  AIRDROP_END_PARTICIPATION,
} from '../../const'
import { AirDropInfo } from '../../../../hooks/useLBPInfo'
import { useAllowance, useBalance } from 'pages/Hooks'
import { formatAmount } from 'utils/format'
import { Spinner } from '../../../../ui/spinner'
import { AIRDROP_CHAIN_ID } from '../../../../const'
import { STATUS } from '../../Tokens'
import { useBlockNumber } from '../../../../web3'
import { AIRDROP_ADDRESS } from '../../../../web3/address'

export const Airdrop = ({
  info,
  onRedeem,
  onApprove,
  transactions,
}: {
  info: AirDropInfo
  onRedeem: () => Promise<any>
  onApprove: () => Promise<any>
  transactions: any
}) => {
  const { chainId, account } = useWeb3React()
  const [startTime] = useState<number>(
    new Date(AIRDROP_START_PARTICIPATION).getTime()
  )
  const [endTime] = useState<number>(
    new Date(AIRDROP_END_PARTICIPATION).getTime()
  )
  const [status, setStatus] = useState<STATUS>(
    Date.now() >= startTime
      ? Date.now() > endTime
        ? STATUS.ENDED
        : STATUS.STARTED
      : STATUS.PRE_START
  )
  const [startAirdrop, setStartAirdrop] = useState(status === STATUS.STARTED)
  const [approved, setApproved] = useState(false)
  const [spinnerOpen, setSpinnerOpen] = useState(false)
  const [approveHashLink, setApproveHashLink] = useState('')
  const [redeemHashLink, setRedeemHashLink] = useState('')

  const quota = info?.quota ? formatAmount(info.quota.toString()) : '0'
  const price = info?.price ? formatAmount(info.price.toString()) : '-'
  const cost = +quota * +price

  const blockNumber = useBlockNumber()
  const balance = useBalance(info?.token, { curChainId: chainId })
  const isDone =
    info && info.done
      ? new BigNumber(info.done.toString()).isGreaterThan('0')
      : false

  const allowance = useAllowance(info?.currency, AIRDROP_ADDRESS, {
    curChainId: chainId,
    approveStatus: '1',
  })

  const {
    popUp: networkPopUp,
    close: networkClose,
    open: networkOpen,
  } = useControlPopUp()
  const {
    popUp: confirmPopUp,
    close: confirmClose,
    open: confirmOpen,
  } = useControlPopUp()
  const {
    popUp: approvePopUp,
    close: approveClose,
    open: approveOpen,
  } = useControlPopUp()

  useEffect(() => {
    status === STATUS.STARTED ? setStartAirdrop(true) : setStartAirdrop(false)
  }, [status, setStartAirdrop])

  useEffect(() => {
    if (!info || !info.price || !info.quota) return
    const price = new BigNumber(info.price.toString())
    const quota = new BigNumber(info.quota.toString())
    if (new BigNumber(allowance!).isGreaterThan(price.multipliedBy(quota))) {
      setApproved(true)
    } else {
      setApproved(false)
    }
  }, [allowance, balance, info, account])

  useEffect(() => {
    if (!approveHashLink && !redeemHashLink) {
      setSpinnerOpen(false)
    }
  }, [approveHashLink, redeemHashLink])

  useEffect(() => {
    if (!transactions) return
    const tx = transactions.find(
      (i: any) => i.approve && i.hashLink && i.hashLink === approveHashLink
    )
    if (tx && (tx.approve.status === 1 || tx.approve.status === -1)) {
      setSpinnerOpen(false)
      setApproveHashLink('')
    }
  }, [approveHashLink, transactions, blockNumber])

  useEffect(() => {
    if (!redeemHashLink || !transactions) return
    const tx = transactions.find(
      (i: any) => i.exercise && i.hashLink && i.hashLink === redeemHashLink
    )
    if (tx && (tx.exercise.status === 1 || tx.exercise.status === -1)) {
      setSpinnerOpen(false)
      setRedeemHashLink('')
    }
  }, [redeemHashLink, transactions, blockNumber])

  const handleApprove = useCallback(() => {
    if (!onApprove) return
    approveOpen()
    setSpinnerOpen(true)
    onApprove().then((r) => {
      if (r && r.status === 'success') {
        setApproveHashLink(r.hashLink)
      } else if (r && r.status === 'failed') {
        setApproveHashLink('')
        setSpinnerOpen(false)
        approveClose()
      }
    })
  }, [approveClose, approveOpen, onApprove])

  const handleRedeem = useCallback(() => {
    if (!onRedeem) return
    setSpinnerOpen(true)
    onRedeem().then((r) => {
      if (r && r.status === 'success') {
        setRedeemHashLink(r.hashLink)
      } else if (r && r.status === 'failed') {
        setRedeemHashLink('')
        setSpinnerOpen(false)
        confirmClose()
      }
    })
  }, [confirmClose, onRedeem])

  return (
    <>
      {status === STATUS.STARTED && (
        <Button
          disabled={true}
          className={styles.timer_button}
          variant="contained"
        >
          <Timer
            timer={endTime / 1000}
            onZero={() => setStatus(STATUS.ENDED)}
          />
        </Button>
      )}
      {status === STATUS.PRE_START ? (
        <div style={{ display: 'grid', gridGap: '40px', margin: '45px' }}>
          <Body1 className={styles.title}>
            The TOKEN option airdrop will start in
          </Body1>
          <Body1 className={styles.timer}>
            <Timer
              timer={startTime / 1000}
              onZero={() => setStatus(STATUS.STARTED)}
            />
          </Body1>
        </div>
      ) : (
        <div className={styles.component}>
          <p className={styles.balanceWrapper}>
            <Caption Component="span" lighten={60}>
              Your airdropped TOKEN Option amount:
            </Caption>
            <Body1 className={styles.balance} Component="span" weight="medium">
              {quota} TOKEN
            </Body1>
          </p>
          <div style={{ height: '20px' }} />
          {!isDone && quota !== '0' && (
            <Body1 className={styles.purchase}>
              You are eligible for purchasing {isDone ? '0' : quota} TOKEN at{' '}
              {price} USDT per TOKEN
            </Body1>
          )}

          {!startAirdrop && (
            <Button
              className={styles.button}
              color="primary"
              variant="contained"
              disabled={true}
            >
              <Timer
                timer={startTime / 1000}
                onZero={() => setStatus(STATUS.PRE_START)}
              />
            </Button>
          )}
          {startAirdrop && quota !== '0' && !approved && !isDone && (
            <Button
              className={styles.button}
              color="primary"
              variant="contained"
              disabled={!startAirdrop || approved || spinnerOpen}
              onClick={
                chainId !== AIRDROP_CHAIN_ID ? networkOpen : handleApprove
              }
            >
              {spinnerOpen && (
                <>
                  <Spinner size="small" color="white" />
                  <div style={{ width: 15 }} />
                </>
              )}
              {spinnerOpen ? 'Approving USDT' : 'Approve USDT'}
            </Button>
          )}
          {startAirdrop && quota !== '0' && approved && !isDone && (
            <Button
              className={styles.button}
              color="primary"
              variant="contained"
              disabled={!approved || isDone || spinnerOpen}
              onClick={chainId !== AIRDROP_CHAIN_ID ? networkOpen : confirmOpen}
            >
              {spinnerOpen && (
                <>
                  <Spinner size="small" color="white" />
                  <div style={{ width: 15 }} />
                </>
              )}
              {spinnerOpen ? 'Redeeming Option' : 'Redeem Option'}
            </Button>
          )}
          {startAirdrop && (quota === '0' || isDone) && (
            <Button
              className={styles.button}
              color="primary"
              variant="contained"
              disabled={true}
            >
              {chainId === AIRDROP_CHAIN_ID
                ? isDone
                  ? 'You have already claimed your token'
                  : 'Sorry! You don’t have airdropped TOKEN'
                : 'Please switch to Binance Smart Chain'}
            </Button>
          )}
        </div>
      )}
      {networkPopUp.defined ? (
        <WrongNetworkPopUp control={networkPopUp} close={networkClose} />
      ) : null}
      {confirmPopUp.defined ? (
        <ConfirmPopUp
          info={info}
          control={confirmPopUp}
          close={confirmClose}
          amount={cost}
          tokensAmount={+quota}
          onRedeem={handleRedeem}
          hashLink={redeemHashLink ?? undefined}
        />
      ) : null}
      {approvePopUp.defined ? (
        <ConfirmPopUp
          info={info}
          control={approvePopUp}
          close={approveClose}
          amount={cost}
          tokensAmount={+quota}
          onApprove={onApprove}
          hashLink={approveHashLink ?? undefined}
        />
      ) : null}
    </>
  )
}
