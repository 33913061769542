import {getContract, useActiveWeb3React, useBlockNumber} from "../web3";
import {getNetworkLibrary} from "./multicall/hooks";
import ERC20 from '../web3/abi/ERC20.json'
import {useEffect, useMemo, useState} from "react";
import {TOKEN_FACTORY} from "../web3/address";
import TokenFactory from "../web3/abi/TokenFactory.json";
import {CHAIN_LIST, FACTORY_CHAIN_ID, ZERO_ADDRESS} from "../const";

export const useToken = (chainId, address, nonce) => {
  const {account} = useActiveWeb3React()
  const factoryContract = chainId && address ? getContract(getNetworkLibrary(chainId), TokenFactory, TOKEN_FACTORY) : undefined
  const contract = chainId && address ? getContract(getNetworkLibrary(chainId), ERC20, address) : undefined
  const [token, setToken] = useState({
    tokenMapped: undefined,
    name: undefined,
    symbol: undefined,
    decimals: undefined,
    totalSupply: undefined
  })
  const [certifyToken, setCertifyToken] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const tokenMapped = await factoryContract?.tokenMappeds(address)
        const name = await contract?.name()
        const symbol = await contract?.symbol()
        const decimals = await contract?.decimals()
        const totalSupply = await contract?.totalSupply()
        setToken({tokenMapped, name, symbol, decimals, totalSupply})
        setLoading(false)
      } catch (e) {
        console.error('error---->', e)
        setError(true)
      }
    }
    if (chainId && address) {
      setLoading(true)
      setError(false)
      fetchToken()
    }


  }, [chainId, address])

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const deployToken = await factoryContract?.calcContract(account, nonce)
        setCertifyToken(deployToken)
      } catch (e) {
        console.log('deployToken', e)
      }
    }

    if(chainId && address && nonce && account){
      fetchToken()
    }
  }, [chainId, address, nonce, account])


  return useMemo(() => {
    if (!token.name || !token.symbol || !token.decimals || !token.totalSupply) return {
      token: undefined,
      certifyToken: undefined,
      loading,
      error
    }
    return {token, certifyToken, loading, error}
  }, [token, certifyToken, loading, error])
}

export const useAllMappingTokens = (mainChainId, existingTokenAddress) => {

  const {blockNumber} = useBlockNumber()
  const [tokens, setTokens] = useState()

  useEffect(() => {
    const fetchMappingTokens = async () => {
      const tokens = await Promise.all(CHAIN_LIST.map(async item => {
        const factoryContract = getContract(getNetworkLibrary(item.chainId), TokenFactory, TOKEN_FACTORY)
        const address = await factoryContract.mappingTokens(mainChainId, existingTokenAddress)
        return {chainId: item.chainId, address: address === ZERO_ADDRESS ? undefined : address}
      }))
      setTokens(tokens)
    }
    if (existingTokenAddress) {
      fetchMappingTokens()
    }
  }, [existingTokenAddress, blockNumber])

  return useMemo(() => {
    if (!tokens) return tokens
    return tokens.filter(item => {
      return item.address
    })
  }, [tokens])
}

export const useMappedToken = (chainId, address) => {
  const factoryContract = chainId && address ? getContract(getNetworkLibrary(chainId), TokenFactory, TOKEN_FACTORY) : undefined
  const [mappedAddress, setMappedAddress] = useState()

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const tokenMapped = await factoryContract?.tokenMappeds(address)
        setMappedAddress(tokenMapped)
      } catch (e) {
        console.log(e)
      }

    }
    if (chainId && address) {
      fetchToken()
    }
  }, [chainId, address, factoryContract])


  return mappedAddress
}

export const useMappingTokens = (chainId, address) => {
  const factoryContract = chainId && address ? getContract(getNetworkLibrary(FACTORY_CHAIN_ID), TokenFactory, TOKEN_FACTORY) : undefined
  const [tokens, setTokens] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const mappingTokenRes = await factoryContract?.chainIdMappingTokenMappeds(address)
        setLoading(false)
        setTokens(mappingTokenRes)
      } catch (e) {
        console.log(e)
        setError(true)
      }

    }
    if (chainId && address) {
      setLoading(true)
      setError(false)
      fetchToken()
    }
  }, [chainId, address])


  return useMemo(() => {
    return {
      tokens: tokens?.chainIds.map((item, index) => {
        return {address: tokens.mappingTokenMappeds_[index], chainId: item}
      }), loading, error
    }
  }, [tokens, loading, error])
}



