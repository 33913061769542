import React, {useContext} from 'react'
import {SUPPORT_CHAINS} from "../../const";
import ArrowLeft from "../../assets/icon/arrow-left.svg";
import {formatAddress, formatAmount} from "../../utils/format";
import {CheckCircle} from "react-feather";
import {useActiveWeb3React} from "../../web3";
import {mainContext} from "../../reducer";
import Circle from "../../assets/icon/circle.svg";
import {Img} from "../../components/Img";

export const ClaimItem = ({token, data, setWithdrawData, key})=>{
  const {transactions} = useContext(mainContext).state
  const {account} = useActiveWeb3React()
  const withdrawing = transactions.find((item) => {
    const claimData = item.claim
    return claimData &&
        claimData.status === 0 &&
        claimData.nonce === data.nonce &&
        claimData.fromChainId === data.fromChainId &&
        claimData.toChainId === data.toChainId &&
        claimData.fromMainAddress === data.fromMainAddress &&
        claimData.toMainAddress === data.toMainAddress
  })

  const withdrawed = transactions.find((item) => {
    const claimData = item.claim
    return claimData &&
        claimData.status === 1 &&
        claimData.nonce === data.nonce &&
        claimData.fromChainId === data.fromChainId &&
        claimData.toChainId === data.toChainId &&
        claimData.fromMainAddress === data.fromMainAddress &&
        claimData.toMainAddress === data.toMainAddress
  })


  return (
      <div className="claim_item" key={key}>
        <div
            className="claim_item__item"
            style={{marginRight: 0}}
        >
          <p>From:</p>
          <div>
            <img src={SUPPORT_CHAINS[data.fromChainId].icon} alt=""/>
            <p>{SUPPORT_CHAINS[data.fromChainId].title}</p>
            <img
                className="arrow giant"
                src={ArrowLeft}
                alt=""
                style={{transform: 'rotate(180deg)'}}
            />
          </div>
        </div>

        <img
            className="arrow mini"
            src={ArrowLeft}
            alt=""
            style={{transform: 'rotate(180deg)'}}
        />

        <div className="claim_item__item">
          <p>To:</p>
          <div>
            <img src={SUPPORT_CHAINS[data.toChainId].icon} alt=""/>
            <p>{SUPPORT_CHAINS[data.toChainId].title}</p>
          </div>
        </div>

        <div
            className="claim_item__item giant"
            style={{width: 100}}
        >
          <p>Token:</p>
          <div>
            <Img
                src={`https://raw.githubusercontent.com/williamzng/chainswap-assets/main/blockchains/ethereum/${token.address.toLowerCase()}.png`}
                alt=""
            />
            <p>{token.symbol}</p>
          </div>
        </div>

        <div className="claim_item__item giant">
          <p>Destination:</p>
          <div>
            <p>{account && formatAddress(account)}</p>
          </div>
        </div>

        <div className="claim_item__item amount">
          <p>Amount:</p>
          <div>
            <p>
              {formatAmount(data.volume, data.decimals)}
              <span className="mini">{data.symbol}</span>
            </p>
          </div>
        </div>
        <div className="divider mini"/>
        <div className="claim_item__item mini">
          <p>Destination:</p>
          <div>
            <p>{account && formatAddress(account)}</p>
          </div>
        </div>

        {withdrawing && !withdrawed && (
            <img
                src={Circle}
                className="claim_item__item__loading"
                alt=""
            />
        )}

        {!withdrawing && (data.received || withdrawed) && (
            <CheckCircle
                style={{marginRight: 0, marginLeft: 'auto'}}
                color={'#24FF00'}
            />
        )}

        {(!withdrawing && !withdrawed) && !data.received && (
            <button
                className="token_button claim_button"
                style={{marginRight: 0}}
                onClick={() => {
                  setWithdrawData({
                    fromChainId: data.fromChainId,
                    toChainId: data.toChainId,
                    toAddress: account,
                    volume: data.volume,
                    nonce: data.nonce,
                    fromMainAddress: data.fromMainAddress,
                    toMainAddress: data.toMainAddress,
                    fromMappingAddress: data.fromMappingAddress,
                    toMappingAddress: data.toMappingAddress,
                    symbol: data.symbol,
                    decimals: data.decimals,
                    mainAddress: token.address,
                  })
                }}
            >
              Claim
            </button>
        )}
      </div>
  )
}
