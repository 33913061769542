import React, {useRef, useState} from 'react'
import {ReactComponent as DropDown} from '../../assets/icon/down.svg'
import {useOnClickOutside} from '../../hooks/useOnOutsideClick'
import {Check} from 'react-feather'

export default function ButtonMultiSelect({
                                              defaultContent,
                                              children,
                                              label,
                                              options,
                                              onSelections,
                                              selectedIds,
                                          }) {
    const node = useRef()
    const [isOpen, setIsOpen] = useState(false)
    const [selects, setSelects] = useState([])
    useOnClickOutside(node, () => setIsOpen(false))
    return (
        <div className="button_select">
            {label && (
                <div style={{marginBottom: '4px'}}>
                    <span className='text_gray text_14 text_regular'>{label}</span>
                </div>
            )}
            <button
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
                // selected={!!selectedId}
            ><div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    {options && selects
                        ? options.filter(({id}) => selects.indexOf(id) !== -1).map(item => {
                            // return item.option
                            return <span style={{marginRight:10, fontSize:16, color:'#ffffff'}}>{item.title}</span>
                        })
                        : children}
                </div>
                    {(options && selects.length === 0) && <span className='text_gray text_16'>{defaultContent} </span>}
                    </div>
                    <DropDown />
                  
            </button>
            {isOpen && options && onSelections && (
                <div className={`option_frame${isOpen ? ' is_open' : ''}`} ref={node}>
                    <div>
                        {options.map(({id, option}) => (
                            <button
                                style={{justifyContent: 'flex-start'}}
                                // className={`select_option${
                                //     selectedId === id ? ' selected' : ''
                                // }`}
                                key={id}
                                onClick={() => {
                                    //onSelections(id)
                                    //setIsOpen(false)
                                }}
                            >
                                <label>
                                    <input type="checkbox" id="checkbox"
                                           onChange={(e) => {
                                               setIsOpen(false)
                                               const checked = e.target.checked
                                               let selectIds = []
                                               if (checked) {
                                                   selectIds = selects.concat(id)
                                               } else {
                                                   selectIds = selects.filter(item => item !== id)
                                               }
                                               setSelects(selectIds)
                                               onSelections(selectIds)
                                           }}
                                           checked={selects.findIndex(item => {
                                               return item === id
                                           }) !== -1}
                                           size={16}
                                    />
                                    <i/>
                                </label>
                                {option}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
