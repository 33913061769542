import React, {useContext, useMemo, useState} from 'react'
import Circle from '../../assets/icon/circle.svg'
import {ReactComponent as Close} from '../../assets/icon/close.svg'
import {ReactComponent as Left} from '../../assets/icon/arrow-left.svg'
import {useToken} from '../../pages/Hooks'
import {isAddress} from '../../utils/address'
import Warning from '../../assets/icon/warning_purple.svg'
import {HANDLE_TOKENS} from '../../const'
import {mainContext} from '../../reducer'
import {Img} from '../Img'

export const AssetModal = ({tokenList, onSelect, onClose, selectedToken}) => {

  const {dispatch} = useContext(mainContext)
  const [searchText, setSearchText] = useState()
  const [searchToken, setSearchToken] = useState()
  const searchRes = useToken(searchToken)
  const [importToken, setImportToken] = useState()
  const [checked, setChecked] = useState(false)

  const isLocalToken = useMemo(() => {
    if (!isAddress(searchText)) return true
    return !!(
        tokenList && tokenList.find(({address}) => address === searchText)
    )
  }, [searchText, tokenList])

  return (
      <div className="modal">
        <div className="modal__box">
          <div
              className="form-app"
              style={{height: importToken ? 'fit-content' : '100%'}}
          >
            <div className="form-app__inner">
              <>
                {importToken ? (
                    <>
                      <div style={{display: ' grid'}}>
                        <Close className="close-btn" onClick={onClose}/>
                        <Left
                            onClick={() => {
                              if (importToken) setImportToken(undefined)
                            }}
                            className="back-btn"
                        />
                        <p className="form-app__title">Import a token</p>
                      </div>
                      <div className="import_heard">
                        <div className="import_heard__token">
                          <Img style={{width: 32, height: 32}}
                              src={`https://raw.githubusercontent.com/williamzng/chainswap-assets/main/blockchains/ethereum/${importToken.address.toLowerCase()}.png`}
                          />
                          <p>{importToken.name}</p>
                          <p style={{color: 'rgba(255, 255, 255, 0.4)'}}>
                            {importToken.symbol}
                          </p>
                        </div>
                        <span style={{color: '#9867FF', fontSize: 14}}>{importToken.address}</span>
                      </div>
                      <div className="import_bottom">
                        <img src={Warning} alt=""/>
                        <h5>Bridge at your own risk!</h5>
                        <p>
                          Anyone can bridge a token, including creating fake
                          versions of existing tokens that claim to represent
                          projects
                        </p>
                        <label>
                          <input
                              type="checkbox"
                              id="checkbox"
                              onChange={(e) => {
                                const checked = e.target.checked
                                setChecked(checked)
                              }}
                              size={16}
                          />
                          <i style={{marginRight: 12}}/>I understand
                        </label>
                      </div>
                      <button
                          disabled={!checked}
                          style={{width: 416, margin: 'auto', marginTop: 24}}
                          className="default_button"
                          onClick={() => {
                            dispatch({type: HANDLE_TOKENS, tokens: [importToken]})
                            setImportToken(undefined)
                          }}
                      >
                        Import
                      </button>
                    </>
                ) : (
                    <>
                      <div style={{display: ' grid'}}>
                        <Close className="close-btn" onClick={onClose}/>

                        <p className="form-app__title">Select a token</p>
                        <input
                            value={searchText}
                            onChange={(e) => {
                              const value = e.target.value.trim()
                              if (
                                  isAddress(value) &&
                                  tokenList?.findIndex((item) => {
                                    return value === item.address
                                  }) === -1
                              ) {
                                setSearchToken(value)
                              } else {
                                setSearchText(value)
                              }
                              setSearchText(e.target.value.trim())
                            }}
                            placeholder="Search by name or paste address"
                        />
                        <div className="form-app__inner__divider"/>
                      </div>
                      {isLocalToken && (
                          <div className="token_frame">
                            {tokenList &&
                            tokenList.length !== 0 &&
                            tokenList[0].chains ? (
                                tokenList
                                    .filter((item) => {
                                      return (
                                          !searchText ||
                                          item.symbol
                                              .toLowerCase()
                                              .indexOf(searchText.toLowerCase()) !== -1 ||
                                          item.address === searchText
                                      )
                                    })
                                    .map((item, index) => {
                                      return (
                                          <div
                                              className={`token__frame ${item === selectedToken ? 'disabled' : ''}`}
                                              onClick={() => {
                                                if (item !== selectedToken) {
                                                  onSelect(item)
                                                }
                                                onClose()
                                              }}
                                              key={index}
                                          >
                                            <Img
                                                style={{width: 30}}
                                                src={`https://raw.githubusercontent.com/williamzng/chainswap-assets/main/blockchains/ethereum/${item.address.toLowerCase()}.png`}
                                                alt=""
                                            />
                                            <div className="token__frame__extra">
                                              <p>{item.symbol}</p>
                                              <p>{item.name}</p>
                                            </div>
                                          </div>
                                      )
                                    })
                            ) : (
                                <img className="fetch__loading" src={Circle} alt=""/>
                            )}
                          </div>
                      )}

                      {!isLocalToken && (
                          <div className="token_frame">
                            {searchRes.loading ? (
                                <img className="fetch__loading" src={Circle} alt=""/>
                            ) : (
                                <>
                                  {searchRes && searchRes.token && (
                                      <div className="token__frame">
                                        <Img
                                            style={{width: 30}}
                                            src={`https://raw.githubusercontent.com/williamzng/chainswap-assets/main/blockchains/ethereum/${searchRes.token.address.toLowerCase()}.png`}
                                            alt=""
                                        />
                                        <div
                                            className="token__frame__extra"
                                            onClick={() => {
                                              onSelect(searchRes.token)
                                              onClose()
                                            }}
                                        >
                                          <p>{searchRes.token.symbol}</p>
                                          <p>{searchRes.token.name}</p>
                                        </div>
                                        <button
                                            onClick={() => {
                                              setImportToken(searchRes.token)
                                            }}
                                            className="default_button import_btn"
                                        >
                                          Import
                                        </button>
                                        {/*{searchRes.loading ? (*/}
                                        {/*    <p style={{ marginLeft: 'auto' }}>*/}
                                        {/*      {formatAmount(searchRes.token.balance, searchRes.token.decimals)}*/}
                                        {/*    </p>*/}
                                        {/*) : (*/}
                                        {/*    <img*/}
                                        {/*        className="fetch__loading"*/}
                                        {/*        style={{ width: 12, marginRight: 0 }}*/}
                                        {/*        src={Circle}*/}
                                        {/*        alt=""*/}
                                        {/*    />*/}
                                        {/*)}*/}
                                      </div>
                                  )}
                                </>
                            )}
                          </div>
                      )}
                    </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
  )
}
