import {useCallback, useState,useEffect} from "react";
import { ScatteredContinuousState, useScatteredContinuousState } from "./use-continuous-state";

export const useControlPopUp = (defaultVisible=false): {
  popUp: ScatteredContinuousState<boolean>;
  open(): void;
  close(): void;
  toggle(): void;
} =>{
  const [popUpVisible, setPopUpVisible] = useState(defaultVisible);

  useEffect(() => {
    setPopUpVisible(defaultVisible)
  }, [defaultVisible])

  const popUp = useScatteredContinuousState(popUpVisible, {
    timeout: 350,
  });
  const open = useCallback(() => setPopUpVisible(true), []);
  const close = useCallback(() => setPopUpVisible(false), []);
  const toggle = useCallback(() => setPopUpVisible((visible) => !visible), []);

  return {
    popUp,
    open,
    close,
    toggle,
  };
};
