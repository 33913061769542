import React, {FC} from 'react'
import styles from './ConfirmPopUp.module.scss'
import {Body2, Caption, Heading2} from '../../../../../ui/typography'
import {Button} from '../../../../../ui/action'
import BigNumber from "bignumber.js";
import {useBalance} from "../../../../Hooks";
import {AirDropInfo} from "../../../../../hooks/useLBPInfo";
import {useActiveWeb3React} from "../../../../../web3";
import {numToWei} from "../../../../../utils/format";

export const Default: FC<{
    info: AirDropInfo,
    amount: number
    tokensAmount: number
    onClick(): void
}> = ({info, amount, tokensAmount, onClick}) => {
    const {chainId} = useActiveWeb3React()
    const currencyBalance: any = useBalance(info?.currency, {
        curChainId: chainId,
    })

    const insufficientBalance = currencyBalance
        ? new BigNumber(numToWei(amount)).isGreaterThan(
            new BigNumber(currencyBalance.toString())
        )
        : true
    return (
        <div>
            <Heading2 Component="h2" className={styles.defaultTitle}>
                Option exercise confirmation
            </Heading2>
            <ul className={styles.defaultList}>
                <Caption className={styles.defaultItem} Component="li" lighten={80}>
                    You will pay
                    <Body2
                        Component="span"
                        className={styles.defaultAmount}
                        lighten={100}
                    >
                        <span className={styles.usdt}>USDT</span>
                        {amount.toString().slice(0, 10)}
                    </Body2>
                </Caption>
                <Caption className={styles.defaultItem} Component="li" lighten={80}>
                    You will get
                    <Body2
                        Component="span"
                        className={styles.defaultAmount}
                        lighten={100}
                    >
                        <span className={styles.token}>TOKEN</span>
                        {tokensAmount.toString().slice(0, 10)}
                    </Body2>
                </Caption>
            </ul>
            <Button
                disabled={insufficientBalance}
                className={styles.defaultButton}
                variant="contained"
                color="primary"
                onClick={onClick}
            >
                {insufficientBalance? 'Insufficient USDT Balance' : 'Confirm' }
            </Button>
        </div>
    )
}
