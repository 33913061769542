import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {formatAmount, numToWei} from '../../utils/format'
import {
  getContract,
  getSingleContractMultipleData,
  useActiveWeb3React,
} from '../../web3'
import Circle from '../../assets/icon/circle.svg'
import {ANTIMATTER_TRANSACTION_LIST, SIGN_COUNT, SIGN_URL_LIST, SUPPORT_CHAINS} from '../../const'
import {getNetworkLibrary} from '../../hooks/multicall/hooks'
import MainMatter from '../../web3/abi/MainMatter.json'
import {useMulticallContract} from '../../web3/useContract'
import {mainContext} from "../../reducer";
import {AssetModal} from "../../components/modal/AssetModal";
import Down from "../../assets/icon/down.svg";
import ClaimModal from "../../components/modal/ClaimModal";
import {getEtherscanLink} from "../../utils";
import {Confirming} from "../bridge/Confirming";
import {Submitted} from "../bridge/Submitted";
import {useTransactionAdder} from "../Hooks";
import {ClaimItem} from "./Item";
import {ERROR} from "../bridge/modals";
import {Img} from "../../components/Img";

export const ClaimList = () => {

  const {transactions, tokens} = useContext(mainContext).state
  const addTransaction = useTransactionAdder()
  const {account, active, library, chainId} = useActiveWeb3React()
  const [claimList, setClaimList] = useState([])
  const [loading, setLoading] = useState(false)
  const [withdrawData, setWithdrawData] = useState()
  const [attempting, setAttempting] = useState(false)
  const [hash, setHash] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [auction, setAuction] = useState('CONSENSUS')


  const [token, setToken] = useState()
  const [tokenModal, setTokenModal] = useState(false)

  const {dispatch} = useContext(mainContext)
  const deposite = transactions.find((item) => {
    return (
        item.stake && withdrawData &&
        item.stake.fromChainId === withdrawData.fromChainId &&
        item.stake.toChainId === withdrawData.toChainId &&
        item.stake.fromMainAddress === withdrawData.fromMainAddress &&
        item.stake.toMainAddress === withdrawData.toMainAddress &&
        item.stake.toMainAddress === withdrawData.toMainAddress &&
        item.nonce?.toString() === withdrawData.nonce.toString() &&
        item.stake.status !== 2
    )
  })

  const queryPairs = useMemo(() => {
    if (!token || !token.chains) return []
    const pairs = []
    for (let i = 0; i < token.chains.length; i++) {
      for (let k = token.chains.length - 1; k >= 0; k--) {
        pairs.push([token.chains[i], token.chains[k]])
      }
    }
    return pairs
  }, [token])

  const multicallContract1 = useMulticallContract(1, getNetworkLibrary(1))
  const multicallContract3 = useMulticallContract(3, getNetworkLibrary(3))
  const multicallContract4 = useMulticallContract(4, getNetworkLibrary(4))
  const multicallContract56 = useMulticallContract(56, getNetworkLibrary(56))
  const multicallContract66 = useMulticallContract(66, getNetworkLibrary(66))
  const multicallContract128 = useMulticallContract(128, getNetworkLibrary(128))
  const multicallContract137 = useMulticallContract(137, getNetworkLibrary(137))

  const AllMulticallContract = {
    1: multicallContract1,
    3: multicallContract3,
    4: multicallContract4,
    56: multicallContract56,
    66: multicallContract66,
    128: multicallContract128,
    137: multicallContract137
  }

  const fetchClaimList = useCallback(async () => {
    setLoading(true)
    const results = await Promise.all(
        queryPairs.map(async (item) => {
          const fromChainId = item[0].chainId
          const toChainId = item[1].chainId
          const fromAddress = item[0].address
          const fromMainAddress = item[0].mainAddress
          const toAddress = item[1].address
          const toMainAddress = item[1].mainAddress

          const fromMultcallContracct = AllMulticallContract[fromChainId]
          const toMultcallContracct = AllMulticallContract[toChainId]
          if (!fromMultcallContracct || !toMultcallContracct) return
          const fromContract = getContract(
              getNetworkLibrary(fromChainId),
              MainMatter,
              fromAddress
          )
          const count = await fromContract.sentCount(toChainId, account)
          const fromQueryData = []
          const toQueryData = []

          for (let i = 0; i < parseInt(count.toString()); i++) {
            fromQueryData[i] = [
              toChainId,
              account,
              parseInt(count.toString()) - (i + 1),
            ]
            toQueryData[i] = [
              fromChainId,
              account,
              parseInt(count.toString()) - (i + 1),
            ]
          }
          const sendResult = await getSingleContractMultipleData(
              fromMultcallContracct,
              getContract(getNetworkLibrary(fromChainId), MainMatter, fromAddress),
              'sent',
              fromQueryData
          )
          const reResult = await getSingleContractMultipleData(
              toMultcallContracct,
              getContract(getNetworkLibrary(toChainId), MainMatter, toAddress),
              'received',
              toQueryData
          )
          return sendResult.map((item, index) => {
            return {
              nonce: fromQueryData[index][2],
              fromChainId: fromChainId,
              toChainId: toChainId,
              mainAddress: token.address,
              fromMappingAddress: fromAddress,
              toMappingAddress: toAddress,
              fromMainAddress,
              toMainAddress,
              volume: item.toString(),
              received: item.toString() === reResult[index]?.toString(),
              symbol: token.symbol,
              decimals: token.decimals
            }
          })
        })
    )

    const allData = results.reduce((a, b) => {
      return a.concat(b)
    })
    setClaimList(allData)
    setLoading(false)
  }, [account, queryPairs])

  useEffect(() => {
    if (token && account) {
      fetchClaimList()
    }
  }, [token, account])

  const getSigns = async () => {
    if (!withdrawData) return
    return await new Promise((resolve, reject) => {
      let signList = []
      let count = 1
      for (let i = 0; i < SIGN_URL_LIST.length; i++) {
        try {
          fetch(
              `${SIGN_URL_LIST[i]}/getSignDataSyn?
              contractAddress=${withdrawData.toMappingAddress}
              &fromChainId=${withdrawData.fromChainId}
              &nonce=${withdrawData.nonce}
              &to=${withdrawData.toAddress}
              &toChainId=${withdrawData.toChainId}
              &fromContract=${withdrawData.fromMappingAddress}
              &toContract=${withdrawData.toMappingAddress}
              &mainContract=${withdrawData.mainAddress}`
          )
              .then((response) => {
                return response.json()
              })
              .then((data) => {
                count++
                if (data.data) {
                  signList.push({
                    signatory: data.data.signatory,
                    v: data.data.signV,
                    r: data.data.signR,
                    s: data.data.signS,
                    fromChainId: data.data.fromChainId,
                    to: data.data.to,
                    nonce: data.data.nonce,
                    volume: data.data.volume.toString(),
                  })
                }
                if (signList.length === SIGN_COUNT) {
                  resolve(signList)
                } else if (count === SIGN_URL_LIST.length && signList.length < SIGN_COUNT) {
                  reject('sign error')
                }
              })
        } catch (e) {
          throw new Error('Fetch sign error.')
        }
      }
    })
  }


  const onClaim = async () => {
    setAuction('CONSENSUS')
    setAttempting(true)
    const contract = getContract(
        library,
        MainMatter,
        withdrawData?.toMappingAddress,
        account
    )
    try {
      const signs = await getSigns()
      setAuction('APPROVED')

      const defaultSign = signs[0]
      await contract
          .receive(
              defaultSign.fromChainId,
              defaultSign.to,
              defaultSign.nonce,
              defaultSign.volume,
              signs.map((item) => {
                return {
                  signatory: item.signatory,
                  v: item.v,
                  r: item.r,
                  s: item.s,
                }
              }),
              {from: account, value: numToWei('0.005')}
          )
          .then((response) => {
            setAttempting(false)
            setWithdrawData(undefined)
            setHash(getEtherscanLink(chainId, response.hash, 'transaction'))
            addTransaction(response, {
              claim: {
                nonce: withdrawData.nonce,
                fromChainId: withdrawData.fromChainId,
                toChainId: withdrawData.toChainId,
                fromMainAddress: withdrawData.fromMainAddress,
                toMainAddress: withdrawData.toMainAddress,
                toAddress: account,
                status: 0,
                amount: defaultSign.volume
              },
              summary: `Withdraw ${formatAmount(defaultSign.volume, withdrawData.decimals)} ${
                  withdrawData.symbol
              } in ${SUPPORT_CHAINS[chainId].title}`,
              hashLink: getEtherscanLink(chainId, response.hash, 'transaction'),
            })

            const pastDispatch = deposite
            if (pastDispatch) {
              pastDispatch.stake.status = 2
              dispatch({
                type: ANTIMATTER_TRANSACTION_LIST,
                transaction: pastDispatch,
              })
            }
          })
    } catch (error) {
      setAttempting(false)
      if (error?.code === 4001) {
        setErrorMessage('Transaction rejected.')
      } else {
        setErrorMessage(error?.error?.message ?? 'Oops! Something went wrong')
      }
    }
  }


  return (
      <div className="default_modal claim_modal claim_list claim_list_modal">
        <div className="claim_list_modal__header bridge__input_frame__extra bridge__input_frame__claim_list mw100">
          <p style={{fontSize: 28, marginLeft: 0}}>Claim List</p>
          <div style={{width: 160, height: 36}}
               className={`asset ${!token ? 'asset_default' : 'asset_selected'} ${!active && 'asset_disabled'}`}
               onClick={() => {
                 if (active) setTokenModal(true)
               }}
          >
            {token ? (
                <div className="token-tit">
                  <Img
                      className="icon"
                      src={`https://raw.githubusercontent.com/williamzng/chainswap-assets/main/blockchains/ethereum/${token?.address?.toLowerCase()}.png`}
                      alt=""
                  />
                  <p>{token.symbol}</p>
                </div>
            ) : (
                <p className="mobile-ml10">Select a Token</p>
            )}

            <img src={Down} alt=""/>
          </div>
        </div>
        <div className="claim_list mw100" style={{width: 600}}>
          {loading ? (
              <div className="loading_frame">
                <img className="investment__modal__loading" src={Circle} alt=""/>
              </div>
          ) : (
              <>
                {!token && (
                    <p className="empty_list">
                      Please select a token to see your claim list
                    </p>
                )}
                {token && claimList.length === 0 && (
                    <p className="empty_list">
                      You currently don’t have transactions in the Claim List
                    </p>
                )}

                {token && claimList.length !== 0 &&
                claimList
                    .filter((item) => {
                      return !item.received
                    })
                    .concat(
                        claimList.filter((item) => {
                          return item.received
                        })
                    )
                    .map((item, idx) => {
                      return (
                          <ClaimItem data={item} key={idx} setWithdrawData={setWithdrawData} token={token}/>
                      )
                    })}
              </>
          )}
          <div className="mobile_marginer"/>
        </div>

        {tokenModal && (
            <div className="modal-show">
              <div className="wrapper">
                <AssetModal
                    tokenList={tokens}
                    onSelect={(token) => {
                      setToken(token)
                    }}
                    onClose={() => {
                      setTokenModal(false)
                    }}
                />
              </div>
            </div>
        )}

        {hash && (
            <div className="modal-show">
              <div className="wrapper">
                <Submitted onClose={() => {
                  setHash(undefined)
                }} hash={hash}/>
              </div>
            </div>
        )}

        {withdrawData && (
            <div className="modal-show">
              <div className="wrapper">
                <ClaimModal
                    handleClose={() => {
                      setWithdrawData(undefined)
                    }}
                    withdrawData={withdrawData}
                    account={account}
                    onClaim={onClaim}
                />
              </div>
            </div>
        )}

        {attempting && (
            <div className="modal-show">
              <div className="wrapper">
                <Confirming onClose={() => {
                  setAttempting(false)
                }} chainId={withdrawData.toChainId} auction={auction}/>
              </div>
            </div>
        )}

        {errorMessage && (
            <div className="modal-show">
              <div className="wrapper">
                <ERROR message={errorMessage} onCancel={() => {
                  setErrorMessage(null)
                }}/>
              </div>
            </div>
        )}
      </div>
  )
}
