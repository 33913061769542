import React, { FC, useEffect, useState } from 'react'

import { getDeltaTime, toDeltaTimer } from '../../utils/time'

export const Timer: FC<{ timer: number; onZero: () => void }> = ({
  timer,
  onZero,
}) => {
  const [time, setTime] = useState(getDeltaTime(timer))

  useEffect(() => {
    const tm = setInterval(() => setTime(getDeltaTime(timer)), 1000)
    return () => clearInterval(tm)
  }, [timer])

  useEffect(() => {
    if (!time) {
      onZero()
    }
  }, [time, onZero])

  return <>{toDeltaTimer(time)}</>
}
