import React from 'react'
import {RC} from "../../../../../helpers/types";
import {ScatteredContinuousState} from "../../../../../hooks/ui/use-continuous-state";
import {PopUpContainer} from "../../../../../ui/pop-up-container";
import styles from "./WrongNetworkPopUp.module.scss";
import {Body1} from "../../../../../ui/typography";

export const WrongNetworkPopUp: RC<{
  control: ScatteredContinuousState<boolean>;
  close(): void;
}> = ({close, control}) => {
  return (
    <PopUpContainer
      animated={control.present}
      visible={control.defined}
      onClose={close}
    >
      <div className={styles.component}>
        <Body1 className={styles.text} Component="h2">
          Wrong network detected!<br/>
          Please switch to BSC network to claim your rewards
        </Body1>
        <control.DefinePresent/>
      </div>
    </PopUpContainer>
  );
};
