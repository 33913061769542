import React, {useCallback, useContext, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import {Check, ChevronDown, Menu, X} from 'react-feather'
import {NavLink} from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import LogoLineWhite from '../../assets/image/chainswap-logo.svg'
import ETH from '../../assets/icon/eth.svg'
import Binance from '../../assets/icon/binance.svg'
import Okex from '../../assets/icon/okex.svg'
import Huobi from '../../assets/icon/huobi.svg'
import Polygon from '../../assets/icon/polygon.svg'
import {ReactComponent as Copy} from '../../assets/icon/copy.svg'
import {useETHBalance} from '../../hooks/balance'
import {formatAddress, formatAmount} from '../../utils/format'
import {
  SUPPORT_CHAINS,
  ANTIMATTER_TRANSACTION_LIST,
  CLEAR_ANTIMATTER_TRANSACTION_LIST,
  MODE_TYPE, CHAIN_LIST,
} from '../../const'
import {Wallets} from '../../pages/bridge/Wallets'
import {Profile} from '../../pages/bridge/Profile'
import {mainContext} from '../../reducer'
import {ModalOverlay} from './modal'
import {useActiveWeb3React} from '../../web3'
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const tabs = [
  {title: 'Swap', route: '/swap'},
  {title: 'Deploy', route: '/deploy'},
  {title: 'Liquidity', route: '/liquidity'},
  // {title: 'Farm', route: '/farm'},
  {
    title: 'Info',
    link: ' https://info.chainswap.com/',
  },
  {
    title: 'About',
    route: '/about',
    children: [
      {
        title: 'Docs',
        link: 'https://docs.chainswap.com/',
      },
      {
        title: 'Apply for whitelisting',
        link: 'https://w5h8tot8xqe.typeform.com/to/jw96fQsB',
      },
      {title: 'Support', link: 'https://info.chainswap.com/#/support'},
    ],
  },
]

export default function HeaderMain() {
  const [modalType, setModalType] = useState(MODE_TYPE.INIT)

  const {account, active, chainId} = useWeb3React()
  const balance = useETHBalance()
  const {dispatch} = useContext(mainContext)
  const {transactions} = useContext(mainContext).state

  const handleClose = useCallback(() => setModalType(MODE_TYPE.INIT), [
    setModalType,
  ])
  const handleProfile = useCallback(() => setModalType(MODE_TYPE.PROFILE), [
    setModalType,
  ])
  const handleClaimList = useCallback(
      () => setModalType(MODE_TYPE.CLAIM_LIST),
      [setModalType]
  )

  const [showMenu, setShowMenu] = useState(false)
  const [showMenuChild, setShowMenuChild] = useState(false)

  const mobileMenu = (
      <List>
        <ListItem button key='withdraw'>
          <NavLink to='/withdraw' isActive={(match, {pathname}) => Boolean(match) || pathname === '/'}>
            <ListItemText primary='Claim List'/>
          </NavLink>
        </ListItem>
        {tabs.map(({route, title, children, link}, index) => (
                children ? (
                    <>
                      <ListItem button key={title}>
                        <a onClick={(e) => {
                          e.stopPropagation()
                          setShowMenuChild(!showMenuChild)
                        }}>
                          <ListItemText>{title}<ChevronDown/></ListItemText>
                        </a>
                      </ListItem>
                      <div className="menu_children" style={{display: showMenuChild ? 'block' : 'none'}}>
                        {children.map(item => (
                            <ListItem button key={item.title}>
                              <a href={item.link} target="_brank">
                                <ListItemText primary={item.title}/>
                              </a>
                            </ListItem>
                        ))}
                      </div>
                    </>
                ) : (
                    route ? (
                        <ListItem button key={title}>
                          <NavLink to={route} isActive={(match, {pathname}) => Boolean(match) || pathname === '/'}>
                            <ListItemText primary={title}/>
                          </NavLink>
                        </ListItem>
                    ) : (
                        <ListItem button key={title}>
                          <a href={link} target="_brank">
                            <ListItemText primary={title}/>
                          </a>
                        </ListItem>
                    )
                )
            )
        )}
      </List>
  );

  return (
      <>
        <div className="logo_header">
          <NavLink to="/swap">
            <img className="logo" src={LogoLineWhite} alt=""/>
          </NavLink>
          {showMenu ? (
              <X
                  onClick={() => {
                    setShowMenu(!showMenu)
                  }}
              />
          ) : (
              <Menu
                  onClick={() => {
                    setShowMenu(!showMenu)
                  }}
              />
          )}
        </div>
        <header id="header_main" className="header_main">
          <div className="header_constant_wrapper giant">
            <NavLink to="/swap">
              <img className="logo" src={LogoLineWhite} alt=""/>
            </NavLink>
            <div className="nav_link_wrapper">
              {tabs.map(({route, title, children, link}) => {
                if (children) {
                  return (
                      <span key={title} className="nav_link parent">
                    {title}
                        <ChevronDown
                            height={16}
                            width={16}
                            style={{marginLeft: 'px'}}
                        />
                    <div
                        style={{
                          position: 'absolute',
                          top: '100%',
                          width: '140px',
                          height: '10px',
                        }}
                    />
                    <div className="nav_link_child">
                      {children.map(({title, link}) => (
                          <a
                              key={title}
                              href={link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={`nav_link${
                                  title === '' ? ' border-top' : ''
                              }`}
                          >
                            {title}
                          </a>
                      ))}
                    </div>
                  </span>
                  )
                } else if (link) {
                  return (
                      <a
                          target="_blank"
                          key={title}
                          rel="noopener noreferrer"
                          className="nav_link"
                          href={link}
                      >
                        {title}
                      </a>
                  )
                }
                return title === 'Swap' ? (
                    <NavLink
                        key={title}
                        to={route}
                        className="nav_link"
                        isActive={(match, {pathname}) =>
                            Boolean(match) || pathname === '/'
                        }
                    >
                      {title}
                    </NavLink>
                ) : (
                    <NavLink key={title} to={route} className="nav_link">
                      {title}
                    </NavLink>
                )
              })}
            </div>
          </div>
          {active && account && chainId ? (
              <>
                {SUPPORT_CHAINS[chainId] ? (
                    <HeaderLogin
                        balance={balance}
                        chainId={chainId}
                        account={account}
                        handleProfile={handleProfile}
                        handleClaimList={handleClaimList}
                    />
                ) : (
                    <div className="wrong_network">Wrong Network</div>
                )}

              </>
          ) : (
              <HeaderPreLogin
                  onLoginClick={() => {
                    setModalType(MODE_TYPE.WALLETS)
                  }}
              />
          )}
          {modalType === MODE_TYPE.WALLETS && (
              <ModalOverlay>
                <Wallets
                    onClose={handleClose}
                    onConnecting={() => {
                      setModalType(MODE_TYPE.CONNECTING)
                    }}
                />
              </ModalOverlay>
          )}
          {modalType === MODE_TYPE.PROFILE && (
              <ModalOverlay zIndex={'999'}>
                <Profile
                    account={account}
                    onClose={handleClose}
                    onWalletChange={() => {
                      setModalType(MODE_TYPE.WALLETS)
                    }}
                    transactions={transactions}
                    onClear={() => {
                      window.localStorage.setItem(
                          ANTIMATTER_TRANSACTION_LIST,
                          JSON.stringify([])
                      )
                      dispatch({type: CLEAR_ANTIMATTER_TRANSACTION_LIST})
                    }}
                />
              </ModalOverlay>
          )}
        </header>

        <Drawer anchor="bottom" open={showMenu}
                onClick={() => {
                  setShowMenu(!showMenu)
                }}
                className="mobile_menu"
        >
          {mobileMenu}
        </Drawer>
      </>
  )
}

function HeaderLogin({
                       balance,
                       account,
                       handleProfile,
                       handleClaimList,
                     }) {
  const {library} = useActiveWeb3React()
  const [copied, setCopied] = useState(false)
  const {chainId} = useActiveWeb3React()
  return (
      <>
        <div className="logo_header_wrapper">
          <div className="chain_info">
            <NavLink to="/withdraw" className="token_button">
              Claim List
            </NavLink>
            <div className="connected_chain">
              <img
                  src={chainId === 66 ? Okex : chainId === 56 ? Binance : chainId === 128 ? Huobi : chainId === 137 ? Polygon : ETH}
                  alt=""
              />
              <p>{SUPPORT_CHAINS[chainId]?.title}</p>
              <ChevronDown height={16} width={16} style={{marginLeft: 'px'}}/>
              <div className="filler"/>
              <div className="children">
                {CHAIN_LIST.map(({title, icon, chainId: chainId1}) => (
                    <button
                        key={title}
                        onClick={() => {
                          const curChain = SUPPORT_CHAINS[chainId1]
                          library.provider.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                              {
                                chainId: `0x${curChain.chainId.toString(16)}`,
                                chainName: curChain.chainName,
                                nativeCurrency: curChain.nativeCurrency,
                                rpcUrls: curChain.rpcUrls,
                                blockExplorerUrls: curChain.blockExplorerUrls,
                              },
                            ],
                          })
                        }}
                    >
                      {chainId1 === chainId ? (
                          <Check style={{marginRight: 12, width: 16}} color={'#ffffff'}/>
                      ) : (
                          <div style={{width: 16, marginRight: 12}}/>
                      )}
                      {<img src={icon} alt=""/>}
                      {title}
                    </button>
                ))}
              </div>
            </div>
            <div className="wallet">
              <p className="wallet__balance">
                {balance ? formatAmount(balance, 18, 2) : '--'}{' '}
                {SUPPORT_CHAINS[chainId]?.nativeCurrency.symbol}
              </p>
              <div className="wallet__address" onClick={handleProfile}>
                <div className="dot" onClick={handleProfile}/>
                <p>
                  {formatAddress(account)}
                </p>
                <CopyToClipboard
                    text={account}
                    onCopy={() => {
                      setCopied(true)
                      setTimeout(() => {
                        setCopied(false)
                      }, 1000)
                    }}
                >
                  {copied ? <Check/> : <Copy/>}
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-show">
          <div className="wrapper">
            <div className="default_modal">
              <p>The chainswap bridge is currently unavailable</p>
            </div>
          </div>
        </div>
      </>
  )
}

function HeaderPreLogin({onLoginClick}) {
  return (
      <>
        <div className="chain_info buttons">
          <button className="default_button" style={{paddingTop: 0, paddingBottom: 0}} onClick={onLoginClick}>
            Connect Wallet
          </button>
        </div>
      </>
  )
}
