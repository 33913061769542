import React, { FC } from 'react'
import styles from './ConfirmPopUp.module.scss'
import { Body2, Caption, Heading2 } from '../../../../../ui/typography'
import { Spinner } from '../../../../../ui/spinner'

export const Loading: FC<{ tokensAmount: number; approve?: boolean }> = ({
  tokensAmount,
  approve,
}) => {
  return (
    <div className={styles.loading}>
      <Spinner />
      <Heading2 Component="h2" className={styles.loadingTitle}>
        Waiting For Confirmation...
      </Heading2>
      {approve ? (
        <Body2 className={styles.loadingText} lighten={80}>
          Allow Chainswap to spend your USDT
        </Body2>
      ) : (
        <Body2 className={styles.loadingText} lighten={80}>
          Claim {tokensAmount} Tokens
        </Body2>
      )}
      <Caption className={styles.loadingNote} lighten={40}>
        Confirm this transaction in your wallet
      </Caption>
    </div>
  )
}
