import React, { useCallback, useState } from 'react'
import { ChevronDown } from 'react-feather'
import classNames from 'classnames'
import styles from './FarmModal.module.scss'
import { ReactComponent as MatterTokenIcon } from '../../../assets/icon/matter-token.svg'
import StakeModal from './StakeModal'
import FarmClaimModal from './FarmClaimModal'

enum STAKE_MODAL_TYPE {
  STAKE = 'stake',
  CLAIM = 'claim',
  UNSTAKE = 'unstake',
}

export default function FarmModal({
  data: {
    totalEarned,
    network,
    statistics: { apy, yourStake, pooledTotal },
  },
  active,
}: {
  active: boolean
  data: {
    network: string | JSX.Element
    totalEarned: string | number
    statistics: {
      apy: string
      yourStake: string
      pooledTotal: string
    }
  }
}) {
  const [modalType, setModalType] = useState<STAKE_MODAL_TYPE | undefined>(
    undefined
  )
  const handleClose = useCallback(() => setModalType(undefined), [setModalType])
  const handleClaim = useCallback(() => setModalType(STAKE_MODAL_TYPE.CLAIM), [
    setModalType,
  ])
  const handleStake = useCallback(() => setModalType(STAKE_MODAL_TYPE.STAKE), [
    setModalType,
  ])
  const handleUnstake = useCallback(
    () => setModalType(STAKE_MODAL_TYPE.UNSTAKE),
    [setModalType]
  )
  return (
    <>
      {modalType === STAKE_MODAL_TYPE.STAKE && (
        <StakeModal
          tokenBalance={1}
          onStake={() => {}}
          active={active}
          onClose={handleClose}
        />
      )}
      {modalType === STAKE_MODAL_TYPE.CLAIM && (
        <FarmClaimModal
          balance={'1'}
          onClaim={() => {}}
          onClose={handleClose}
        />
      )}
      <div className={classNames('app_body', styles.body)}>
        <div className="row_between">
          <div className="auto_row">
            <img src={'https://mdex.com/token-icons/bsc/lp/0xa21b4103e4501a5a3076baa31bce7d184e1e79c7.png'}/>
            <div style={{ width: 7 }} />
            <p className={styles.title}>TOKEN/WBNB LP Pool</p>
          </div>

          <div className={styles.card}>{network}</div>
        </div>
        <div className="row_between">
          <div className="auto_row">
            <span className={styles.num}>{totalEarned}</span>
            <span className={styles.unit}>Token earned</span>
          </div>
          <button className={styles.claim} onClick={handleClaim}>
            Claim Reward
          </button>
        </div>
        <div className="row_between">
          <button
            className={classNames('default_button_outlined', styles.button)}
            onClick={handleStake}
          >
            Stake
          </button>
          <div style={{ width: 24, flexShrink: 0 }} />
          <button
            className={classNames('default_button_outlined', styles.button)}
            onClick={handleUnstake}
          >
            Unstake
          </button>
        </div>
        <div className={styles.divider} />
        <Accordion
          title="Statistics"
          data={[
            { title: 'APY', content: apy },
            { title: 'Your Stake', content: yourStake },
            { title: 'Pooled Total', content: pooledTotal },
          ]}
        />
      </div>
    </>
  )
}

function Accordion({
  title,
  data,
}: {
  title: string
  data: { title: string; content: string }[]
}) {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = useCallback(() => setIsOpen((open) => !open), [setIsOpen])
  return (
    <div className={styles.accordion}>
      <button className={'row_between'} onClick={handleOpen}>
        <span>{title}</span>
        <ChevronDown
          style={{
            transform: isOpen ? 'rotate(180deg)' : 'none',
            transition: 'none',
          }}
        />
      </button>
      {isOpen && (
        <div>
          {data.map(({ title, content }) => (
            <div className="row_between">
              <p className="text_small text_gray">{title}</p>
              <p className="text_small"> {content}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
