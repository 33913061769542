import React from 'react'

export function ModalOverlay({
  children,
  zIndex,
}: {
  children: JSX.Element
  zIndex?: number
}) {
  return (
    <div className="modal_overlay" style={{ zIndex: zIndex ?? 100 }}>
      {children}
    </div>
  )
}
