import React, { SVGAttributes } from "react";

export const Left = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={62}
      height={49}
      viewBox="0 0 62 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M37 48.5l24-24-24-24" stroke="url(#Left__paint0_linear)" fill="none" />
      <path d="M19 48.5l24-24-24-24" stroke="url(#Left__paint1_linear)" fill="none"/>
      <path d="M1 48.5l24-24L1 .5" stroke="url(#Left__paint2_linear)" fill="none" />
      <defs>
        <linearGradient
          id="Left__paint0_linear"
          x1={37}
          y1={0.499}
          x2={65.636}
          y2={0.499}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.045} stopColor="#0B0B0B" />
          <stop offset={0.61} stopColor="#fff" stopOpacity={0.31} />
          <stop offset={0.974} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="Left__paint1_linear"
          x1={19}
          y1={0.502}
          x2={61}
          y2={1.004}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.255} stopColor="#0B0B0B" />
          <stop offset={0.61} stopColor="#fff" stopOpacity={0.31} />
          <stop offset={0.974} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="Left__paint2_linear"
          x1={16}
          y1={0.499}
          x2={49.5}
          y2={0.499}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.045} stopColor="#0B0B0B" />
          <stop offset={0.61} stopColor="#fff" stopOpacity={0.31} />
          <stop offset={0.974} stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  )
};

export const Right = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={62}
      height={49}
      viewBox="0 0 62 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M25 .5l-24 24 24 24" stroke="url(#Right__paint0_linear)" fill="none"/>
      <path d="M43 .5l-24 24 24 24" stroke="url(#Right__paint1_linear)" fill="none"/>
      <path d="M61 .5l-24 24 24 24" stroke="url(#Right__paint2_linear)" fill="none"/>
      <defs>
        <linearGradient
          id="Right__paint0_linear"
          x1={25}
          y1={48.501}
          x2={-3.636}
          y2={48.501}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.045} stopColor="#0B0B0B" />
          <stop offset={0.61} stopColor="#fff" stopOpacity={0.31} />
          <stop offset={0.974} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="Right__paint1_linear"
          x1={43}
          y1={48.498}
          x2={1}
          y2={47.996}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.255} stopColor="#0B0B0B" />
          <stop offset={0.61} stopColor="#fff" stopOpacity={0.31} />
          <stop offset={0.974} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="Right__paint2_linear"
          x1={46}
          y1={48.501}
          x2={12.5}
          y2={48.501}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.045} stopColor="#0B0B0B" />
          <stop offset={0.61} stopColor="#fff" stopOpacity={0.31} />
          <stop offset={0.974} stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  )
}
