import React, { useState } from 'react'
import { ReactComponent as CopyIcon } from '../assets/icon/copy.svg'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Check } from 'react-feather'


export default function Copy({ text }) {
  const [copied, setCopied] = useState(false)
  return (<CopyToClipboard
    text={text}
    onCopy={() => {
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 1000)
    }}
  >
    {copied ? <Check style={{ marginLeft: 10, width: 14, height: 14 }} /> : <CopyIcon style={{ marginLeft: 10, width: 14, height: 14 }} />}
  </CopyToClipboard>)
}