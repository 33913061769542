import classNames from 'classnames'
import React from 'react'
import type { FC } from 'react'

import styles from './Shadow.module.scss'
import { MaybeWithClassName } from '../../helpers/types'

type ShadowType = {
  animated: boolean
  visible: boolean
  focusLock?: boolean
}

type ComponentType = ShadowType

export const Shadow: FC<ComponentType & MaybeWithClassName> = ({
  className,
  visible,
  animated,
  focusLock,
}) => {
  return (
    <div
      className={classNames(
        className,
        styles.component,
        visible && styles.visible,
        animated && styles.animation
      )}
    />
  )
}
