import React from 'react'
import { ArrowLeft } from 'react-feather'
import classNames from 'classnames'
import { ModalOverlay } from 'components/header/modal'
import styles from './FarmModal.module.scss'

export default function FarmClaimModal({
  balance,
  onClaim,
  onClose,
}: {
  balance: string
  onClaim: () => void
  onClose: () => void
}) {
  return (
    <ModalOverlay>
      <div className={classNames('app_body', styles.body)}>
        <div className="row_between">
          <ArrowLeft className="btn_close " onClick={onClose} />
          <p className="text_title text_22">Claim</p>
          <div />
        </div>
        <div>
          <div className={styles.num} style={{ margin: 0 }}>
            {balance}
          </div>
          <div className={styles.unit}>unit</div>
        </div>
        <div style={{ textAlign: 'center' }}>
          When you claim without withdrawing your liquidity remains in the
          mining pool
        </div>
        <button className="default_button" onClick={onClaim}>
          Claim
        </button>
      </div>
    </ModalOverlay>
  )
}
