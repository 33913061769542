import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { ReactComponent as Close } from '../../assets/icon/close.svg'
import { numToWei } from '../../utils/format'
import { SwapItem } from '../../pages/bridge/SwapItem'
import { formatAddress, formatAmount } from '../../utils/format'
import {SUPPORT_CHAINS} from "../../const";
import {Img} from "../Img"
import {useActiveWeb3React} from "../../web3";
import {useETHBalance} from "../../hooks/balance";

export default function ClaimModal({
  handleClose,
  withdrawData,
  onClaim,
}) {

    const {chainId, account} = useActiveWeb3React()
    const balance = useETHBalance()

    const isEnoughGas = useMemo(() => {
    if (!account || !chainId) return false
    return new BigNumber(balance).isGreaterThan(numToWei('0.005'))
  }, [account, balance, chainId])

  return (
    <div className="default_modal claim_modal">
      <Close className="close-btn" onClick={handleClose} />
      <div>
        <p
          className="default_modal__title"
          style={{
            opacity:
              chainId !== withdrawData.toChainId
                ? 1
                : 0.4,
          }}
        >
          1. Please switch your wallet network to{' '} {withdrawData && SUPPORT_CHAINS[withdrawData.toChainId].title} to complete token swap.
        </p>
        <p className="default_modal__title"
        style={{
            paddingBottom: 0,
            opacity:
            account !== withdrawData.toAddress
                ? 1
                : 0.4,
          }}
        >2. Also please switch to your wallet with the destination address </p>
        {chainId !== withdrawData.toChainId &&
        <>
          <div style={{height: 25}}/>
          <SwapItem data={withdrawData} />
          <div className="chain_tip">
            <p>Destination:</p>
            <p>{withdrawData && formatAddress(withdrawData.toAddress, 12, -8)}</p>
          </div>
        </>
        }
      </div>

      <div className="divider"
        style={{width: 'calc(100% + 80px)'}}
      />

      <div
        style={{
          width: '100%',
          opacity:
            chainId === withdrawData.toChainId &&
            account === withdrawData.toAddress
              ? 1
              : 0.2,
        }}
      >
        <p className="default_modal__title" style={{ width: '100%' }}>
          3. Confirm Withdraw
        </p>
        <p className="claim__amount">
          {withdrawData && formatAmount(withdrawData.volume, withdrawData.decimals)}{' '}
        </p>
        <div className="claim__token">
          <Img src={`https://raw.githubusercontent.com/williamzng/chainswap-assets/main/blockchains/ethereum/${withdrawData.mainAddress.toLowerCase()}.png`} />
          <span>{withdrawData.symbol}</span>
        </div>
        {chainId === withdrawData.toChainId &&
        <>
          <SwapItem data={withdrawData} />
          <div className="chain_tip">
            <p>Destination:</p>
            <p>{withdrawData && formatAddress(withdrawData.toAddress, 12, -8)}</p>
          </div>
        </>
        }
      </div>
      <button
        disabled={
          withdrawData &&
          (chainId !== withdrawData.toChainId ||
            account !== withdrawData.toAddress ||
            !isEnoughGas)
        }
        style={{marginTop: 25}}
        onClick={onClaim}
        className={withdrawData.chainId === chainId && withdrawData.toAddress === account ? 'notice_button' : 'default_button'}
      >
        {isEnoughGas ? (
          `Withdraw on ${
            withdrawData && SUPPORT_CHAINS[withdrawData.toChainId].title
          } Chain`
        ) : (
          'Insufficient balance for gas'
        )}
      </button>
    </div>
  )
}
