import React, { FC, ReactNode, useCallback } from 'react'
import classNames from 'classnames'
import styles from './Input.module.scss'
import { Field, useField } from 'react-final-form'
import { MaybeWithClassName } from '../../helpers/types'
import { TextColor } from '../text-color'
import { Button } from 'ui/action'

type InputType = {
  name: string
  label: string | ReactNode
  type: 'text' | 'email' | 'number'
  placeholder?: string
  readOnly?: boolean
  initialValue?: any
  required?: boolean
  min?: number
  max?: number
  validate?: (value: string) => any
  defaultValue?: any
}

export const Input: FC<InputType & MaybeWithClassName> = ({
  className,
  name,
  label,
  type,
  placeholder,
  readOnly,
  initialValue,
  required,
  validate,
  min,
  max,
  defaultValue,
}) => {
  const {
    input: { onChange },
  } = useField(name)
  const handleMax = useCallback(() => onChange(max), [max, onChange])
  return (
    <Field name={name} initialValue={initialValue} validate={validate}>
      {({ input, meta }) => (
        <div className={classNames(className, styles.component)}>
          <label className={styles.label}>
            {label}
            <input
              defaultValue={defaultValue}
              {...input}
              className={styles.input}
              type={type}
              placeholder={placeholder}
              readOnly={readOnly}
              required={required}
              min={min}
              max={max}
            />
            {(max || max == 0) && (
              <Button className={styles.max} onClick={handleMax}>
                Max
              </Button>
            )}
          </label>
          <div className={styles.error}>
            {meta.error && meta.touched && (
              <TextColor color="purple">{meta.error}</TextColor>
            )}
            {meta.submitError && (
              <TextColor color="purple">{meta.submitError}</TextColor>
            )}
          </div>
        </div>
      )}
    </Field>
  )
}
