import React from 'react'

export const DeployInit = ({onDeploy}) => {

    return (
        <div style={{paddingBottom: 40}} className="default_modal modal_init">
            <p className="default_modal__title">Please select the following options for deployment</p>
            <button className="btn_big" onClick={onDeploy}>
                <p>Existing Token</p>
                <p>You already deployed a token on Ethereum or EVM supportive chains</p>
            </button>
            <button disabled style={{marginTop: 20, opacity: 0.5}} onClick={onDeploy}>
                <p>New Token (Coming soon)</p>
                <p>You haven’t deployed any token contract yet</p>
            </button>
        </div>
    )
}
