import React from 'react'
import Huobi_logo from './assets/icon/huobi.svg'
import Binnace_logo from './assets/icon/binance.svg'
import OKEX_logo from './assets/icon/okex.svg'
import ETH_logo from './assets/icon/eth.svg'
import Polygon_logo, {ReactComponent as Polygon} from './assets/icon/polygon.svg'
import {ReactComponent as ETH} from './assets/icon/eth.svg'
import {ReactComponent as Binance} from './assets/icon/binance.svg'
import {ReactComponent as Huobi} from './assets/icon/huobi.svg'
import {ReactComponent as OKEX} from './assets/icon/okex.svg'
import {ReactComponent as POLYGON} from './assets/icon/polygon.svg'
import WHITELIST from './assets/whitelistTokens.json'
import WHITELIST_TEST from './assets/whitelistTokens_test.json'


import {numToWei} from "./utils/format";

export const FACTORY_CHAIN_ID = 1

export const LBP_CHAIN_ID = 1

export const AIRDROP_CHAIN_ID = 56

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const TX_GAS = numToWei('0.005')

export const GALLERY_SELECT_WEB3_CONTEXT =
    'ANTIMATTER_SELECT_WEB3_CONTEXT_RELEASE'

export const HANDLE_WALLET_MODAL = 'HANDLE_WALLET_MODAL'

export const ANTIMATTER_TRANSACTION_LIST = 'ANTIMATTER_TRANSACTION_LIST'

export const CHAINSWAP_TOKENS = 'CHAINSWAP_TOKENS'

export const CLEAR_ANTIMATTER_TRANSACTION_LIST =
    'CLEAR_ANTIMATTER_TRANSACTION_LIST'

export const HANDLE_POPUP_LIST = 'HANDLE_POPUP_LIST'

export const HANDLE_TOKENS = 'HANDLE_TOKENS'

export const HANDLE_LBP_SLIPPAGE = 'HANDLE_LBP_SLIPPAGE'

export const MODE_TYPE = {
  INIT: 'INIT',
  WALLETS: 'WALLETS',
  PROFILE: 'PROFILE',
  CONNECTING: 'CONNECTING',
  CONNECTED: 'CONNECTED',
  CONNECT_ERROR: 'CONNECT_ERROR',
  SWITCH_CHAIN: 'SWITCH_CHAIN',
  NOT_ELIGIBLE: 'NOT_ELIGIBLE',
  CONTRIBUTE_SUCCESS: 'CONTRIBUTE_SUCCESS',
  CONTRIBUTED: 'CONTRIBUTED',
  WAITING: 'WAITING',
  CLAIMED: 'CLAIMED',
  SUBMITTED: 'SUBMITTED',
  CLAIM_LIST: 'CLAIM_LIST',
  ERROR: 'ERROR',
}

export const CHAINS = {
  1: {
    title: 'ETH',
    symbol: 'ETH',
    chainId: 1,
    logo: <ETH style={{width: 24}} className="icon"/>,
    icon: ETH_logo,
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3/3f6f55ba1ee540328662f8496ddbc228'],
    blockExplorerUrls: ['https://etherscan.io'],
  },
  56: {
    title: 'BSC',
    symbol: 'BNB',
    chainId: 56,
    logo: <Binance style={{width: 24}} className="icon"/>,
    icon: Binnace_logo,
    chainName: 'Binance Smart Chain Mainnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [
      'https://bsc-dataseed3.binance.org',
      'https://bsc-dataseed1.binance.org',
      'https://bsc-dataseed2.binance.org',
    ],
    blockExplorerUrls: ['https://bscscan.com/'],
  },
  66: {
    title: 'OEC',
    symbol: 'OKT',
    chainId: 66,
    logo: <OKEX style={{width: 24}} className="icon"/>,
    icon: OKEX_logo,
    chainName: 'OKExChain Mainnet',
    nativeCurrency: {
      name: 'OKT',
      symbol: 'OKT',
      decimals: 18,
    },
    rpcUrls: [
      'https://exchainrpc.okex.org'
    ],
    blockExplorerUrls: ['https://www.oklink.com/okexchain'],
  },
  128: {
    title: 'HECO',
    symbol: 'HT',
    chainId: 128,
    logo: <Huobi style={{width: 24}} className="icon"/>,
    icon: Huobi_logo,
    chainName: 'Huobi ECO Chain',
    nativeCurrency: {
      name: 'HT',
      symbol: 'HT',
      decimals: 18,
    },
    rpcUrls: ['https://http-mainnet.hecochain.com'],
    blockExplorerUrls: ['https://hecoinfo.com/'],
  },
  137: {
    title: 'Polygon',
    symbol: 'MATIC',
    chainId: 137,
    logo: <POLYGON style={{width: 24}} className="icon"/>,
    icon: Polygon_logo,
    chainName: 'Matic Network',
    nativeCurrency: {
      name: 'Matic Token',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://matic-mainnet-archive-rpc.bwarelabs.com'],
    blockExplorerUrls: ['https://explorer-mainnet.maticvigil.com'],
  }
}

export const CHAINS_TEST = {
  3: {
    title: 'Ropsten',
    symbol: 'ETH',
    chainId: 3,
    logo: <ETH style={{width: 24}} className="icon"/>,
    icon: ETH_logo,
    chainName: 'Ropsten Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://ropsten.infura.io/v3/3f6f55ba1ee540328662f8496ddbc228'],
    blockExplorerUrls: ['https://ropsten.etherscan.io/'],
  },
  4: {
    title: 'Rinkeby',
    symbol: 'ETH',
    chainId: 4,
    logo: <ETH style={{width: 24}} className="icon"/>,
    icon: ETH_logo,
    chainName: 'Rinkeby Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://rinkeby.infura.io/v3/3f6f55ba1ee540328662f8496ddbc228'],
    blockExplorerUrls: ['https://rinkeby.etherscan.io/'],
  }
}

export const SUPPORT_CHAINS = FACTORY_CHAIN_ID === 3 ? CHAINS_TEST : CHAINS

const CHAIN_LIST_TEST = [
  {
    id: 0,
    title: 'Ropsten',
    chainId: 3,
    logo: <ETH className="icon"/>,
    icon: ETH_logo,
  },
  {
    id: 1,
    title: 'Rinkeby',
    chainId: 4,
    logo: <ETH className="icon"/>,
    icon: ETH_logo,
  }
]

export const CHAIN_LIST = FACTORY_CHAIN_ID === 3 ? CHAIN_LIST_TEST : [
  {
    id: 0,
    title: 'ETH',
    chainId: 1,
    logo: <ETH className="icon"/>,
    icon: ETH_logo,
  },
  {
    id: 1,
    title: 'BSC',
    chainId: 56,
    logo: <Binance className="icon"/>,
    icon: Binnace_logo,
  },
  {
    id: 2,
    title: 'OEC',
    chainId: 66,
    logo: <OKEX className="icon"/>,
    icon: OKEX_logo,
  },
  {
    id: 3,
    title: 'HECO',
    chainId: 128,
    logo: <Huobi className="icon"/>,
    icon: Huobi_logo,
  },
  {
    id: 4,
    title: 'Polygon',
    chainId: 137,
    logo: <Polygon style={{width: 24}} className="icon"/>,
    icon: Polygon_logo,
  }
]


export const DEFAULT_TOKEN = {
  symbol: 'MATTER',
  address: '0x1C9491865a1DE77C5b6e19d2E6a5F1D7a6F2b25F',
  chainId: 1,
  name: ['Antimatter.Finance Governance Token'],
  decimals: [18],
  balance: '0',
  chains: [
    {
      chainId: 1,
      address: '0x1C9491865a1DE77C5b6e19d2E6a5F1D7a6F2b25F',
    },
    {
      chainId: 128,
      address: '0x1C9491865a1DE77C5b6e19d2E6a5F1D7a6F2b25F',
    },
    {
      chainId: 56,
      address: '0x1C9491865a1DE77C5b6e19d2E6a5F1D7a6F2b25F',
    },
    {
      chainId: 66,
      address: '0x1C9491865a1DE77C5b6e19d2E6a5F1D7a6F2b25F',
    },
  ],
}

export const WHITELIST_TOKENS = FACTORY_CHAIN_ID === 3 ? WHITELIST_TEST : WHITELIST

const SIGN_URLS = [
  'https://node1.chainswap.exchange/web',
  'https://node2.chainswap.exchange/web',
  'https://node3.chainswap.exchange/web',
  'https://node4.chainswap.exchange/web',
  'https://node5.chainswap.exchange/web'
]

const SIGN_URLS_TEST = [
    'https://test.chainswap.exchange/web'
]

export const SIGN_URL_LIST = FACTORY_CHAIN_ID === 3 ? SIGN_URLS_TEST : SIGN_URLS

export const SIGN_COUNT = FACTORY_CHAIN_ID === 3 ? 1: 3

export const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

export const APPROVE_STATUS = {
  UNAPPROVE: "UNAPPROVE",
  APPROVING: "APPROVING",
  APPROVED: "APPROVED"
}
