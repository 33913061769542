import React, { useContext, useState } from 'react'
import { formatAddress } from '../../utils/format'
import { ReactComponent as Close } from '../../assets/icon/close.svg'
import {
  ANTIMATTER_TRANSACTION_LIST,
  CLEAR_ANTIMATTER_TRANSACTION_LIST,
} from '../../const'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Check, CheckCircle, Triangle } from 'react-feather'
import { ReactComponent as Copy } from '../../assets/icon/copy.svg'
import Circle from '../../assets/icon/circle.svg'
import { mainContext } from '../../reducer'

export const Profile = ({ account, transactions, onClose, onWalletChange }) => {
  const { dispatch } = useContext(mainContext)

  const [copied, setCopied] = useState(false)

  return (
    <div className="default_modal modal_profile" style={{zIndex: 999}}>
      <p className="default_modal__title">Connected with MetaMask</p>
      <div className="modal_profile__address">
        <div className="dot" />
        <p>{account && formatAddress(account)}</p>
      </div>

      <div className="modal_profile__copy">
        <Close className="close-btn" onClick={onClose} />

        <CopyToClipboard
          text={account}
          onCopy={() => {
            setCopied(true)
            setTimeout(() => {
              setCopied(false)
            }, 1000)
          }}
        >
          {copied ? <Check size={12} /> : <Copy size={12} />}
        </CopyToClipboard>
        <p>{'Copy Address'}</p>
      </div>

      <div className="btn_group mw100" style={{ width: 418, padding: '36px 0' }}>
        <button className="default_button_outlined" onClick={onWalletChange}>
          Change
        </button>
        <button className="default_button" onClick={onClose}>
          Close
        </button>
      </div>

      <div className="transactions mw100" style={{width: 418}}>
        <p>
          Recent Transactions
          <a
            href="/#"
            onClick={() => {
              window.localStorage.setItem(
                ANTIMATTER_TRANSACTION_LIST,
                JSON.stringify([])
              )
              dispatch({ type: CLEAR_ANTIMATTER_TRANSACTION_LIST })
            }}
            rel="noopener noreferrer"
            className="clear"
          >
            (clear all)
          </a>
        </p>

        <ul>
          {transactions.map((item) => {
            return (
              <li>
                <a
                  href={item.hashLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {item.summary}
                </a>
                {!item.receipt ? (
                  <img
                    className="confirm_modal__loading"
                    style={{ width: 14, height: 14 }}
                    src={Circle}
                    alt=""
                  />
                ) : item.receipt.status === 1 ? (
                  <CheckCircle size={14} color={'#27AE60'} />
                ) : (
                  <Triangle size={14} />
                )}
              </li>
            )
          })}
        </ul>
      </div>
      <div className="mobile_marginer"></div>
    </div>
  )
}
