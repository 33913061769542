import React, {useContext, useEffect, useState} from 'react'
import {ReactComponent as Close} from '../../../assets/icon/close.svg'
import {mainContext} from "../../../reducer";
import {HANDLE_LBP_SLIPPAGE, HANDLE_WALLET_MODAL} from "../../../const";

export function ImpactModal({onClose}) {

    const [defaultImpact, setDefaultImpact] = useState(1)
    const [impact, setImpact] = useState()
    const [error, setError] = useState(false)
    const {dispatch} = useContext(mainContext)

    useEffect(()=>{
        const lbpSlippage = window.localStorage.getItem('LBP_SLIPPAGE')
        const lbpDefaultSlippage = window.localStorage.getItem('LBP_DEFAULT_SLIPPAGE')
        if(lbpDefaultSlippage){
            setDefaultImpact(parseInt(lbpDefaultSlippage))
            if(lbpDefaultSlippage === '-1' && lbpSlippage){
                setImpact(lbpSlippage/ 100)
            }
        }
    }, [])
    return (
        <div style={{paddingBottom: 30}} className="default_modal">
            <Close className="close-btn" onClick={onClose}/>
            <p>Transaction Settings</p>

            <p className="impact_helper"
               style={{width: '100%', marginLeft: 80, textAlign: 'left', fontSize: 16, marginTop: 20}}>Slippage
                tolerance</p>
            <div className="impact_frame">
                <button onClick={(() => {
                    setDefaultImpact(0)
                    window.localStorage.setItem('LBP_SLIPPAGE', '50')
                    window.localStorage.setItem('LBP_DEFAULT_SLIPPAGE', '0')
                    dispatch({
                        type: HANDLE_WALLET_MODAL,
                        walletModal: null,
                    });
                })} className={defaultImpact === 0 ? `impact_active` : ''}>0.5%
                </button>
                <button onClick={(() => {
                    setDefaultImpact(1)
                    dispatch({
                        type: HANDLE_LBP_SLIPPAGE,
                        slippage: 50,
                    })
                    window.localStorage.setItem('LBP_SLIPPAGE', '500')
                    window.localStorage.setItem('LBP_DEFAULT_SLIPPAGE', '1')
                })} className={defaultImpact === 1 ? `impact_active` : ''}>5%
                </button>
                <button onClick={(() => {
                    setDefaultImpact(2)
                    dispatch({
                        type: HANDLE_LBP_SLIPPAGE,
                        slippage: 500,
                    })
                    window.localStorage.setItem('LBP_SLIPPAGE', '1000')
                    window.localStorage.setItem('LBP_DEFAULT_SLIPPAGE', '2')
                })} className={defaultImpact === 2 ? `impact_active` : ''}>10%
                </button>
                <input
                    value={impact}
                    onFocus={() => {
                        setDefaultImpact(-1)
                        window.localStorage.setItem('LBP_DEFAULT_SLIPPAGE', '2')
                        window.localStorage.setItem('LBP_SLIPPAGE', '1000')
                        dispatch({
                            type: HANDLE_LBP_SLIPPAGE,
                            slippage: 1000,
                        });
                    }}
                    onChange={((e) => {
                        setError(false)
                        setImpact(e.target.value)
                        const value = Number.parseInt((Number.parseFloat(e.target.value) * 100).toString())
                        if (!Number.isNaN(value) && value < 5000) {
                            window.localStorage.setItem('LBP_DEFAULT_SLIPPAGE', '-1')
                            window.localStorage.setItem('LBP_SLIPPAGE', value.toString())
                            dispatch({
                                type: HANDLE_LBP_SLIPPAGE,
                                slippage: value,
                            });
                        } else {
                            setError(true)
                        }
                    })} className={`impact_input ${defaultImpact === -1 ? 'impact_active' : ''}`}/>
                <p className="impact_icon">%</p>
            </div>
            {error && <p className="slippage_error">Enter a valid slippage percentage</p>}
        </div>
    )
}
