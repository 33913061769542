import React from 'react'
import Circle from "../../../assets/icon/circle.svg";
import {ReactComponent as Close} from "../../../assets/icon/close.svg";
import Error from '../../../assets/icon/error.svg'

export const WAITING = () => {
  return (
      <div className="default_modal connecting">
        <p className="default_modal__title">It takes a few minutes to populate your staking transaction record
          on the other chain. Please wait patiently.</p>
        <img className="investment__modal__loading" src={Circle} alt=""/>
      </div>
  )
}

export const CONNECTING = () => {
  return (
      <div className="investment__modal connecting">
        <p className="investment__modal__title">Please wait a little...</p>
        <img className="investment__modal__loading" src={Circle} alt=""/>
      </div>
  )
}

export const ERROR = ({message, onCancel, onOk}) => {
  return (
      <div className="default_modal error_modal">
        <Close className="close-btn" onClick={onCancel}/>
        <img src={Error} alt=""/>

        <p>{message}</p>

        <div className="btn_group" style={{paddingTop: 30,}}>
          <button  className="default_button" onClick={onCancel}>Close
          </button>
          {/* <button className="default_button" onClick={onOk}>Try again
          </button> */}
        </div>
      </div>
  )
}

export const GASERROR = ({onCancel, onOk}) => {
  return (
      <div className="default_modal error_modal">
        <Close className="close-btn" onClick={onCancel}/>
        <img src={Error} alt=""/>
        <p style={{fontSize: 26}}>Insufficient balance for gas</p>

        <p style={{fontSize: 16, marginTop: 12}}>Please recharge your wallet will more gas for further actions</p>

        <div className="btn_group" style={{padding: 24}}>
          <button className="default_button" onClick={onCancel}>Close
          </button>
          <button className="default_button" onClick={onOk}>Try again
          </button>
        </div>
      </div>
  )
}

