import React, {useContext, useEffect, useMemo, useState} from 'react'
import Exchange from '../../assets/icon/exchange.svg'
import {
  useAllowance,
  useBalance,
  useMediaWiderThan,
  useTransactionAdder,
} from '../Hooks'
import {useWeb3React} from '@web3-react/core'
import {formatAmount, numToWei} from '../../utils/format'
import Down from '../../assets/icon/down.svg'
import {
  SUPPORT_CHAINS,
  CHAIN_LIST,
  ANTIMATTER_TRANSACTION_LIST,
  MODE_TYPE, SIGN_URL_LIST, SIGN_COUNT, inputRegex,
} from '../../const'
import BigNumber from 'bignumber.js'
import {getContract, switchChain} from '../../web3'
import MainMatter from '../../web3/abi/MainMatter.json'
import {isAddress} from '../../utils/address'
import Circle from '../../assets/icon/circle.svg'
import {mainContext} from '../../reducer'
import {Check} from 'react-feather'
import {escapeRegExp, getEtherscanLink} from '../../utils'
import {AssetModal} from '../../components/modal/AssetModal'
import {CONNECTING, ERROR, WAITING} from './modals'
import {Confirm} from './Confirm'
import {Submitted} from './Submitted'
import {Confirming} from './Confirming'
import {Footer} from './Footer'
import ButtonSelect from '../../components/button/ButtonSelect'
import {useETHBalance} from '../../hooks/balance'
import ClaimModal from '../../components/modal/ClaimModal'
import {Wallets} from './Wallets'

export const Bridge = () => {
  const {library, account, active, chainId} = useWeb3React()

  const {transactions} = useContext(mainContext).state
  const {tokens} = useContext(mainContext).state
  const {dispatch} = useContext(mainContext)
  const balance = useETHBalance()

  const [selectedToken, setSelectedToken] = useState()
  const [depositData, setDepositData] = useState()
  const [toChain, setToChain] = useState()
  const [fromChain, setFromChain] = useState()
  const [modalType, setModalType] = useState(MODE_TYPE.INIT)
  const [hash, setHash] = useState()
  const [withdrawData, setWithdrawData] = useState()
  const [amount, setAmount] = useState()
  const [inputAccount, setInputAccount] = useState('')
  const [inputError, setInputError] = useState()
  const [fromChainList, setFromChainList] = useState(CHAIN_LIST)
  const [toChainList, setToChainList] = useState(CHAIN_LIST)
  const [auction, setAuction] = useState('DEPOSIT')
  const [selectingToken, setSelectingToken] = useState(false)
  const [attempting, setAttempting] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const isMediaWiderThanSm = useMediaWiderThan('sm')

  const addTransaction = useTransactionAdder()

  const deposite = transactions.find((item) => {
    return (
        item.stake &&
        account === item.stake.fromAddress &&
        item.stake.status !== 2
    )
  })

  const fromTokenChain = useMemo(() => {
    if (!selectedToken || !fromChain) return undefined
    return selectedToken.chains.find(({chainId}) => {
      return chainId === fromChain.chainId
    })
  }, [selectedToken, fromChain])

  const {tokenBalance, loading} = useBalance(fromTokenChain?.mainAddress, {curChainId: fromTokenChain?.chainId})

  const toTokenChain = useMemo(() => {
    if (!selectedToken || !toChain) return undefined
    return selectedToken.chains.find(({chainId}) => {
      return chainId === toChain.chainId
    })
  }, [selectedToken, toChain])

  const selectButtonChainOptions = useMemo(
      () =>
          toChainList.map(({chainId, logo, title}) => ({
            id: chainId,
            option: (
                <>
                  {logo}
                  {title}
                </>
            ),
          })),
      [toChainList]
  )

  const selectFromChainOptions = useMemo(
      () =>
          fromChainList.map(({chainId, logo, title}) => ({
            id: chainId,
            option: (
                <>
                  {logo}
                  {title}
                </>
            ),
          })),
      [fromChainList]
  )

  const isEnoughGas = useMemo(() => {
    if (!account || !chainId) return false
    return new BigNumber(balance).isGreaterThan(numToWei('0.005'))
  }, [account, chainId, balance])


  const withdraw = transactions.find((item) => {
    return item.claim && item.claim.isSwap && item.claim.status === 0
  })
  const approve = transactions.find((item) => {
    return (
        item.approve &&
        item.approve.status === 0 &&
        item.approve.account === account
    )
  })

  const onClose = () => {
    setModalType(MODE_TYPE.INIT)
  }

  useEffect(() => {
    if (
        !chainId ||
        !withdrawData ||
        !library ||
        chainId.toString() === withdrawData.toChainId.toString() ||
        withdrawData.toChainId.toString() === '1'
    )
      return

    const curChain = SUPPORT_CHAINS[withdrawData.toChainId]

    library.provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: `0x${curChain.chainId.toString(16)}`,
          chainName: curChain.chainName,
          nativeCurrency: curChain.nativeCurrency,
          rpcUrls: curChain.rpcUrls,
          blockExplorerUrls: curChain.blockExplorerUrls,
        },
      ],
    })
  }, [library, chainId, withdrawData])

  const approveStatus = useMemo(() => {
    if (!fromTokenChain) return false
    return fromTokenChain.address !== fromTokenChain.mainAddress
  }, [fromTokenChain])

  const curAllowance = useAllowance(
      fromTokenChain?.mainAddress,
      fromTokenChain?.address,
      {curChainId: fromTokenChain?.chainId, approveStatus}
  )

  const sendButton = useMemo(() => {
    const ret = {
      el: '',
      className: 'notice_button'
    }
    if (deposite && deposite.stake.status === 1) {
      ret.el = 'Deposited'
      ret.className = 'primary_button'
      return ret
    }
    if (approveStatus && approve) {
      ret.el =
          <>
            <img src={Circle} className="confirm_modal__loading" alt=""/>
            <p>Approving</p>
          </>
      return ret
    }

    if (approveStatus && new BigNumber(curAllowance).isEqualTo('0')) {
      ret.className = 'default_button'
      ret.el = `Allow Chainswap to use your ${selectedToken.symbol}`
      return ret
    }

    if (deposite && deposite.stake.status === 0) {
      ret.el = <>
        <img src={Circle} className="confirm_modal__loading" alt=""/>
        <p>Depositing</p>
      </>
      return ret
    }

    if (withdraw) {
      ret.el = 'Deposit'
      ret.className = 'primary_button'
      return ret
    }

    if (!fromChain) {
      ret.el = 'Please select a from chain'
      return ret
    }

    if (!toChain) {
      ret.el = 'Please select a to chain'
      return ret
    }

    if (!amount) {
      ret.el = 'Enter amount'
      return ret
    }

    if (!deposite || (deposite && deposite.stake.status === 2)) {
      ret.el = `Deposit in ${SUPPORT_CHAINS[chainId]?.title} Chain`
      ret.className = 'default_button'
      return ret
    }

    ret.el = `Deposit in ${SUPPORT_CHAINS[chainId]?.title} Chain`
    ret.className = 'notice_button'
    return ret

  }, [withdraw, curAllowance, deposite, chainId, approve, approveStatus, fromChain, toChain, amount])

  const readyButton = useMemo(() => {
    let content = ''
    if (!selectedToken) {
      content = 'Please select token for more options'
    } else if (!fromChain) {
      content = 'Please select a sender chain'
    } else if (!toChain) {
      content = 'Please select a destination chain'
    }else if(fromChain.chainId !== chainId){
      content = `Please switch to ${SUPPORT_CHAINS[fromChain.chainId].chainName}`
    }else if (!amount || !new BigNumber(amount).isGreaterThan(0)) {
      content = 'Please enter amount'
    }
    return {show: !approve && !deposite && !withdraw && (!selectedToken || !fromChain || !toChain || !amount || !new BigNumber(amount).isGreaterThan(0)), content}
  }, [selectedToken, fromChain, toChain, amount, approve, deposite, withdraw, chainId])

  const getSigns = async () => {
    if (!withdrawData) return
    return await new Promise((resolve, reject) => {
      let signList = []
      let count = 1
      for (let i = 0; i < SIGN_URL_LIST.length; i++) {
        try {
          fetch(
              `${SIGN_URL_LIST[i]}/getSignDataSyn?
              contractAddress=${withdrawData.toTokenAddress}
              &fromChainId=${withdrawData.fromChainId}
              &nonce=${withdrawData.nonce}
              &to=${withdrawData.toAddress}
              &toChainId=${withdrawData.toChainId}
              &fromContract=${withdrawData.fromTokenAddress}
              &toContract=${withdrawData.toTokenAddress}
              &mainContract=${withdrawData.mainAddress}`
          )
              .then((response) => {
                return response.json()
              })
              .then((data) => {
                count++
                if (data.data) {
                  signList.push({
                    signatory: data.data.signatory,
                    v: data.data.signV,
                    r: data.data.signR,
                    s: data.data.signS,
                    fromChainId: data.data.fromChainId,
                    to: data.data.to,
                    nonce: data.data.nonce,
                    volume: data.data.volume.toString(),
                  })
                }
                if (signList.length === SIGN_COUNT) {
                  resolve(signList)
                } else if (count === SIGN_URL_LIST.length && signList.length < SIGN_COUNT) {
                  reject('sign error')
                }
              })
        } catch (e) {
          throw new Error('Fetch sign error.')
        }
      }
    })
  }

  useEffect(() => {
    if (account) {
      setInputAccount(account)
    }
  }, [account])

  useEffect(() => {
    if (selectedToken) {
      const chains = selectedToken.chains
      const chainList = chains
          .filter((item) => {
            return item.chainId !== toChain?.chainId
          })
          .map((item, index) => {
            return {...SUPPORT_CHAINS[item.chainId], id: index}
          })
      setFromChainList(chainList)
    }
  }, [selectedToken, toChain])

  useEffect(() => {
    if (selectedToken) {
      const chains = selectedToken.chains
      const chainList = chains
          .filter((item) => {
            return item.chainId !== fromChain?.chainId
          })
          .map((item, index) => {
            return {...SUPPORT_CHAINS[item.chainId], id: index}
          })
      setToChainList(chainList)
    }
  }, [selectedToken, fromChain])

  useEffect(() => {
    if (selectedToken && deposite && deposite.stake.status === 1) {
      const _toChain = selectedToken.chains.find(({chainId}) => {
        return chainId.toString() === deposite.stake.toChainId.toString()
      })
      const _fromChain = selectedToken.chains.find(({chainId}) => {
        return chainId.toString() === deposite.stake.fromChainId.toString()
      })
      setFromChain(_fromChain)
      setToChain(_toChain)
    } else {
      setToChain(undefined)
      setFromChain(undefined)
    }
  }, [selectedToken, deposite])

  useEffect(() => {
    if (deposite && deposite.stake.status === 1) {
      const depositedToken = tokens.find(({symbol}) => {
        return symbol === deposite.stake.symbol
      })
      if (depositedToken) setSelectedToken(depositedToken)
    }
  }, [deposite, tokens])

  const onStake = async () => {
    setDepositData(undefined)
    setAttempting(true)

    try {
      const contract = getContract(
          library,
          MainMatter,
          fromTokenChain.address,
          account
      )

      if (approveStatus && new BigNumber(curAllowance).isEqualTo('0')) {
        setAuction('APPROVE')
        const approveContract = getContract(
            library,
            MainMatter,
            fromTokenChain.mainAddress,
            account
        )
        await approveContract
            .approve(
                fromTokenChain.address,
                numToWei(amount, fromTokenChain.decimals, fromTokenChain)
            )
            .then((response) => {
              setAttempting(false)
              setHash(getEtherscanLink(chainId, response.hash, 'transaction'))
              addTransaction(response, {
                approve: {
                  account,
                  address: fromTokenChain.mainAddress,
                  chainId,
                  status: 0,
                },
                summary: `Approve ${selectedToken.symbol} in ${SUPPORT_CHAINS[chainId].title}`,
                hashLink: getEtherscanLink(chainId, response.hash, 'transaction'),
              })
            })
        return
      }
      setAuction('DEPOSIT')

      await contract
          .send(
              toChain.chainId,
              inputAccount,
              numToWei(amount, fromTokenChain.decimals),
              {
                from: account,
                value: numToWei('0.005'),
              }
          )
          .then((response) => {
            setAttempting(false)
            setHash(getEtherscanLink(chainId, response.hash, 'transaction'))
            addTransaction(response, {
              stake: {
                fromChainId: chainId,
                toChainId: toChain.chainId,
                fromAddress: account,
                toAddress: inputAccount,
                fromTokenAddress: fromTokenChain.address,
                toTokenAddress: toTokenChain.address,
                fromMainAddress: fromTokenChain.mainAddress,
                toMainAddress: toTokenChain.mainAddress,
                status: 0,
                amount: numToWei(amount, fromTokenChain.decimals),
                symbol: selectedToken.symbol,
                mainAddress: selectedToken.address,
                decimals: fromTokenChain.decimals
              },
              summary: `Deposit ${amount} ${selectedToken.symbol} in ${SUPPORT_CHAINS[chainId].title}`,
              hashLink: getEtherscanLink(chainId, response.hash, 'transaction'),
            })
          })
    } catch (error) {
      setAttempting(false)
      if (error?.code === 4001) {
        setErrorMessage('Transaction rejected.')
      } else {
        setErrorMessage(error?.error?.message ?? 'Oops! Something went wrong')
      }
    }
  }

  const onClaim = async () => {
    setAuction('CONSENSUS')
    setAttempting(true)
    const contract = getContract(
        library,
        MainMatter,
        withdrawData?.toTokenAddress,
        account
    )
    try {
      const signs = await getSigns()
      setAuction('APPROVED')
      const defaultSign = signs[0]
      await contract
          .receive(
              defaultSign.fromChainId,
              defaultSign.to,
              defaultSign.nonce,
              defaultSign.volume,
              signs.map((item) => {
                return {
                  signatory: item.signatory,
                  v: item.v,
                  r: item.r,
                  s: item.s,
                }
              }),
              {from: account, value: numToWei('0.005')}
          )
          .then((response) => {
            setAttempting(false)
            setWithdrawData(undefined)
            setHash(getEtherscanLink(chainId, response.hash, 'transaction'))
            addTransaction(response, {
              claim: {
                nonce: withdrawData.nonce,
                fromChainId: withdrawData.fromChainId,
                toChainId: withdrawData.toChainId,
                fromMainAddress: withdrawData.fromMainAddress,
                toMainAddress: withdrawData.toMainAddress,
                toAddress: account,
                status: 0,
                amount: defaultSign.volume,
                isSwap: true
              },
              summary: `Withdraw ${formatAmount(defaultSign.volume, withdrawData.decimals)} ${
                  withdrawData.symbol
              } in ${SUPPORT_CHAINS[chainId].title}`,
              hashLink: getEtherscanLink(chainId, response.hash, 'transaction'),
            })

            const pastDispatch = deposite
            if (pastDispatch) {
              pastDispatch.stake.status = 2
              dispatch({
                type: ANTIMATTER_TRANSACTION_LIST,
                transaction: pastDispatch,
              })
            }
          })
    } catch (error) {
      setAttempting(false)
      if (error?.code === 4001) {
        setErrorMessage('Transaction rejected.')
      } else {
        setErrorMessage(error?.error?.message ?? 'Oops! Something went wrong')
      }
    }
  }

  const Stepper = useMemo(() => {
    const check = deposite && deposite.stake && deposite.stake.status === 1
    return function ({className}) {
      return (
          <div className={`step_frame${className ? ' ' + className : ''}`}>
            {check ? (
                <p className="step_circle">
                  <Check size={18}/>
                </p>
            ) : (
                <p>1</p>
            )}
            <div className={check ? 'check' : ''}/>
            <p className="un_active">2</p>
          </div>
      )
    }
  }, [deposite])

  return (
      <>
        <div className="page">

          {modalType === MODE_TYPE.WAITING && <WAITING/>}

          {modalType === MODE_TYPE.CONNECTING && <CONNECTING/>}

          {modalType === MODE_TYPE.WALLETS && (
              <Wallets
                  onClose={onClose}
                  onConnecting={() => {
                    setModalType(MODE_TYPE.CONNECTING)
                  }}
              />
          )}

          {modalType === MODE_TYPE.INIT && (
              <div className="bridge">
                <h5 className="bridge__title">Cross Chain Bridge</h5>
                {active && selectedToken && <Stepper className="mini"/>}
                <div className={`bridge__input_frame ${(deposite || withdraw) ? 'mask' : ''}`}>
                  {fromTokenChain ? (
                          <p>
                            Amount
                            {active && selectedToken && (
                                <span>{`Your balance : ${
                                    !loading && tokenBalance
                                        ? formatAmount(tokenBalance, selectedToken.decimals, 2)
                                        : '--'
                                } ${selectedToken ? selectedToken.symbol : ''}`}</span>
                            )}
                          </p>
                      ) :
                      <p>Asset</p>
                  }

                  <div
                      className={`bridge__input_frame__extra ${
                          inputError ? 'input_error' : ''
                      }`}
                  >
                    <div className="main_content" style={{width: fromTokenChain ? '62.5%' : 0}}>
                      <input
                          className="enter_amount_input"
                          disabled={!selectedToken || !tokenBalance}
                          pattern="^[0-9]*[.,]?[0-9]*$"
                          style={{paddingRight: 90}}
                          value={amount}
                          onChange={(e) => {
                            let value = e.target.value.replace(/,/g, '.')
                            if (value === '' || inputRegex.test(escapeRegExp(value))) {
                              setAmount(value)
                              setInputError(null)
                              if (
                                  !selectedToken ||
                                  new BigNumber(
                                      numToWei(value, selectedToken.decimals)
                                  ).isGreaterThan(tokenBalance)
                              ) {
                                setInputError(
                                    `You do not have enough ${selectedToken.symbol}`
                                )
                              }
                            }
                          }}
                          placeholder='Enter amount'
                      />

                      <button
                          style={{display: fromTokenChain ? 'block' : 'none'}}
                          className="max"
                          onClick={() => {
                            setAmount(
                                formatAmount(tokenBalance ?? 0, fromTokenChain?.decimals)
                            )
                          }}
                      >
                        MAX
                      </button>
                    </div>

                    <div
                        className={`asset ${!selectedToken ? 'asset_default' : 'asset_selected'} ${!active && 'asset_disabled'}`}
                        onClick={() => {
                          if (active) setSelectingToken(true)
                        }}
                        style={{width: fromTokenChain ? '37.5%' : '100%', transition: 'all .8s'}}
                    >
                      {selectedToken ? (
                          <div className="token-tit">
                            <img
                                className="icon"
                                src={`https://raw.githubusercontent.com/williamzng/chainswap-assets/main/blockchains/ethereum/${selectedToken.address.toLowerCase()}.png`}
                                alt=""
                            />
                            <p>{selectedToken.symbol}</p>
                          </div>
                      ) : (
                          <p className="mobile-ml10">Select a Token</p>
                      )}

                      <img src={Down} alt=""/>
                    </div>
                  </div>

                  <p className="error">{inputError}</p>
                </div>

                {selectedToken && (
                    <div className={`inputs ${(deposite || withdraw) ? 'mask' : ''}`}>
                      <div className="bridge__dropdown_frame">
                        <div className="dropdown">
                          <span>From</span>
                          <div/>
                          <ButtonSelect
                              disabled={!!deposite}
                              defaultContent='Select Chain'
                              options={selectFromChainOptions}
                              onSelection={(id) => {
                                setFromChain(SUPPORT_CHAINS[id])
                                switchChain(library, id)
                              }}
                              selectedId={fromChain?.chainId}
                          />
                        </div>
                        <div className="arrow_down-box" onClick={() => {
                          const _from = fromChain
                          const _to = toChain
                          setFromChain(_to)
                          setToChain(_from)
                        }}>
                          <img className="arrow_down" src={Exchange} alt=""/>
                        </div>
                        <div className="dropdown">
                          <span>To</span>
                          <div/>
                          <ButtonSelect
                              disabled={!!deposite}
                              defaultContent='Select Chain'
                              options={selectButtonChainOptions}
                              onSelection={(id) => {
                                setToChain(SUPPORT_CHAINS[id])
                              }}
                              selectedId={toChain?.chainId}
                          />
                        </div>
                      </div>

                      {active && (
                          <div className={`bridge__input_frame`}>
                            <p>Destination Address</p>
                            <div className="bridge__input_frame__extra">
                              <input
                                  value={inputAccount}
                                  onChange={(e) => {
                                    const value = e.target.value
                                    setInputAccount(value)
                                  }}
                                  placeholder="Enter address to swap"
                              />
                            </div>
                            <p className="error"
                               style={{height: 5}}
                            >
                              {!isAddress(inputAccount) ? 'Invalid address' : ''}
                            </p>
                            <p style={{marginTop: 12}}>This is the receiving address of the destination chain</p>
                          </div>
                      )}
                    </div>
                )}

                {false &&
                <div className="success-notice">
                  <span>Now you can swap Matter</span>
                  <div className="check-box"><Check/></div>
                </div>
                }

                {!active ? (
                    <button
                        className="giant primary_button"
                        style={{marginTop: 24}}
                        onClick={() => {
                          setModalType(MODE_TYPE.WALLETS)
                        }}
                    >
                      Connect Wallet
                    </button>
                ) : (
                    <div className="btn_group">
                      {readyButton.show ? (
                          <button disabled className="notice_button" style={{marginTop: selectedToken? 0: 30}}>{readyButton.content}</button>
                      ) : (
                          <>
                            {(approveStatus && !deposite && !withdraw && new BigNumber(curAllowance).isLessThan(numToWei(amount, fromTokenChain.decimals))) || approve ? (
                                <button
                                    disabled={approve}
                                    className={sendButton.className}
                                    onClick={onStake}
                                >
                                  {sendButton.el}
                                </button>
                            ) : (
                                <>
                                  <button
                                      className={sendButton.className}
                                      disabled={
                                        !toChain ||
                                        !isEnoughGas ||
                                        approve ||
                                        (!new BigNumber(curAllowance).isEqualTo('0') &&
                                            (!amount ||
                                                (account && !new BigNumber(amount).isGreaterThan(0)) ||
                                                inputError ||
                                                !inputAccount ||
                                                !isAddress(inputAccount) ||
                                                deposite ||
                                                (deposite && deposite.stake.status !== 2)))
                                      }
                                      onClick={() => {
                                        if (approveStatus && new BigNumber(curAllowance).isEqualTo('0')) {
                                          onStake()
                                        } else {
                                          setDepositData({
                                            fromChainId: chainId,
                                            toChainId: toTokenChain.chainId,
                                            fromAddress: account,
                                            toAddress: inputAccount,
                                            amount: numToWei(amount, fromTokenChain.decimals),
                                            symbol: selectedToken.symbol,
                                            mainAddress: fromTokenChain.address,
                                            decimals: fromTokenChain.decimals
                                          })
                                        }
                                      }}
                                  >
                                    {sendButton.el}
                                  </button>

                                  <button
                                      className={withdraw ? "notice_button" : "default_button"}
                                      disabled={
                                        !deposite ||
                                        (deposite.stake && deposite.stake.status !== 1) ||
                                        withdraw
                                      }
                                      onClick={() => {
                                        setWithdrawData({
                                          fromChainId: deposite.stake.fromChainId,
                                          toChainId: deposite.stake.toChainId,
                                          toAddress: deposite.stake.toAddress,
                                          volume: deposite.stake.amount,
                                          nonce: deposite.nonce,
                                          toTokenAddress: deposite.stake.toTokenAddress,
                                          fromTokenAddress: deposite.stake.fromTokenAddress,
                                          symbol: deposite.stake.symbol,
                                          mainAddress: deposite.stake.mainAddress,
                                          decimals: deposite.stake.decimals
                                        })
                                      }}
                                  >
                                    {withdraw ? (
                                        <>
                                          <img
                                              src={Circle}
                                              className="confirm_modal__loading"
                                              alt=""
                                          />{' '}
                                          <p>Withdrawing</p>
                                        </>
                                    ) : (
                                        `Withdraw ${deposite ? 'on ' + SUPPORT_CHAINS[deposite.stake.toChainId]?.title + ' Chain' : ' '}`
                                    )}
                                  </button>
                                </>
                            )}

                          </>
                      )}
                    </div>
                )}

                {active && selectedToken && <Stepper className="giant"/>}
                {active && selectedToken && selectedToken.isMainstream && (
                    <div className="quota_frame">
                      <div className="row_between">
                        <span className="text_small_gray">Your Quota:</span>
                        <div>
                          <span
                              style={{marginRight: '12px'}}>{`${amount && fromTokenChain ? numToWei(amount, fromTokenChain.decimals) : 0} ${selectedToken.symbol}`}</span>
                          <span className="text_small_gray">80% / 100%</span>
                        </div>
                      </div>
                      <div className="progress_frame">
                        <div className="progress" style={{width: '80%'}}/>
                      </div>
                    </div>
                )}
              </div>
          )}

          {depositData && (
              <div className="modal-show">
                <div className="wrapper">
                  <Confirm
                      onClose={() => {
                        setDepositData(undefined)
                      }}
                      depositData={depositData}
                      onStake={onStake}
                  />
                </div>
              </div>
          )}

          {withdrawData && (
              <div className="modal-show">
                <div className="wrapper">
                  <ClaimModal
                      handleClose={() => {
                        setWithdrawData(undefined)
                      }}
                      withdrawData={withdrawData}
                      onClaim={onClaim}
                  />
                </div>
              </div>
          )}

          {hash && (
              <div className="modal-show">
                <div className="wrapper">
                  <Submitted onClose={() => {
                    setHash(undefined)
                  }} hash={hash}/>
                </div>
              </div>
          )}


          {attempting && (
              <div className="modal-show">
                <div className="wrapper">
                  <Confirming onClose={() => {
                    setAttempting(false)
                  }} chainId={chainId} auction={auction}/>
                </div>
              </div>
          )}

          <div className="mobile_filler mini"/>
        </div>

        {errorMessage && (
            <div className="modal-show">
              <div className="wrapper">
                <ERROR message={errorMessage} onCancel={() => {
                  setErrorMessage(null)
                }}/>
              </div>
            </div>
        )}

        {selectingToken && (
            <div className="modal-show">
              <div className="wrapper">
                <AssetModal
                    tokenList={tokens}
                    selectedToken={selectedToken}
                    onSelect={(token) => {
                      setAmount('')
                      if (token !== selectedToken) {
                        setFromChain(undefined)
                        setSelectedToken(token)
                      }
                    }}
                    onClose={() => {
                      setSelectingToken(false)
                    }}
                />
              </div>
            </div>
        )}
        <Footer active={active}/>
      </>
  )
}

