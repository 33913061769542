import React from 'react'
import Dots from '../../components/Dots'

export default function ComingSoon() {
  return (
    <div className="app_body">
      <p
        style={{
          fontWeight: 500,
          fontSize: '24px',
          lineHeight: '88.69%',
          color: '#ffffff',
        }}
      >
        Coming Soon <Dots />
      </p>
      <div style={{ marginTop: '40px' }}>
        This section is still implemeting.
      </div>
      <div style={{ marginTop: '20px' }}>Please come back later</div>
    </div>
  )
}
