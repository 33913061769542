import React, { CSSProperties, ComponentType, FC, ReactNode } from "react";
import classNames from "classnames";
import {NavLink as RouterLink} from "react-router-dom";

import styles from "./Action.module.scss";
import {MaybeWithClassName, WithChildren, EmptyObject} from "../../helpers/types";

type ButtonType = "button" | "submit" | "reset";
type HTMLLinkType = "a";

export type ActionCommonType = {
	disabled?: boolean;
	style?: CSSProperties;
	activeClassName?: string;

	onClick?(): void;
} & {
	iconAfter?: ReactNode;
	iconBefore?: ReactNode;
	icon?: ReactNode;
	variant?: "contained" | "text" | "outlined";
	color?: "primary" | "secondary";
	size?: "medium";
};

type ActionType<T extends EmptyObject> = ActionCommonType &
	(
		| {
				Component: "button" | undefined;
				type: ButtonType;
		  }
		| {
				Component: HTMLLinkType;
				activeClassName?: string;
				href?: string;
				role?: "link";
				target?: "_blank";
				rel?: "noopener noreferrer";
		  }
		| ({
				Component: ComponentType<T>;
				to: string;
		  } & T)
	);

export type CommonType = ActionCommonType & MaybeWithClassName & WithChildren;

export const Action: FC<ActionType<any> & MaybeWithClassName & WithChildren> = ({
	Component = "button",
	className,
	children,
	iconAfter,
	iconBefore,
	icon,
	variant,
	color,
	size = "medium",
	disabled,
	onClick,
	...props
}) => (
	<Component
		className={classNames(
			className,
			styles.button,
			icon && styles.icon,
			variant && styles[variant],
			color && variant && styles[`${variant}-${color}`],
			size && styles[size],
			size && variant && styles[`${variant}-${size}`],
			size && icon && styles[`icon-${size}`],
			size && iconBefore && styles[`iconBefore-${size}`],
			size && iconAfter && styles[`iconAfter-${size}`],
			disabled && styles.disabled
		)}
		onClick={onClick}
		disabled={disabled}
		{...props}
	>
		{iconBefore}
		{icon ? (
			<>
				{icon}
				<span>{children}</span>
			</>
		) : (
			children
		)}
		{iconAfter}
	</Component>
);

export const Button: FC<CommonType & { submit?: boolean }> = ({ submit, ...rest }) => (
	<Action Component="button" type={submit ? "submit" : "button"} {...rest} />
);

export const NavLink: FC<CommonType & { href: string; }> = ({ href, ...rest }) => (
	<>
		{href.startsWith("http") || href.startsWith("mailto") || href.startsWith("tel") ? (
			<Action
				Component="a"
				href={href}
				target="_blank"
				rel="noopener noreferrer"
				{...rest}
			/>
		) : (
			<Action
				Component={RouterLink}
				to={href}
				{...rest}
				// @ts-ignore
				exact
			/>
		)}
	</>
);
