import React, { useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SUPPORT_CHAINS, FACTORY_CHAIN_ID } from '../../../const'
import { Check } from 'react-feather'
import { getContract, useActiveWeb3React } from "../../../web3";
import TokenFactory from "../../../web3/abi/TokenFactory.json";
import { TOKEN_FACTORY } from "../../../web3/address";
import { numToWei } from "../../../utils/format";
import { getEtherscanLink } from "../../../utils";
import { TXConfirm } from "../../../components/modal/TXConfirm";
import { useTransactionAdder } from "../../Hooks";
import { ModalOverlay } from "../../../components/header/modal";
import { Submitted } from "../../bridge/Submitted";
import { mainContext } from "../../../reducer";
import { Button } from "../../../components/button/Button";
import { Deployed } from "./Deployed";
import { useIsEnoughGas } from "../../../hooks/balance";
import { GASERROR } from "../../bridge/modals";
import Copy from '../../../components/Copy'

export const RegisterModal = ({ existingToken, mappingTokens, mappedRegistered }) => {

  const isEnoughGas = useIsEnoughGas('0.2')

  const { transactions } = useContext(mainContext).state
  const history = useHistory()
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [showGasError, setShowGasError] = useState(false)
  const [hash, setHash] = useState()

  const registeringTX = transactions.find((item) => {
    return item.registerMappingToken &&
      item.registerMappingToken.status === 0 &&
      item.registerMappingToken.register.existingAddress === existingToken.address
  })

  const registeredTX = transactions.find((item) => {
    return item.registerMappingToken &&
      item.registerMappingToken.status === 1 &&
      item.registerMappingToken.register.existingAddress === existingToken.address
  })

  const Stepper = useMemo(() => {
    return function ({ className }) {
      return (
        <div className={`step_frame${className ? ' ' + className : ''}`}>
          <div className="step_circle">
            <Check size={18} />
          </div>
          <div className="divider" />
          <div className="step_circle">
            <Check size={18} />
          </div>
          <div className="divider" />
          <p>3</p>
        </div>
      )
    }
  }, [])


  const getSigns = async () => {
    return await new Promise((resolve, reject) => {
      let signList = []
      let count = []
      for (let i = 1; i < 6; i++) {
        try {
          count.push('')
          fetch(
            `https://node${i}.chainswap.exchange/web/getRegisterMappingTokenSignDataSyn`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              chainIds: mappingTokens.map(({ chainId }) => chainId).concat(mappedRegistered ? [] : existingToken.chainId),
              currentChainId: chainId,
              mainChainId: existingToken.chainId,
              mappingTokenMappeds: mappingTokens.map(({ address }) => address).concat(mappedRegistered ? [] : existingToken.tokenMapped),
              token: existingToken.address,
            })
          }
          )
            .then((response) => {
              return response.json()
            })
            .then((data) => {
              if (data.data) {
                signList.push({
                  signatory: data.data.signatory,
                  v: data.data.signV,
                  r: data.data.signR,
                  s: data.data.signS,
                  cap: data.data.cap,
                  decimals: data.data.decimals,
                  name: data.data.name,
                  symbol: data.data.symbol,
                  token: data.data.token,
                  mainChainId: data.data.mainChainId
                })
              }
              if (signList.length === 3) {
                resolve(signList)
              } else if (count.length === 5 && signList.length < 3) {
                //reject('query error')
              }
            })
        } catch (e) {
          console.log('Fetch sign error', e)
          throw new Error('Fetch sign error.', e)
        }
      }
    })
  }

  const onRegister = async (chainId) => {
    if (!isEnoughGas) {
      setShowGasError(true)
      return
    }
    setAttemptingTxn(true)

    try {
      const signs = await getSigns(chainId)
      const contract = getContract(library, TokenFactory, TOKEN_FACTORY, account)
      console.log('start CreateMapping', signs.map((item) => {
        return {
          signatory: item.signatory,
          v: item.v,
          r: item.r,
          s: item.s,
        }
      }))
      await contract.registerMapping(
        existingToken.chainId,
        existingToken.address,
        existingToken.nonce,
        mappingTokens.map(({ chainId }) => chainId).concat(mappedRegistered ? [] : existingToken.chainId),
        mappingTokens.map(({ address }) => address).concat(mappedRegistered ? [] : existingToken.tokenMapped),
        signs.map((item) => {
          return {
            signatory: item.signatory,
            v: item.v,
            r: item.r,
            s: item.s,
          }
        }),
        {
          from: account,
          value: numToWei('0.2'),
        }
      )
        .then((response) => {
          setAttemptingTxn(false)
          setHash(getEtherscanLink(chainId, response.hash, 'transaction'))
          addTransaction(response, {
            registerMappingToken: {
              register: {
                existingAddress: existingToken.address,
                existingChainId: existingToken.chainId,
                mappingChainId: chainId
              },
              status: 0
            },
            summary: `Register ${existingToken.symbol} mapping token`,
            hashLink: getEtherscanLink(chainId, response.hash, 'transaction'),
          })
        })
    } catch (e) {
      console.log('createTokenMapped', e)
      setAttemptingTxn(false)
    }
  }


  return (
    <div className="default_modal modal_deployed">
      <div className="row_between">
        <p className="default_modal__title">
          Bridge Factory Contract
        </p>
        <Stepper />
      </div>

      <div className="modal_deployed__extras">
        <div className="extra gray_frame">
          <div className="extra__header">
            <p className="text_bold text_14">  Mainchain Info</p>
          </div>
          <ul>
            <li>Token contract address:</li>
            <li style={{ color: '#ffffff' }}> {existingToken?.address}</li>
          </ul>
          <ul>
            <li>Mappable contract address:</li>
            <li style={{ color: '#ffffff' }}>{existingToken?.tokenMapped}</li>
          </ul>
          <ul>
            <li>Mainchain ID:</li>
            <li style={{ color: '#ffffff' }}>
              {existingToken?.chainId.toString()}
            </li>
          </ul>
        </div>

        {mappingTokens?.map((item) => {
          return (
            <div key={item.address} className="extra gray_frame">
              <div
                className="extra__header"
                style={{ justifyContent: 'flex-start', alignItems: 'center' }}
              >
                {SUPPORT_CHAINS[parseInt(item.chainId)].logo}
                <p style={{ marginLeft: 8 }} className="text_bold text_14">
                  {SUPPORT_CHAINS[parseInt(item.chainId)].title}
                </p>
              </div>
              <ul>
                <li>Mapping contract address:</li>

                <li style={{ color: '#ffffff' }}>
                  <span style={{
                    maxWidth: 'calc(100% - 24px)', overflow: 'hidden', textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}>{item?.address}</span>
                  <Copy text={item?.address ?? ''} />
                </li>
              </ul>
              <ul>
                <li>Chain ID:</li>
                <li style={{ color: '#ffffff' }}>
                  {item?.chainId.toString()}
                  <Copy text={item?.chainId.toString()} />
                </li>


              </ul>

            </div>
          )
        })}
      </div>
      <Button
        disabled={chainId !== FACTORY_CHAIN_ID}
        background={''}
        content={registeringTX ? 'Deploying' : registeredTX ? 'Deployed' : chainId !== FACTORY_CHAIN_ID ? `Please switch ${SUPPORT_CHAINS[FACTORY_CHAIN_ID].title} Chain` : 'Deploy'}
        loading={registeringTX}
        className="default_button"
        onClick={onRegister}
        extra={!registeredTX && !registeringTX && chainId === FACTORY_CHAIN_ID ? 'Deployment fee: 0.2 ETH' : null}
      />

      {hash && (
        <ModalOverlay>
          <Submitted
            hash={hash}
            onClose={() => {
              setHash(null)
            }}
          />
        </ModalOverlay>
      )}

      {registeredTX && (
        <ModalOverlay>
          <Deployed
            hash={getEtherscanLink(FACTORY_CHAIN_ID, registeredTX.hash, 'transaction')}
            onClose={() => {
              history.push('/swap')
            }}
          />
        </ModalOverlay>
      )}

      {attemptingTxn && (
        <TXConfirm onClose={() => {
          setAttemptingTxn(false)
        }} />
      )}

      {showGasError && (
        <ModalOverlay>
          <GASERROR onOk={() => {
            setShowGasError(false)
          }} onCancel={() => {
            setShowGasError(false)
          }} />
        </ModalOverlay>
      )}
    </div>
  )
}
