import React, {useEffect, useState} from 'react'
import Coin from '../assets/image/question-mark.svg'
export const Img = ({src, style, className}) =>{
    const [source, setSource] = useState(src)

  useEffect(()=>{
    setSource(src)
  },[src])
    return (
        <img className={className} style={style} src={source} onError={()=>{
            setSource(Coin)
        }} alt=""/>
    )
}
