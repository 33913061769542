import React, { useContext, useState } from 'react'
import BigNumber from 'bignumber.js'
import { ArrowLeft } from 'react-feather'
import classNames from 'classnames'
import styles from './FarmModal.module.scss'
import { escapeRegExp } from 'utils'
import { formatAmount, numToWei } from 'utils/format'
import { ModalContext } from 'App'
import { ModalOverlay } from 'components/header/modal'

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`)

export default function StakeModal({
  tokenBalance,
  onStake,
  active,
  onClose,
}: {
  tokenBalance: number
  onStake: () => void
  onClose: () => void
  active: boolean
}) {
  const [selectedToken, setSelectedToken] = useState<any>()
  const [amount, setAmount] = useState<any>()
  const [inputError, setInputError] = useState<any>()

  const { handleWallet } = useContext(ModalContext)

  return (
    <ModalOverlay>
      <div
        className={classNames('app_body', styles.body)}
        style={{ width: 512 }}
      >
        <div className="row_between">
          <ArrowLeft className="btn_close" onClick={onClose} />
          <p className="text_title text_22">Stake Tokens</p> <div />
        </div>
        <div className="bridge__input_frame">
          <p>
            Amount
            {active && selectedToken && (
              <span>{`Your balance : ${tokenBalance ? tokenBalance : '--'} ${
                selectedToken ? selectedToken.symbol : ''
              }`}</span>
            )}
          </p>

          <div
            className={`bridge__input_frame__extra ${
              inputError ? 'input_error' : ''
            }`}
          >
            <input
              className="enter_amount_input"
              disabled={!selectedToken || !tokenBalance}
              pattern="^[0-9]*[.,]?[0-9]*$"
              value={amount}
              onChange={(e) => {
                let value = e.target.value.replace(/,/g, '.')
                if (value === '' || inputRegex.test(escapeRegExp(value))) {
                  setAmount(value)
                  setInputError(null)
                  if (
                    !selectedToken ||
                    new BigNumber(
                      numToWei(value, selectedToken.decimals)
                    ).isGreaterThan(tokenBalance)
                  ) {
                    setInputError(
                      `You do not have enough ${selectedToken.symbol}`
                    )
                  }
                }
              }}
              placeholder={'Enter amount'}
            />

            {tokenBalance && (
              <button
                className="max"
                onClick={() => {
                  setAmount(formatAmount(tokenBalance, selectedToken.decimals))
                }}
                style={{ marginLeft: 180 }}
              >
                MAX
              </button>
            )}
          </div>

          <p className="error">{inputError}</p>
        </div>
        <div className="row_between" style={{ marginTop: 36 }}>
          <button
            className={classNames('default_button_outlined', styles.button)}
            onClick={onClose}
          >
            Cancel
          </button>
          <div style={{ width: 24, flexShrink: 0 }} />
          <button
            onClick={onStake}
            className={classNames('default_button_outlined', styles.button)}
          >
            Confirm
          </button>
        </div>
        {/* <Form
        validate={validation}
        onSubmit={onStake}
        render={({ handleSubmit }) => (
          <form className={styles.form} onSubmit={handleSubmit} noValidate>
            <div>
              <Input
                className={styles.input}
                name="amount"
                label={
                  <Caption
                    className={classNames(styles.label, 'row_between')}
                    Component="p"
                    lighten={60}
                  >
                    Input
                    <Caption Component="span" lighten={60}>
                      Your balance: {balance} ETH
                    </Caption>
                  </Caption>
                }
                type="number"
                required
                placeholder="Enter the amount "
                max={balance}
              />
            </div>
            <div className="row_between" style={{ marginTop: 36 }}>
              <button
                className={classNames('default_button_outlined', styles.button)}
              >
                Cancel
              </button>
              <div style={{ width: 24, flexShrink: 0 }} />
              <button
                type="submit"
                className={classNames('default_button_outlined', styles.button)}
              >
                Confirm
              </button>
            </div>
          </form>
        )}
      /> */}
      </div>
    </ModalOverlay>
  )
}
