import React, { useCallback, useContext, useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { NavLink, useLocation } from 'react-router-dom'
import { Check } from 'react-feather'
import styles from './Tokens.module.scss'
import { LBP } from './modules/lbp'
import { Info } from './modules/info'
import { Airdrop } from './modules/airdrop'
import { ObjectKeys } from '../../helpers/objects'
import { GutterBox } from '../../ui/gutter-box'
import { Body2, Heading1 } from '../../ui/typography'
import { ReactComponent as Copy } from './assets/copy.svg'
// import { START_PARTICIPATION, END_PARTICIPATION } from './const'
import { useLBPInfo, useAirdropInfo } from 'hooks/useLBPInfo'
import { getContract, getEtherscanLink, useActiveWeb3React } from '../../web3'
import { Purchase } from 'pages/tokens/modules/purchase'
import { fromWei, numToWei } from 'utils/format'
import { useTransactionAdder } from 'pages/Hooks'
// import {Timer} from "./modules/timer";
import ERC20Abi from '../../web3/abi/ERC20.json'
import { mainContext } from 'reducer'
import { useETHPrice } from '../../hooks/balance'
import {
  LBP_START_PARTICIPATION,
  LBP_END_PARTICIPATION,
  DAY_IN_MILISECONDS,
} from './const'
import { Button } from 'ui/action'
import { Timer } from './modules/timer'
import {AIRDROP_ADDRESS} from "../../web3/address";
import {CHAINS, CHAINS_TEST, SUPPORT_CHAINS} from "../../const";

const TABS = {
  LBP: '/tokens',
  Airdrop: '/tokens/airdrop',
  'Token Information': '/tokens/info',
}

export enum STATUS {
  PRE_START = 'prestart',
  STARTED = 'started',
  ENDED = 'ended',
}

export const Tokens = () => {
  const price = useETHPrice()
  const { chainId, account, library } = useActiveWeb3React()
  const [ethVal, setEthVal] = useState<number | undefined>()
  const [copied, setCopied] = useState(false)
  const [LbpStartTime, setLbpStartTime] = useState<number>(
    new Date(LBP_START_PARTICIPATION).getTime()
  )
  const [LbpfinishTime, setLbpFinishTime] = useState<number>(
    new Date(LBP_END_PARTICIPATION).getTime()
  )
  const [LbpStatus, setLbpStatus] = useState<STATUS>(STATUS.PRE_START)

  const addTransaction = useTransactionAdder()
  const { pathname } = useLocation()
  const { transactions } = useContext(mainContext).state
  const [minStrap, setMinStrap] = useState('')
  const info = useLBPInfo(ethVal)

  const { slippage } = useContext(mainContext).state

  useEffect(() => {
    if (info && !info.error) {
      const start = info.begin
        ? new Date(parseInt(info.begin._hex, 16) * 1000).getTime()
        : LbpStartTime
      const end = start + DAY_IN_MILISECONDS
      start && setLbpStartTime(start)
      start && setLbpFinishTime(end)
    }
  }, [LbpStartTime, LbpfinishTime, info])

  useEffect(() => {
    if (Date.now() >= LbpStartTime) {
      setLbpStatus(STATUS.PRE_START)
    }
    if (Date.now() > LbpfinishTime) {
      setLbpStatus(STATUS.ENDED)
    }
  }, [LbpStartTime, LbpfinishTime])

  useEffect(() => {
    info &&
      info.strapOut &&
      setMinStrap(
        fromWei(info.strapOut.toString())
          .multipliedBy(1 - slippage / 10000)
          .toFixed(6)
      )
  }, [info, slippage])

  const airdropInfo = useAirdropInfo()

  const onStrap = useCallback(async () => {
    if (info && info.strap) {
      let r: any
      try {
        r = await info.strap(numToWei(minStrap), {
          from: account,
          value: numToWei(ethVal?.toString()),
        })
        addTransaction(r, {
          strap: {
            ...r,
          },
          summary: `Purchased ${minStrap} Tokens`,
          hashLink: getEtherscanLink(chainId, r.hash, 'transaction'),
        })
        return {
          status: 'success',
          hashLink: getEtherscanLink(chainId, r.hash, 'transaction'),
        }
      } catch (e) {
        return { status: 'failed' }
      }
    }
    return undefined
  }, [account, addTransaction, chainId, ethVal, info, minStrap])

  const onApprove = useCallback(async () => {
    if (!airdropInfo || !airdropInfo.exercise || !airdropInfo.currency)
      return undefined
    let a
    try {
      const approveContract = await getContract(
        library,
        ERC20Abi,
        airdropInfo.currency,
        account
      )
      a = await approveContract.approve(
        AIRDROP_ADDRESS,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
        { gasLimit: 350000 }
      )
      addTransaction(a, {
        approve: {
          account,
          address: airdropInfo.currency,
          chainId,
          status: 0,
        },
        summary: `Approve USDT in ${
            CHAINS[(chainId ?? 1) as keyof typeof CHAINS ].title
        }`,
        hashLink: getEtherscanLink(chainId, a.hash, 'transaction'),
      })
      return {
        status: 'success',
        hashLink: getEtherscanLink(chainId, a.hash, 'transaction'),
      }
    } catch (e) {
      return {
        status: 'failed',
        hashLink: '',
      }
    }
  }, [account, addTransaction, airdropInfo, chainId, library])

  const onRedeem = useCallback(async () => {
    if (!airdropInfo || !airdropInfo.exercise || !airdropInfo.currency)
      return undefined
    let r

    try {
      r = await airdropInfo.exercise({ gasLimit: 350000 })
      const quota = airdropInfo?.quota && fromWei(airdropInfo.quota.toString())

      const price = airdropInfo?.price && fromWei(airdropInfo.price.toString())

      addTransaction(r, {
        exercise: {
          ...r,
        },
        summary: `Redeem  ${
          quota && price ? quota.multipliedBy(price).toFixed(6) : 0
        } DAI to ${quota ? quota.toFixed(6) : 0} tokens`,
        hashLink: getEtherscanLink(chainId, r.hash, 'transaction'),
      })
      return {
        status: 'success',
        hashLink: getEtherscanLink(chainId, r.hash, 'transaction'),
      }
    } catch (e) {
      return { status: 'failed', data: {} }
    }
  }, [addTransaction, airdropInfo, chainId])

  return (
    <>
      {pathname === '/tokens/purchase' ? (
        <Purchase
          ethVal={ethVal}
          setEthVal={setEthVal}
          tokenAmount={minStrap}
          onStrap={onStrap}
        />
      ) : (
        <section className={styles.component}>
          <GutterBox className={styles.wrapper}>
            <div className={styles.content}>
              <div className={styles.header}>
                <Heading1 className={styles.title} Component="h2">
                  Chainswap Token
                </Heading1>
                <ul className={styles.list}>
                  {ObjectKeys(TABS).map((key) => (
                    <li key={key}>
                      <NavLink
                        className={styles.link}
                        activeClassName={styles.active}
                        to={TABS[key]}
                        exact={true}
                      >
                        {key}
                      </NavLink>
                    </li>
                  ))}
                </ul>
                <Body2 Component="p" className={styles.wallet} lighten={80}>
                  <CopyToClipboard
                    text={'0x3B73c1B2ea59835cbfcADade5462b6aB630D9890'}
                    onCopy={() => {
                      setCopied(true)
                      setTimeout(() => {
                        setCopied(false)
                      }, 1000)
                    }}
                  >
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      0x3B73c1B2ea59835cbfcADade5462b6aB630D9890
                      <span style={{ width: '5px' }}></span>
                      {copied ? (
                        <Check size={14} />
                      ) : (
                        <Copy style={{ width: 14, height: 14 }} />
                      )}
                    </span>
                  </CopyToClipboard>
                  {LbpStatus === STATUS.STARTED && pathname === '/tokens' && (
                    <Button
                      disabled={true}
                      className={styles.timer_button}
                      variant="contained"
                    >
                      <Timer
                        timer={LbpfinishTime / 1000}
                        onZero={() => setLbpStatus(STATUS.ENDED)}
                      />
                    </Button>
                  )}
                </Body2>
                {/*{openParticipation && (*/}
                {/*  <Body2 className={styles.timer} Component="span" color="purple">*/}
                {/*    <Timer timer={convertedCloseTime / 1000} onZero={() => null}/>*/}
                {/*  </Body2>*/}
                {/*)}*/}
              </div>
              <div className={styles.main}>
                {pathname === '/tokens/airdrop' && (
                  <Airdrop
                    info={airdropInfo}
                    onRedeem={onRedeem}
                    onApprove={onApprove}
                    transactions={transactions}
                  />
                )}

                {pathname === '/tokens' && (
                  <LBP
                    startTime={LbpStartTime}
                    status={LbpStatus}
                    setStatus={setLbpStatus}
                    price={price ?? ''}
                    info={info}
                    chainId={chainId}
                  />
                )}

                {pathname === '/tokens/info' && <Info />}
                {/*<Switch>*/}
                {/*  <Route*/}
                {/*    exact*/}
                {/*    path="/tokens/airdrop"*/}
                {/*    component={() => (*/}
                {/*      <Airdrop*/}
                {/*        info={airdropInfo}*/}
                {/*        onRedeem={onRedeem}*/}
                {/*        onApprove={onApprove}*/}
                {/*        slippage={slippage}*/}
                {/*        transactions={transactions}*/}
                {/*      />*/}
                {/*    )}*/}
                {/*  />*/}
                {/*  <Route exact path="/tokens/info" component={Info} />*/}
                {/*  <Route*/}
                {/*    exact*/}
                {/*    path="/tokens"*/}
                {/*    component={() => <LBP  price={price ?? ''} info={info} chainId={chainId} />}*/}
                {/*  />*/}
                {/*</Switch>*/}
              </div>
            </div>
          </GutterBox>
        </section>
      )}
    </>
  )
}
