import React from "react";
import Circle from "../../assets/icon/circle.svg";
import Success from "../../assets/icon/success_green.svg";


export const Button = ({loading = false, success, content, disabled, onClick, background, extra, outline}) => {
  return (
      <button disabled={disabled || loading} className="default_button" onClick={onClick}
      style={{ background: success ? background ? background : '#000000' : '',border: `1px solid ${outline?'rgba(255, 255, 255, 0.4)':'transparent'}`}}>
          <div className="btn_content">
          <p style={{color:outline?'#ffffff':'inherit', fontSize:16}}>{content}</p>
          {extra && <p className="btn_extra">{extra}</p>
          }
        </div>
        {loading && <img src={Circle} className="loading-mid" style={{marginRight: 12}} alt=""/>}
        {success && <img src={Success} style={{marginRight: 12, width: 20, height: 20}} alt=""/>}

      </button>
  )
}
