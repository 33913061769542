import React from 'react'
import { ReactComponent as Close } from '../../assets/icon/close.svg'
import { GALLERY_SELECT_WEB3_CONTEXT } from '../../const'
import metamask from '../../assets/icon/metamask.png'
import walletConnect from '../../assets/icon/walletConnect.png'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { useWeb3React } from '@web3-react/core'

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 66 ,128, 137],
})

const POLLING_INTERVAL = 12000
const RPC_URLS = {
  1: 'https://mainnet.infura.io/v3/3f6f55ba1ee540328662f8496ddbc228',
  4: 'https://rinkeby.infura.io/v3/8f6d3d5d7a1442a38d9e8050c31c1884',
}

export const Wallets = ({ onClose, onConnecting }) => {
  const { activate } = useWeb3React()

  return (
    <div style={{ paddingBottom: 40 }} className="default_modal modal-wallets">
      <Close className="close-btn" onClick={onClose} />
      <p className="default_modal__title">Connect to a wallet</p>
      <button
        className="default_button_outlined white"
        onClick={() => {
          onConnecting()
          activate(injected)
            .then(() => {
              window &&
                window.localStorage.setItem(
                  GALLERY_SELECT_WEB3_CONTEXT,
                  'MetaMask'
                )
              onClose()
            })
            .catch(() => {})
        }}
      >
        <img src={metamask} alt="" />
        MetaMask
      </button>
      <button
        className="default_button_outlined white"
        style={{ marginTop: 16 }}
        onClick={() => {
          onConnecting()
          const walletChange = new WalletConnectConnector({
            rpc: { 1: RPC_URLS[1] },
            bridge: 'https://bridge.walletconnect.org',
            qrcode: true,
            pollingInterval: POLLING_INTERVAL,
          })
          activate(walletChange)
            .then(() => {
              window &&
                window.localStorage.setItem(
                  GALLERY_SELECT_WEB3_CONTEXT,
                  'WalletConnect'
                )
              onClose()
            })
            .catch(() => {})
        }}
      >
        <img src={walletConnect} alt="" />
        WalletConnect
      </button>
    </div>
  )
}
