import React from 'react'
import FarmModal from 'components/modal/FarmModal'
import { ReactComponent as BSCIcon } from '../../assets/icon/binance.svg'
import styles from './Farm.module.scss'
import { useWeb3React } from '@web3-react/core'

export default function Farm() {
  const { active } = useWeb3React()
  return (
    <div className={styles.farm}>
      <FarmModal
        active={active}
        data={{
          network: (
            <>
              <BSCIcon />
              BSC
            </>
          ),
          totalEarned: '0.0000',
          statistics: {
            apy: '100%',
            yourStake: 'xxxxxx',
            pooledTotal: 'xxxxxx',
          },
        }}
      />
    </div>
  )
}
