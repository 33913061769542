import React, {useContext, useEffect, useMemo, useState} from 'react'
import {Check} from 'react-feather'
import {isAddress} from '../../../utils/address'
import ButtonSelect from '../../../components/button/ButtonSelect'
import {useMappingTokens, useToken} from '../../../hooks/deoloy'
import {formatAmount, numToWei} from '../../../utils/format'
import Circle from '../../../assets/icon/circle.svg'
import {SUPPORT_CHAINS, CHAIN_LIST, ZERO_ADDRESS} from '../../../const'
import {getContract, useActiveWeb3React} from '../../../web3'
import {getEtherscanLink} from '../../../utils'
import {TOKEN_FACTORY} from '../../../web3/address'
import TokenFactory from '../../../web3/abi/TokenFactory.json'
import {useTransactionAdder} from '../../Hooks'
import {Submitted} from '../../bridge/Submitted'
import {TXConfirm} from '../../../components/modal/TXConfirm'
import {mainContext} from '../../../reducer'
import {ModalOverlay} from '../../../components/header/modal'
import {Button} from "../../../components/button/Button";
import {useIsEnoughGas} from "../../../hooks/balance";
import {GASERROR} from "../../bridge/modals";

const TOKEN_STATUS = {
  INIT: 'INIT',
  MAPPED: 'MAPPED',
  MAPPING: 'MAPPING',
}

export const AddTokenModal = ({onSuccess, toMapping}) => {
  const {transactions} = useContext(mainContext).state

  const isEnoughGas = useIsEnoughGas('0.1')

  const {account, library, chainId} = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const [showGasError, setShowGasError] = useState(false)
  const [tokenStatus, setTokenStatus] = useState(TOKEN_STATUS.INIT)
  const [existingToken, setExistingToken] = useState()
  const [nonce, setNonce] = useState()
  const [existingChain, setExistingChain] = useState()
  const [confirmed, setConfirmed] = useState(false)
  const [supportChains, setSupportChains] = useState(CHAIN_LIST)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [hash, setHash] = useState()
  const createTokenMappedTX = transactions.find((item) => {
    return item.createTokenMapped && item.createTokenMapped.status === 0
  })

  const createdTokenMappedTX = transactions.find((item) => {
    return item.createTokenMapped && item.createTokenMapped.status === 1
  })

  useEffect(() => {
    if (createdTokenMappedTX) {
      onSuccess(createdTokenMappedTX.createTokenMapped.existingToken, hash)
    }
  }, [createTokenMappedTX, createdTokenMappedTX, onSuccess, hash])

  const {token, certifyToken, loading, error} = useToken(existingChain, existingToken, nonce)
  const mappingTokensResult = useMappingTokens(existingChain, existingToken)

  const supportChainOptions = useMemo(
      () =>
          supportChains.filter(({chainId}) => {return chainId !== 66}).map(({chainId, logo, title}) => ({
            id: chainId,
            option: (
                <>
                  {logo}
                  {title}
                </>
            ),
          })),
      [supportChains]
  )
  const Stepper = useMemo(() => {
    return function ({className}) {
      return (
          <div className={`step_frame${className ? ' ' + className : ''}`}>
            <p>1</p>
          <div className="divider" />
          <div className="step_circle">
            <p>2</p>
            </div>
          <div className="divider" />
          <div className="step_circle">
            <p>3</p>
            </div>
          </div>
      )
    }
  }, [])

  useEffect(() => {
    if (token && token.tokenMapped !== ZERO_ADDRESS) {
      setTokenStatus(TOKEN_STATUS.MAPPED)
    }
    if (
        (token && token.tokenMapped !== ZERO_ADDRESS) ||
        (mappingTokensResult &&
            mappingTokensResult.tokens &&
            mappingTokensResult?.tokens?.length !== 0)
    ) {
      //toMapping({ chainId: existingChain, address: existingToken })
      setTokenStatus(TOKEN_STATUS.MAPPING)
    }
  }, [token, mappingTokensResult])

  const onCreate = async () => {
    setAttemptingTxn(true)

    try {
      const contract = getContract(
          library,
          TokenFactory,
          TOKEN_FACTORY,
          account
      )
      await contract
          .createTokenMapped(existingToken, nonce, {
            from: account,
            value: numToWei('0.1'),
          })
          .then((response) => {
            setAttemptingTxn(false)
            setHash(getEtherscanLink(chainId, response.hash, 'transaction'))
            addTransaction(response, {
              createTokenMapped: {
                existingToken: {
                  address: existingToken,
                  chainId: existingChain,
                  nonce,
                  symbol: token.symbol,
                },
                status: 0,
              },
              summary: `Create ${token.symbol} mapped token`,
              hashLink: getEtherscanLink(chainId, response.hash, 'transaction'),
            })
          })
    } catch (e) {
      setAttemptingTxn(false)
      console.log(e)
    }
  }

  return (
      <div style={{paddingTop: 24}} className="default_modal modal_add_token">
        <div className="row_between">
          <p className="default_modal__title">Add an Existing Token</p>
          <Stepper/>
        </div>
        <div className="modal_add_token__body">
          <div className="bridge__input_frame" style={{width: '100%'}}>
            <p style={{ marginTop:20}}>Token Contract Address</p>
            <div className="bridge__input_frame__extra">
              <input
                  value={existingToken}
                  onChange={(e) => {
                    const value = e.target.value
                    setExistingToken(value)
                  }}
                  placeholder="Enter the token contract address"
              />
            </div>
            <p className="error">
              {(existingToken && !isAddress(existingToken)) || (error && !error.nonce)
                  ? 'Invalid contract address'
                  : ''}
            </p>
          </div>

          <div className="bridge__input_frame" style={{width: '100%'}}>
          <ButtonSelect
              smallLabel
                defaultContent={'Select the chain ID of your existing token'}
                label={'Chain ID'}
                options={supportChainOptions}
                onSelection={(id) => {
                  setExistingChain(SUPPORT_CHAINS[id].chainId)
                }}
                selectedId={existingChain}
            />
          </div>

          {((token &&
              token.name &&
              token.symbol &&
              token.decimals &&
              token.totalSupply) ||
              loading) &&
          !error && (
              <div className="extra gray_frame" style={{paddingBottom: 0}}>
                <ul>
                  <li>Token name:</li>
                  <li style={{color: '#ffffff'}}>
                    {loading ? (
                        <img src={Circle} className="loading"/>
                    ) : (
                        token.name
                    )}
                  </li>
                </ul>
                <ul>
                  <li>Token symbol:</li>
                  <li style={{color: '#ffffff'}}>
                    {loading ? (
                        <img src={Circle} className="loading"/>
                    ) : (
                        token.symbol
                    )}
                  </li>
                </ul>
                <ul>
                  <li>Token decimals:</li>
                  <li style={{color: '#ffffff'}}>
                    {loading ? (
                        <img alt="" src={Circle} className="loading"/>
                    ) : (
                        token.decimals
                    )}
                  </li>
                </ul>
                <ul>
                  <li>Total supply:</li>
                  <li style={{color: '#ffffff'}}>
                    {loading ? (
                        <img src={Circle} className="loading"/>
                    ) : (
                        formatAmount(token.totalSupply.toString(), token.decimals)
                    )}
                  </li>
                </ul>
                {!loading &&
                token &&
                token.name &&
                token.symbol &&
                token.decimals &&
                token.totalSupply && (
              <ul className="check_frame">
                <div className='divider'/>
                      <label>
                        <input
                            type="checkbox"
                            id="checkbox"
                            onChange={(e) => {
                              const checked = e.target.checked
                              if (checked) {
                                setConfirmed(true)
                              } else {
                                setConfirmed(false)
                              }
                            }}
                            size={16}
                        />
                        <i/>
                      </label>
                      <span>I confirm token information</span>
                    </ul>
                )}
              </div>
          )}

          <div className="bridge__input_frame" style={{width: '100%'}}>
            <p>Nonce<a href="https://docs.chainswap.com/user-guide-1/search-for-nonce" target="_blank">How to find nonce
              ?</a></p>
            <div className="bridge__input_frame__extra">
              <input
                  type="number"
                  onBlur={(e) => {
                    const value = e.target.value
                    setNonce(value)
                  }}
                  placeholder="Please enter the nonce that you deployed the token"
              />
            </div>
            <p className="error">
              {nonce && certifyToken && existingToken && certifyToken.toLowerCase() !== existingToken.toLowerCase()
                  ? 'Invalid deployer or nonce'
                  : ''}
            </p>
          </div>

          <p className="text_small_gray">Please confirm the token information before deploying.</p>

          <Button
              loading={createTokenMappedTX}
              content={
                createTokenMappedTX
                    ? 'Deploying'
                    : tokenStatus === TOKEN_STATUS.MAPPED ||
                    tokenStatus === TOKEN_STATUS.MAPPING
                    ? 'Next Step'
                    : existingChain && chainId !== existingChain
                        ? `Please switch ${SUPPORT_CHAINS[existingChain]?.title}`
                        : `Deploy`
              }
              extra={!createTokenMappedTX && tokenStatus === TOKEN_STATUS.INIT && existingChain ? `Deployment fee: 0.1 ${SUPPORT_CHAINS[existingChain]?.nativeCurrency.symbol}` : null}
              disabled={
                !confirmed ||
                !nonce ||
                !token ||
                !token.name ||
                !token.symbol ||
                !token.totalSupply ||
                !token.decimals ||
                !token.tokenMapped ||
                !certifyToken ||
                (certifyToken && existingToken && certifyToken.toLowerCase() !== existingToken.toLowerCase()) ||
                createTokenMappedTX ||
                (tokenStatus === TOKEN_STATUS.INIT && chainId !== existingChain)
              }
              onClick={() => {
                if (!isEnoughGas) {
                  setShowGasError(true)
                  return
                }
                if (
                    tokenStatus === TOKEN_STATUS.MAPPING ||
                    tokenStatus === TOKEN_STATUS.MAPPED
                ) {
                  toMapping({chainId: existingChain, address: existingToken, nonce})
                } else {
                  onCreate()
                }
              }}
              style={{width: '100%'}}
          />
        </div>

        {hash && (
            <ModalOverlay>
              <Submitted
                  hash={hash}
                  onClose={() => {
                    setHash(null)
                  }}
              />
            </ModalOverlay>
        )}

        {attemptingTxn && (
            <TXConfirm
                onClose={() => {
                  setAttemptingTxn(false)
                }}
            />
        )}

        {showGasError && (
            <ModalOverlay>
              <GASERROR onOk={() => {
                setShowGasError(false)
              }} onCancel={() => {
                setShowGasError(false)
              }}/>
            </ModalOverlay>
        )}
      </div>
  )
}
