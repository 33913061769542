import React from 'react'
import ArrowLeft from '../../assets/icon/arrow-left.svg'
import {SUPPORT_CHAINS} from "../../const";

export const SwapItem = ({ data }) => {
  return (
    <div className="extra">
      <div className="extra__chain">
        <p>From:</p>
        <div className="auto_row">
          <Icon src={data && SUPPORT_CHAINS[data.fromChainId].icon} />
          <h5>{data && SUPPORT_CHAINS[data.fromChainId].title}</h5>
        </div>
      </div>
      <img
        className="arrow"
        src={ArrowLeft}
        alt=""
        style={{ transform: 'rotate(180deg)' }}
      />
      <div className="extra__chain">
        <p>To:</p>
        <div className="auto_row">
          <Icon src={data && SUPPORT_CHAINS[data.toChainId].icon} />
          <h5>{data && SUPPORT_CHAINS[data.toChainId].title}</h5>
        </div>
      </div>
    </div>
  )
}

function Icon({ src }) {
  return (
    <img
      src={src}
      alt=""
      style={{
        height: '28px'
      }}
    />
  )
}
