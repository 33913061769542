import {useEffect, useState, useMemo} from 'react'
import LBPAbi from '../web3/abi/LBP.json'
import LBPAirdropAbi from '../web3/abi/LBPAirop.json'
import {getContract, useActiveWeb3React, useBlockNumber} from '../web3'
import {numToWei} from 'utils/format'
import BigNumber from "bignumber.js";
import {AIRDROP_ADDRESS} from "../web3/address";

interface BigNumberInterface {
    _hex: string
    _isBigNumber: boolean
}

interface LBPResultInfo {
    priceLBP?: BigNumberInterface
    priceSwap?: BigNumberInterface
    begin?: BigNumberInterface
    error?: string
    strapOut?: any
    strap?: any
}

interface AirdropResultInfo {
    error?: string
    price?: BigNumberInterface
    token?: string
    currency?: string
    quota?: BigNumberInterface
    exercise?: any
    done?: BigNumberInterface
}

export type LBPInfo = LBPResultInfo | undefined
export type AirDropInfo = AirdropResultInfo | undefined

export const useLBPInfo = (amount?: number) => {
    const {blockNumber} = useBlockNumber()
    const [resultInfo, setResultInfo] = useState<LBPInfo>(undefined)

    const {library, chainId, account} = useActiveWeb3React()

    const contract = useMemo(
        () =>
            chainId
                ? getContract(
                library,
                LBPAbi,
                '0xaE8dC65a012970345ECb5FF05479B2f3014d2090',
                account
                )
                : undefined,
        [account, chainId, library]
    )

    useEffect(() => {
        if (!contract) return
        Promise.all(
            ['priceLBP', 'priceSwap', 'begin'].map((methodName) =>
                contract[methodName]()
            )
        )
            .then(([priceLBP, priceSwap, begin]) => {
                setResultInfo((prev) => ({
                    ...prev,
                    error: undefined,
                    priceLBP,
                    priceSwap,
                    begin,
                    strap: contract.strap,
                }))
            })
            .catch((error) =>
                setResultInfo(() => ({
                    error,
                }))
            )
        amount && !new BigNumber(amount).isEqualTo(0) &&
        contract
            .getStrapOut(numToWei(amount))
            .then((r: any) => setResultInfo((prev) => ({...prev, strapOut: r})))
    }, [amount, contract, blockNumber])
    return resultInfo
}

export const useAirdropInfo = () => {
    const [resultInfo, setResultInfo] = useState<AirDropInfo>(undefined)
    const {blockNumber} = useBlockNumber()
    const {library, chainId, account} = useActiveWeb3React()

    const contract = useMemo(
        () =>
            chainId
                ? getContract(
                library,
                LBPAirdropAbi,
                AIRDROP_ADDRESS,
                account
                )
                : undefined,
        [account, chainId, library]
    )
    useEffect(() => {
        if (!contract || !library || !account || !chainId) return
        Promise.all(
            ['price', 'token', 'currency']
                .map((methodName) => contract[methodName]())
                .concat([contract.quota(account), contract.done(account)])
        )
            .then(([price, token, currency, quota, done]) => {
                setResultInfo((prev) => ({
                    ...prev,
                    error: undefined,
                    price,
                    token,
                    currency,
                    quota,
                    done,
                    exercise: contract.exercise,
                }))
            })
            .catch((error) =>
                setResultInfo(() => ({
                    error,
                }))
            )

    }, [contract, account, blockNumber, chainId])

    return resultInfo
}
